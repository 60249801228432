export default {
  allDeals: [],
  syncFinished: false,
  loadingPriorityTask: false,
  loadingTasks: false,
  prePayments: null,
  selectedDeal: null,
  loadingFomentationLink: false,
  creditorsToSync: null,
  task: "",           //Hash da task.
  token: "",          //Token da seção.
  deals: [],          //Dividas do cliente.
  me: {},             //Dados sobre o cliente.
  current: {},        //Objeto current.
  configuration: {},  //Objeto configuration.
  negotiation: {      //Objeto da negociação.
    date: "",
    email: "",
    phone: null,
    whatsapp: false,
    account: {},
  },
  loadingDeals: true,
  contractInfo: {},
  dialogCreditorsOff: false,
};