import * as types from "./mutation-types";

/**
 * As mutations que fazem as modificações nas variaves do state.
 */
export default {
  [types.SET_HDOC](state, payload) {
    state.hdoc = payload;
  },
  [types.SET_TASK_ID](state, payload) {
    state.taskId = payload;
  },
  [types.SET_CLIENT](state, payload) {
    state.client = payload;
  },
}