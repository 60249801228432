import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import { name, version } from "@/../package.json";
import VueRouter from "vue-router";
const { isNavigationFailure, NavigationFailureType } = VueRouter;


import "./filters";

// Plugins
import "./plugins/axios";
import VueCookies from "vue-cookies";
import VueMask from "v-mask";
import VueJWT from "vuejs-jwt";
import VueMoney from "v-money";
import vuetify from "./plugins/vuetify";
import { initFirebase } from "./plugins/firebase";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { Vue as VueIntegration } from "@sentry/integrations";
import Footer from "@/components/footer/Footer";
import QuitejaAlertInfo from "@/components/AlertInfo";
import QuitejaProposal from "@/components/Proposal";
import VueBarcode from "vue-barcode";
import moment from "moment";

// Css
import "./assets/css/global.scss";
import "./registerServiceWorker";
import { createTagManagerEvent } from "./utils";




console.info("Versão:", version);
console.info("Ambiente:", process.env.NODE_ENV);

// Configurações
Vue.prototype.$sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};
Vue.prototype.window = window;
Vue.prototype.createTagManagerEvent = createTagManagerEvent

Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();
Vue.prototype.isNavigationFailure = isNavigationFailure;
Vue.prototype.NavigationFailureType = NavigationFailureType;
Vue.prototype.skipIfNavigationFailureIsGeneric = (failure) => {
  if (isNavigationFailure(failure, NavigationFailureType.cancelled)) {
    return;
  } else if (isNavigationFailure(failure, NavigationFailureType.redirected)) {
    return;
  } else if (isNavigationFailure(failure)) {
    console.error(failure);
    return;
  }
};

Vue.use(VueCookies);
Vue.use(VueJWT);
Vue.use(VueMask);
Vue.use(VueMoney);


window.addEventListener("appinstalled", () => {
  createTagManagerEvent({
    event: "pwaInstalled",
  });
});

try {
  initFirebase();
} catch (err) {
  console.error("Não foi possível iniciar o Firebase.", err);
}

if (process.env.NODE_ENV === "production") {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: "https://e6719d03fff645c8ab79ee0c3a7c1ac5@o92838.ingest.sentry.io/5495152",
    environment:
      window.location.host.indexOf(".quiteja.com.br") !== -1
        ? "production"
        : "staging",
    replaysOnErrorSampleRate: 0.1,
    integrations: [
      // new Sentry.Replay({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
      new BrowserTracing({
        tracingOrigins: [
          "localhost",
          "quiteja.staging.gorillascode.com",
          /(quiteja.staging.gorillascode)/,
          /(api.quiteja)/,
          /(quiteja.com.br)/,
        ],
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
        markBackgroundTransactions: false,
      }),
    ],
    tracesSampleRate: 0.05,
    release: name + "@" + version,
    sendDefaultPii: true,
  });
} else {
  console.debug("Configurando o Sentry");

  // Integração com o Sentry
  Sentry.init({
    dsn: "https://b5276b01e1f147b783b9d696dfe22ed1@o4503937968570368.ingest.sentry.io/4503937969946624",
    environment:
      window.location.host.indexOf(".quiteja.com.br") !== -1
        ? "production"
        : "staging",
    // replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    integrations: [
      // new Sentry.Replay({
      //   maskAllText: false,
      //   blockAllMedia: false,
      // }),
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
      new BrowserTracing({
        tracingOrigins: [
          "localhost",
          "quiteja.staging.gorillascode.com",
          /(quiteja.staging.gorillascode)/,
          /(api.quiteja)/,
          /(quiteja.com.br)/,
        ],
        startTransactionOnLocationChange: false,
        startTransactionOnPageLoad: false,
        markBackgroundTransactions: false,
      }),
    ],
    tracesSampleRate: 1.0,
    release: name + "@" + version,
    sendDefaultPii: true,
  });
}

//Variaveis globais
window.pageHistory = {
  back: false,
  pages: [],
};

Vue.component("qj-footer", Footer);
Vue.component("qj-alert-info", QuitejaAlertInfo);
Vue.component("qj-proposal", QuitejaProposal);
Vue.component("barcode", VueBarcode);



const app = new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

console.debug("Aplicação criada");
export { app };
