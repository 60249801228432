// Id dos credores
const CreditorId = {
  SANTANDER_PF: 1,
  QUITEJÁ: 2,
  GUIMARÃES: 3,
  AYMORÉ: 4,
  SANTANDER_PJ: 5,
  SANTANDER_PREVENTIVO: 6,
  CBSS_CARTAO_DIGIO: 7,
  PORTO_SEGURO: 8,
  PERNAMBUCANAS: 9,
  ITAPEVA: 10,
  IPANEMA_RETURN: 11,
  SANTANDER_FOMENTO: 12,
  GERU: 13,
  STONE: 14,
  VIA_VAREJO: 15,
  CBSS_EMPRESTIMO: 16,
  MERCADO_LIVRE: 17,
  BRADESCO: 33,
};

// Configurações para Pessoa Física e Pessoa Jurídica
const CustomerType = {
  // Pessoa Física (CPF)
  PERSONAL: {
    mask: "###.###.###-##",
    maxLength: 11,
    placeholder: "___.___.___-__",
  },
  // Pessoa Jurídica (CNPJ)
  BUSINESS: {
    mask: "##.###.###/####-##",
    maxLength: 14,
    placeholder: "__.___.___/____-__",
  },
};

export {
  CreditorId,
  CustomerType,
}
