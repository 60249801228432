<template>
  <v-dialog v-model="dialog" max-width="310" persistent>
    <v-card class="text-center">
      <v-card-title class="justify-center align-center px-4">
        <img src="@/assets/img/svg/terms.svg" alt="image aviso" />
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-row align-center justify-center">
          <h3 class="qj-h3">Quer novidades?</h3>
          <v-icon small @click="openNotificationInfo()" class="more-info">
            mdi mdi-information-outline
          </v-icon>
        </div>
        <p class="qj-p mb-6 mt-3">
          Aceito receber notificações de push e WhatsApp da QuiteJá e me manter
          informado(a) sobre atualizações e promoções.
        </p>
        <div class="options-div">
          <v-checkbox
            id="decline__policies__link"
            hide-details
            class="pt-0 mt-0 ml-2"
            @click="denyAndClose()"
          >
            <template v-slot:label>
              <div class="qj-checkbox-label ml-2">Não</div>
            </template>
          </v-checkbox>
          <v-checkbox
            id="accept__policies__link"
            hide-details
            class="pt-0 mt-0 mr-2"
            @click="acceptAndClose()"
          >
            <template v-slot:label>
              <div class="qj-checkbox-label ml-2">Sim</div>
            </template>
          </v-checkbox>
        </div>
      </v-card-text>
    </v-card>
    <DialogNotifyInfo ref="DialogNotifyInfo" />
  </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DialogNotifyInfo from "./DialogNotifyInfo.vue";
import { createTagManagerEvent } from "@/utils";

export default {
  components: {
    DialogNotifyInfo,
  },
  data: () => ({}),
  methods: {
    ...mapActions("contact", ["acceptNotifications"]),
    ...mapMutations("contact", ["setWhatsappModal"]),
    denyAndClose() {
      createTagManagerEvent({
        event: "declinedNotification",
      });
      this.acceptNotifications({ accepted: false });
      this.dialog = false;
    },
    openNotificationInfo() {
      this.$refs.DialogNotifyInfo?.openDialog();
    },
    async acceptAndClose() {
      this.acceptNotifications({ accepted: true, contact: this.contact });
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("contact", {
      dialogFromStore: "whatsappDialog",
    }),
    ...mapGetters("negotiation", {
      me: "getMe",
    }),
    contact() {
      const isCurrentContactCellPhone = ["CELLPHONE", "PHONE"].includes(
        this.me.contact_type
      );

      if (isCurrentContactCellPhone) 
        return this.me.contacts.find((contact) => {
          return (
              this.me.contact_id == contact.id &&
              [this.me.contact_type].includes(contact.type) &&
              ["CREATED", "CONFIRMED"].includes(contact.status)
            );
        });
      const validContact = this.me.contacts.find((contact) => {
        return (
          ["CELLPHONE", "PHONE"].includes(contact.type) &&
          ["CREATED", "CONFIRMED"].includes(contact.status)
        );
      });
      return validContact;
    },
    dialog: {
      get: function () {
        return this.dialogFromStore;
      },
      set: function (boolean) {
        this.setWhatsappModal(boolean);
      },
    },
  },
};
</script>

<style scoped>
.more-info {
  position: absolute;
  right: 50px;
}

.qj-p {
  line-height: normal;
  margin: 0 16px;
}

.options-div {
  display: flex;
  flex-direction: row-reverse;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px !important;
  padding: 8px;
  background: #f6f6f6;
  border-radius: 7px;
  width: 75%;
  margin: 0 auto;
}
</style>
