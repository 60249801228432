<template>
  <v-row justify="center" v-if="email">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">Reenviar e-mail</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <p class="qj-p">
            Um e-mail de verificação será enviado para <b>{{ email.value }}</b> com um
            link para você confirmar o seu contato.
          </p>
          <v-btn block class="rounded-lg qj-btn qj-bg-primary qj-p-btn" @click="send" :loading="sending">
            Reenviar e-mail
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import serviceContact from "@/services/contact";

export default {
  props: ["dialog", "email"],
  data: () => ({
    sending: false,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.sending = true;

      serviceContact
        .resend(this.email.id, { id: this.email.id })
        .then(() => {          
          this.$emit("snackbar", {
            snackbar: true,
            text: "Verificação enviada.",
          });

          this.close();
        })
        .catch((error) => {
          console.error("Não foi possível enviar a verificação de e-mail.", error);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
  },
};
</script>

<style></style>
