import http from "@/plugins/axios";
const VERSION = "v1/";
const RESOURCE = "customer";
const RESOURCE_URL = VERSION + RESOURCE

export const V2 = {
  deals(justSynced = undefined) {
    let url = "v2/customer/_deals?t=" + new Date().getTime();
    return http.api.get(url, { params: { just_synced: justSynced, include: 'tags_v2' } });
  },
  agreements() {
    let url = "v2/customer/_agreements?t=" + new Date().getTime();
    return http.api.get(url);
  },
  agreement(dealId) {
    let url = "v2/customer/_agreements?t=" + new Date().getTime();
    return http.api.get(url, { params: { deal_id: dealId } });
  },
  me() {
    return http.api.get("v2/customer/me?t=" + new Date().getTime());
  },
}

export default {

  me() {
    return http.api.get(VERSION + "customer/me?t=" + new Date().getTime());
  },

  deals(config = {}) {
    let url = VERSION + "customer/_deals?t=" + new Date().getTime();
    Object.entries(config).forEach(([key, value]) => {
      if (value) url = url + "&" + key + "=" + value;
    });
    return http.api.get(url, { params: { include: 'payments.boleto' } });
  },

  login(payload, token) {
    return http.api.post("v2/customer/auth?PRE_AUTH_TOKEN=" + token, payload);
  },

  contacts(payload) {
    return http.api.post(VERSION + "customer/contacts/_add", payload);
  },

  addresses(payload) {
    return http.api.post(VERSION + "customer/addresses/_add", payload);
  },

  create(payload) {
    return http.api.post(VERSION + "contact", payload);
  },

  update(payload, id) {
    return http.api.patch(VERSION + "contact/" + id, payload);
  },

  whatsapp() {
    return http.api.get(VERSION + "customer/whatsapp");
  },

  save(data) {
    if (data.id) {
      return http.api.patch(RESOURCE_URL + '/' + 'me', data)
    }
  },

  updateOrCreate(payload, contactId) {
    if (payload.status === "DISCARDED") return this.update(payload, contactId);
    return this.create(payload)
  }
}