import { store as home } from "@/views/home";
import { store as opportunity } from "@/views/opportunity";
import { store as negotiation } from "@/views/negotiation";
import { store as authentication } from "@/views/authentication";
import { store as nps } from "@/components/nps";
import { default as brokenDeal } from "@/store/brokenDeal";
import { default as parceleJa } from "@/store/parceleJa";
import { default as creditor } from "@/store/creditor";
import { default as error } from "@/store/error";
import { default as faq } from "@/store/faq";
import { default as simulator } from "@/store/simulator";
import { default as websocket } from "@/store/websocket";
import { default as alert } from "@/store/alert";
import { default as proposal } from "@/store/proposal";
import { default as contact } from "@/store/contact";
import { default as pushNotification } from "@/store/pushNotification";
import { default as pluralCheck } from "@/store/pluralCheck";

export default {
  alert,
  home,
  opportunity,
  proposal,
  simulator,
  negotiation,
  authentication,
  nps,
  faq,
  brokenDeal,
  parceleJa,
  creditor,
  error,
  contact,
  pushNotification,
  pluralCheck,
  websocket
};