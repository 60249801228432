import ProposalService from "@/services/proposal";
import * as types from "./mutation-types";

const state = {
  conditions: [],
}

const mutations = {
  [types.SET_CONDITION](state, payload) {
    state.conditions.push(payload);
  },
}

const actions = {
  // Busca as condições da proposta (oferta)
  getConditions: ({ commit, getters }, id) => {
    // Se já foi verificado se a proposta tem condições então não é mais
    // necessário fazer a requisição para a API
    if (getters.getConditions(id)) {
      return;
    }
    
    ProposalService.conditions(id).then(res => {
      commit(types.SET_CONDITION, {
        proposalId: id,
        conditions: res.data,
      });
    }, () => {
      commit(types.SET_CONDITION, {
        proposalId: id,
        conditions: null,
      });
    });
  },
};

const getters = {
  // Retorna as condições para uma proposta
  getConditions: (state) => (id) => {
    if (!id) {
      return null;
    }

    return state.conditions.find(conditions => conditions.proposalId == id);
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};