<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <img src="@/assets/img/svg/success.svg" alt="image sucesso" />
        </v-card-title>
        <v-card-text>
          <p class="qj-card-text mt-2">Sua indicação foi enviada com sucesso!</p>
          <v-btn block class="rounded-lg qj-btn qj-bg-primary qj-p-btn" @click="close()">
            Ok
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog"],
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
