import http from "@/plugins/axios";
const VERSION = "v1/";

export default {
  /**
   * Endpoint para buscar sobre o credor.
   *
   * @param {String} id Id do credor
   * @return
   */
  creditor(id) {
    return http.api.get(VERSION + "creditor/" + id + "?t=" + new Date().getTime());
  },

  creditorProfile() {
    return http.negotiationApi.get(VERSION + "creditor_profile", {
      params: {
        per_page: 1000
      }
    })
  },
}