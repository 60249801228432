const createTagManagerEvent = (eventData = null) => {
  if (eventData == null) return;
  window.dataLayer.push(eventData);
  window.gtag("event", eventData.event, { ...eventData });
};

const feeMultiplier = (rate = 1, ho = 1) => {
  return (rate / 100) * (ho / 100);
};
const feeTable = new Map();
feeTable
  .set(1, feeMultiplier(44, 7.31)) //Santander PF
  .set(2, feeMultiplier(50, 8)) // QuiteJá (teste)
  .set(5, feeMultiplier(51, 9.59)) //Santander PJ
  .set(7, feeMultiplier(41, 4.93)) // Cbss Cartões
  .set(8, feeMultiplier(49, 8.62)) // Porto seguro
  .set(9, feeMultiplier(52, 5.29)) // Pernambucanas
  .set(10, feeMultiplier(24.6, 14)) // Itapeva
  .set(11, feeMultiplier(30, 13.36)) // Ipanema
  .set(13, feeMultiplier(63, 8.01)) // Geru
  .set(15, feeMultiplier(38, 4.59)) // Via varejo
  .set(16, feeMultiplier(0, 0)) // Cbss Empréstimo
  .set(17, feeMultiplier(0, 0)) // Mercado Livre
  .set(18, feeMultiplier(49, 15)) //Banco Pan
  .set(19, feeMultiplier(35, 3.28)) //Itau
  .set(20, feeMultiplier(29, 27.97)); // Crediativos

const parceleJaStatus = {
  dictionary: [
    {
      code: 1,
      statusDescription: "Received",
      text: "Processando pagamento",
      moreInfo: false,
      icon: "mdi mdi-information-outline",
      textColor: "black--text",
    },
    {
      code: 2,
      statusDescription: "Canceled",
      text: "Falha no pagamento",
      moreInfo: false,
      icon: "mdi mdi-information-outline",
      textColor: "black--text",
    },
    {
      code: 3,
      statusDescription: "BarcodeAssigned",
    },
    {
      code: 4,
      statusDescription: "Settled",
    },
    {
      code: 5,
      statusDescription: "PaymentDenied",
      text: "Pagamento no cartão não aprovado",
      moreInfo: false,
      icon: "mdi mdi-information-outline",
      textColor: "black--text",
    },
    {
      code: 6,
      statusDescription: "PaymentValidated",
    },
    {
      code: 7,
      statusDescription: "AwaitingPayerValidation",
      text: "Aguardando código PIN de validação",
      moreInfo: true,
      moreInfoComponent: 'AwaitingPayerValidationDialog',
      icon: "mdi mdi-information-outline",
      textColor: "black--text",
    },
    {
      code: 8,
      statusDescription: "ValidatingPayment",
    },
    {
      code: 9,
      statusDescription: "Paid",
    },
  ],
  getStatus(code) {
    return this.dictionary.find(status => status.code === code)
  },
};
export { createTagManagerEvent, feeTable, parceleJaStatus };
