import http from "@/plugins/axios";
const VERSION = "v1/";

export default {
  /**
   * Endpoint para qualquer url.
   *
   * @return
   */
  generic(url, type = "get", payload = null, config = null) {
    return http.generic[type.toLowerCase()](url, payload, config);
  },

  /**
   * Endpoint para validação de email.
   *
   * @param {String} email
   * @param {Boolean} check_mx
   * @return Objeto com os status do resultado.
   */
  validatorEmail(email, check_mx = false) {
    return http.validator.get("email?address=" + email + "&check_mx=" + check_mx, {
      timeout: 3000
    });
  },

  /**
   * Endpoint para trazer as configurações.
   *
   * @return Objeto com as configurações.
   */
  configuration() {
    const params = {
      per_page: 999
    }
    return http.negotiationApi.get(VERSION + "configuration?t=" + new Date().getTime(), {params: {...params}});
  },

  /**
   *
   *
   * @return Objeto com as configurações.
   */
  /**
   *
   * @param {Object} payload
   */
  proposalMarketing(payload) {
    return http.api.post("v2/proposal_marketing", payload);
  },

  /**
   * Endpoint para consultar CEP.
   *
   * @return
   */
  cep(payload) {
    return http.generic.get("https://viacep.com.br/ws/" + payload + "/json/");
  },
}