import FaqService from "@/services/faq";
import * as types from "./mutation-types";

const state = {
  faqs: [],
  selectedFaqs: [],
}

const mutations = {
  [types.SET_FAQ](state, payload) {
    state.faqs = state.faqs.concat(payload);
  },

  [types.SELECT_FAQ](state, faqs) {
    state.selectedFaqs = faqs;
  },

  [types.CLEAR_SELECT_FAQ](state, faqs) {
    state.selectedFaqs = faqs;
  },

  [types.CLEAN_FAQS](state) {
    state.faqs = [];
  },
}

const actions = {
  async getFaqView({ commit, state }, payload = { view: null, category: null }) {
    commit(types.CLEAR_SELECT_FAQ)
    if (!payload.view) {
      payload.view = location.pathname.substring(1)
    }
    if (!payload.category) delete payload.category
    let faqs = []
    if (state.faqs.length > 0) {
      faqs = state.faqs.filter(f => f.view.split(",").filter(s => s == payload.view).length > 0)
    }
    if (faqs.length > 0) {
      commit(types.SELECT_FAQ, faqs)
    } else {
      let params = { app: 'negociation', view: payload.view, category_name: payload.category }

      await FaqService.search(params).then(res => {
        let newFaqs = res.data.objects;

        commit(types.SELECT_FAQ, newFaqs)
        commit(
          types.SET_FAQ,
          newFaqs.filter(newFaq => !state.faqs.some(faq => faq.id == newFaq.id))
        )
      })
        .catch((error) => {
          console.error("Não foi possível carregar as FAQs.", error);
        });
    }
  },
};

const getters = {
  getFaqs(state) { return state.selectedFaqs },
  getFaqsByCategory: (state) => (category) => {
    var view = location?.pathname.substring(1)
    return state.faqs.filter(faq => {
      if (!faq?.view) return false
      if (!faq?.category) {
        return faq?.view === view
      }
      return faq.category?.name === category && faq?.view === view
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};