import http from "@/plugins/axios";
import { version as appVersion } from '@/../package.json';
const VERSION = "v1/";

export default {

  /**
   * Endpoint para logar.
   *
   * @param {Object} payload Objeto com document e passcode do cliente.
   * @return Retornar um token da seção.
   */
  loginOpportunity(payload) {
    // return http.api.post(VERSION + "auth?type=track", payload);
    return http.api.post(VERSION + "opportunity/_auth", payload);
  },

  /**
   * Endpoint para logar.
   *
   * @param {Object} payload Objeto com document e passcode do cliente.
   * @return Retornar um token da seção.
   */
  login(payload, token) {
    let params = {
      PRE_AUTH_TOKEN: token,
      source: window.location.host + " " + appVersion,
    };

    return http.api.post("v2/customer/auth", payload, { params: params });
  },

  /**
   * Retorna o usuário autenticado.
   *
   * @param {Object} payload Objeto com document e passcode do cliente.
   * @return Retornar um token da seção.
   */
  getAuthenticatedUser(alreadyLogged) {
    return http.api.get("v2/customer/me?t=" + new Date().getTime(), {params: {already_logged_in: alreadyLogged}});
  },

  /**
   * Endpoint para logar.
   *
   * @param {Object} payload
   * @param {String} token
   * @return
   */
  loginCustomer(payload, token) {
    return http.api.post("v2/customer/auth?PRE_AUTH_TOKEN=" + token, payload);
  },

  /**
   * Endpoint para confirmar o código de verificação.
   *
   * @param {Object} payload Objeto com o código de verificação.
   * @return Retornar um objeto com o status do resultado.
   */
  confirm(payload) {
    return http.api.post(VERSION + "opportunity/_confirm", payload);
  },

  /**
   * Endpoint para confirmar o código de verificação.
   *
   * @param {Object} payload Objeto com o código de verificação.
   * @return Retornar um objeto com o status do resultado.
   */
  validateChallenge(answer, type, token, source_channel) {
    let answerObject = null;
    if (answer && type)
      answerObject = {
        "challenge_answer": {
          [type]: answer
        }
      }
    if (source_channel) {
      answerObject['source_channel'] = source_channel
    }

    let query = new URLSearchParams(window.location.search);
    if(query.get('change_contact')){
      answerObject['change_contact'] = query.get('change_contact') != 'false'
    }

    return http.api.post("v2/customer/auth?PRE_AUTH_TOKEN=" + token, answerObject);
  },

  notMyCPFHash(hash_id) {
    return http.api.post('v2/' + "hash/" + hash_id + "/_reject")
  },

  loginDocument(payload) {
    let params = {
      source: window.location.host + " " + appVersion,
    };

    return http.api.post("v2/customer/auth", payload, { params: params })
  },
};