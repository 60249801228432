<template>
  <v-card class="text-center">
      <v-card-title class="justify-center">
        <h3 class="qj-h3">Você não é {{ userName }}?</h3>
        <v-btn icon class="qj-btn-close-dialog" @click="$emit('close')" style="top: 5px">
          <v-icon>far fa-times-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pl-3 pr-3 pb-3 mt-2">
        <img src="@/assets/img/svg/aviso.svg" alt="image_attention" width="70" height="70"/>

        <p class="qj-p mt-2 mx-5 mb-0">
          Ao confirmar que você não é essa pessoa, <b>nós não faremos futuras notificações</b> sobre ofertas e negociações. Deseja confirmar?
        </p>
        <v-btn
          
          color="#00e58d"
          class="rounded-lg qj-btn qj-p-btn button-cancel mtop-15"
          @click="$emit('close')"
          >Cancelar</v-btn
        >
        <v-btn
          
          color="#BF4242"
          class="rounded-lg white--text qj-btn qj-p-btn mtop-15"
          @click="$emit('confirmNotMe')"
          >Confirmar</v-btn
        >
        
      </v-card-text>
    </v-card>
</template>

<script>
export default {
  props: {
    userName: {
      type: String,
      default: ''
    },
  },

}
</script>

<style lang="scss" scoped>
.medias img{
  margin-left: 12px;
  width: 22px;
  height: 22px;
}

.qj-btn-close-dialog {
  right: 0 !important;
}

.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button {
    width: 25em;
  }
}

.v-card.v-sheet {
  border-radius: 10px !important;
}
.button-cancel {
  margin-right: 26px;
}
.mtop-15 {
  margin-top: 15px;
}
</style>