export default [
    {
        path: '/erro',
        name: 'Error',
        component: () => import(/* webpackChunkName: "public" */ './pages/Error'),
        meta: {
            title: "Erro",
            requiresAuth: false,
            showMenu: false,
            showStep: false,
            canShowLogo: true,
            doNotShowMyData: true,
        },
    },
    {
        path: '/erro/:id',
        name: 'Error5XX',
        component: () => import(/* webpackChunkName: "public" */ './pages/Error5xx'),
        meta: {
            title: "Erro",
            requiresAuth: false,
            showMenu: false,
            showStep: false,
            canShowLogo: true,
            doNotShowMyData: true,
        },
    },
    {
        path: '/404',
        alias: '*',
        name: 'PageNotFound',
        component: () => import(/* webpackChunkName: "public" */ './pages/PageNotFound'),
        meta: {
            title: "Página não encontrada",
            requiresAuth: false,
            showMenu: false,
            showStep: false,
            canShowLogo: true,
            doNotShowMyData: true,
        },
    },
    {
        path: '/500',
        alias: '*',
        name: '500',
        component: () => import(/* webpackChunkName: "public" */ './pages/Error500'),
        meta: {
            title: "Ops!",
            requiresAuth: false,
            showMenu: false,
            showStep: false,
            canShowLogo: true,
            doNotShowMyData: true,
        },
    },
];
