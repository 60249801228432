import { apiParceleJa } from '@/plugins/axios'


export default {
  getProposals(parentProposalId) {
    return apiParceleJa.get(`/v1/proposal`, { params: { proposal_id: parentProposalId } })
  },
  sendPrePayment(payload) {
    return apiParceleJa.post(`/v1/prepayment/authorize`, payload)
  },
  sendPin({ proposalId = undefined, pin = undefined }) {
    return apiParceleJa.post(`/v1/proposal/${proposalId}/validate`, { pin: pin })
  },
  checkPrePayment(dealId) {
    return apiParceleJa.get(`/v1/deal/${dealId}/prepayment`, { params: { include: 'tags_v2' } });
  },
  getPrePayments() {
    return apiParceleJa.get(`/v1/prepayment/customer `)
  },
};