import http from "@/plugins/axios";

export default {
  /**
   * Retorna todos os alertas para o app "quiteja-negotiation-app-web".
   * 
   * @return a lista de alertas.
   */
  search() {
    let params = {
      active: true,
      component: "quiteja-negotiation-app-web",
      per_page: 999,
    };

    return http.negotiationApi.get("v1/alert", { params });
  },
}
