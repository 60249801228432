import NPSAnswerService from "@/services/nps_answer";
import NpsService from '@/services/nps'
import * as types from "./mutation-types";

const state = {
  value: null,
  anwser_id: null,
  nps_id: 1,
  sent: false,
  npsText: {
    question: ''
  }
}

const mutations = {
  [types.SET_VALUE](state, payload) {
    state.value = payload.value;
    state.anwser_id = payload.id
  },
  setNpsText(state, payload) {
    state.npsText = payload
  },
  setNpsValue(state, payload) {
    state.value = payload
  }
}

const actions = {
  async sendNps({ rootState, state, commit }, comment) {
    let payload = {
      customer_id: rootState.authentication.userInfo.id,
      nps_id: state.nps_id,
      comment: comment,
      value: state.value,
      deal_id: rootState.negotiation.selectedDeal?.id || undefined,
    }

    await NPSAnswerService.save(payload).then(res => {
      commit(types.SET_VALUE,
        { value: res.data.value, id: res.data.id });
      state.sent = true;
    }).catch((error) => {
      console.error(error);
    });
  },

  async getNpsText({ commit }){
    const response = await NpsService.get()
    commit('setNpsText', response.data.objects?.[0])
  },

  async setNpsValue({ commit }, value=null){
    commit('setNpsValue', value);
  }
};

const getters = {
  npsText:() => { return state.npsText },
  npsValue:() => { return state.value },
  npsHasSent:() => { return state.sent },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
