import { CreditorId } from "@/constants";

/**
 * Retorna o valor total a ser pago na proposta.
 * 
 * @param {Object} proposal a proposta.
 * @returns o valor total.
 */
function calculateTotalAmountToPay(proposal) {
  if (!proposal) {
    return false;
  }

  return proposal.down_payment +
    (proposal.installments_count_1 * proposal.installment_1) +
    (proposal.installments_count_2 * proposal.installment_2) +
    (proposal.installments_count_3 * proposal.installment_3) +
    (proposal.installments_count_4 * proposal.installment_4) +
    (proposal.installments_count_5 * proposal.installment_5);
}

/**
 * Retorna true se a proposta representa um fluxo irregular.
 * 
 * @param {Object} proposal a proposta.
 * @returns true se a proposta representa um fluxo irregular.
 */
function isIrregularFlow(proposal) {
  if (!proposal) {
    return false;
  }

  return proposal.installments_count_2 > 0 ||
    proposal.installments_count_3 > 0 ||
    proposal.installments_count_4 > 0 ||
    proposal.installments_count_5 > 0 ;
}

/**
 * Retorna true se o valor das operações (saldo devedor) deve ser exibido para
 * uma proposta.
 * 
 * @param {Object} proposal a proposta.
 * @returns true se o valor das operações deve ser exibido.
 */
function isOperationsValueVisible(proposal) {
  if (!proposal) return false;

  if (proposal.operations_value == proposal.down_payment) return false;

  if (proposal.creditor_id == CreditorId.GERU) return false;

  if (proposal.down_payment > proposal.operations_value) return false;

  return true;
}

/**
 * Retorna true se o método de pagamento deve ser exibido para uma proposta.
 * 
 * @param {Object} proposal a proposta.
 * @returns true se o método de pagamento deve ser exibido.
 */
function isPaymentMethodVisible(proposal) {
  if (!proposal) return false;

  // Não exibe o método de pagamento (débito automático) para o Mercado Livre
  if (proposal.creditor_id === CreditorId.MERCADO_LIVRE) return false;

  return true;
}

export default {
  calculateTotalAmountToPay,
  isIrregularFlow,
  isOperationsValueVisible,
  isPaymentMethodVisible,
}
