import { routes as home } from '../views/home';
import { routes as info } from '../views/info';
import { routes as email } from '../views/email';
import { routes as error } from '../views/error';
import { routes as opportunity } from '../views/opportunity';
import { routes as negotiation } from '../views/negotiation';
import { routes as authentication } from '../views/authentication';

export default [
  ...home,
  ...info,
  ...email,
  ...error,
  ...opportunity,
  ...negotiation,
  ...authentication,
];