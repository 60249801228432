export default [
  {
    path: '/meus-dados',
    name: 'MyData',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/MyData'),
    meta: {
      title: "Meus dados",
      requiresAuth: true,
      showMenu: false,
      showStep: false,
    },
  },
]