<!--
Exibe as informações de uma proposta:
Entrada, parcelas, método de pagamento e meio de pagamento.
 -->

<template>
  <div>
    <div class="qj-proposal">
      <v-row>
        <!-- Tags -->
        <v-col cols="12" class="d-flex justify-center">
          <div v-if="proposal?.discount_rate > 0.01" class="qj-tag-discount">
            <img
              src="@/assets/img/icons/sale.svg"
              class="qj-tag-discount__icon"
              alt="sale icon"
            /><span
              ><b>{{ (proposal?.discount_rate * 100).toFixed(0) }}%</b> de
              desconto</span
            >
          </div>
        </v-col>

        <v-col v-if="proposal.alias" class="qj-proposal-alias">
          {{ proposal.alias }}
        </v-col>
        <span
          class="mx-auto"
          :class="
            (proposal.payment_type == 'INSTALLMENT' ? 'parceled ' : 'cash ') +
            'qj-tag'
          "
        >
          <b>{{ proposal.payment_type | proposalPaymentType }}</b>
        </span>

        <!-- Price parcelado -->
        <v-col
          cols="12"
          class="text-center qj-price pt-1"
          v-if="proposal.payment_type == 'INSTALLMENT'"
        >
          <h6 class="mt-2">
            <span v-if="proposal.down_payment > 0">
              <span>Entrada de</span>
              <span class="qj-proposal-down-payment">
                {{ proposal.down_payment | money }}
              </span>
              <p class="ma-0 qj-p" v-if="showMethod">no boleto</p>
            </span>
            <div>
              <h2>{{ proposal.installments_count_1 }}</h2>
              x de
              <h2>{{ proposal.installment_1 | money }}</h2>
            </div>
          </h6>

          <!-- Fluxo irregular 2 -->
          <div
            v-if="proposal.installments_count_2"
            class="qj-proposal-irregular-flow"
          >
            <span class="plus-sign">+</span>

            <span class="installments-count">
              {{ proposal.installments_count_2 }}x
            </span>

            <span class="separator">de</span>

            <span class="installment">
              {{ proposal.installment_2 | money }}
            </span>
          </div>

          <!-- Fluxo irregular 3 -->
          <div
            v-if="proposal.installments_count_3"
            class="qj-proposal-irregular-flow"
          >
            <span class="plus-sign">+</span>

            <span class="installments-count">
              {{ proposal.installments_count_3 }}x
            </span>

            <span class="separator">de</span>

            <span class="installment">
              {{ proposal.installment_3 | money }}
            </span>
          </div>

          <!-- Fluxo irregular 4 -->
          <div
            v-if="proposal.installments_count_4"
            class="qj-proposal-irregular-flow"
          >
            <span class="plus-sign">+</span>

            <span class="installments-count">
              {{ proposal.installments_count_4 }}x
            </span>

            <span class="separator">de</span>

            <span class="installment">
              {{ proposal.installment_4 | money }}
            </span>
          </div>

          <!-- Fluxo irregular 5 -->
          <div
            v-if="proposal.installments_count_5"
            class="qj-proposal-irregular-flow"
          >
            <span class="plus-sign">+</span>

            <span class="installments-count">
              {{ proposal.installments_count_5 }}x
            </span>

            <span class="separator">de</span>

            <span class="installment">
              {{ proposal.installment_5 | money }}
            </span>
          </div>

          <p
            v-if="isPaymentMethodVisible"
            class="ml-1 mb-0 qj-proposal-payment-method"
          >
            no
            {{ proposal.payment_method | proposalPaymentMethod }}
          </p>
        </v-col>

        <!-- Price in cash -->
        <v-col cols="12" class="text-center qj-price pt-1" v-else>
          <h2>
            {{ proposal.down_payment | money }}
          </h2>
          <br />
          <p
            v-if="isPaymentMethodVisible"
            class="mb-0 qj-proposal-payment-method"
          >
            no {{ proposal.payment_method | proposalPaymentMethod }}
          </p>
        </v-col>

        <!-- Informações da conta-corrente. -->
        <v-col
          v-if="showBankAccount && bankAccount"
          cols="12"
          class="qj-proposal-bank-account"
        >
          <div class="content">
            <div>
              <strong>Agência:</strong>
              {{ bankAccount | bankAccountBranch }}
            </div>
            <div>
              <strong>Conta:</strong>
              {{ bankAccount | bankAccountNumber }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Proposal from "@/modules/proposal";
import util from "@/assets/js/util";

export default {
  props: {
    /** A proposta para ser exibida. */
    proposal: {
      type: Object,
      required: true,
    },
    /** Indica se devem ser exibidas as informações da conta-corrente. */
    showBankAccount: {
      type: Boolean,
      required: false,
    },
    /**  */
    showPaymentMethod: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  computed: {
    bankAccount() {
      return util.extractBankAccount(this.proposal);
    },
    /** Retorna true se o método de pagamento deve ser exibido. */
    isPaymentMethodVisible() {
      if (!this.showPaymentMethod) return false;
      return Proposal.isPaymentMethodVisible(this.proposal);
    },
    showMethod() {
      if (
        this.showPaymentMethod &&
        ["FIRST_BOLETO_OTHERS_DEBIT", "FIRST_BOLETO_OTHERS_CREDIT"].includes(this.proposal.payment_method)
      )
        return true;
      return false;
    },
  },
  methods: {
    /** Indica se o valor das operações (saldo devedor) deve ser exibido. */
    isOperationsValueVisible(proposal) {
      return Proposal.isOperationsValueVisible(proposal);
    },
  },
};
</script>

<style scoped lang="scss">
.qj-proposal {
  border: 1px solid #f0f0f0;
  border-radius: 7px;
  margin: 10px 0;
  padding: 0 12px;
}

.qj-proposal-alias {
  font-size: 14px;
  font-weight: 700;
  padding: 12px 12px 0 !important;
  text-align: center;
}
.qj-payment-method {
  text-transform: lowercase;
}
.qj-proposal-bank-account {
  display: flex;
  justify-content: center;
  padding: 0;

  .content {
    background: #f6f6f6;
    border-radius: 7px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    line-height: 18px;
    padding: 5px 7px;
    text-align: center;
  }
}

.qj-proposal-down-payment {
  font-weight: 600;
}

.qj-proposal-irregular-flow {
  line-height: 15px;
  margin-bottom: 5px !important;

  .installment {
    font-weight: 800;
  }
}
.qj-tag-discount {
  margin-left: unset;
  &__icon {
    height: unset;
    width: unset;
  }
}
</style>
