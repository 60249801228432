const state = {
  exclusiveCreditorId: null
}

const mutations = {
  setExclusiveCreditor(state, creditorId){
    state.exclusiveCreditorId = creditorId;
  }
}

const actions = {
  async verifyExclusiveCreditorOnUrl({commit}, route){
    commit('setExclusiveCreditor', route.query.creditor_id || null)
  }
};

const getters = {
  getExclusiveCreditor(state) { return state.exclusiveCreditorId || null },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};