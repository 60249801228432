<template>
  <div class="d-flex flex-column align-center">
    <div>
      <p class="mb-3" style="font-size: 12px;">
        * Lembre-se de que o Bradesco poderá atualizar os valores da sua dívida diariamente
      </p>
      <div class="rounded-lg pa-3" style="background-color: #cafce9;">
        <h4 class="mb-2">Orientações para pagamento</h4>

        <ol style="font-size: 14px;">
          <li>
            Antes de realizar o pagamento, <strong>retire o extrato para conferir o valor exato do seu débito</strong>,
            pois o banco poderá atualizá-lo diariamente
          </li>
          <li>
            Caso o valor do extrato seja diferente da negociação apresentada, <strong>siga com o pagamento no valor do
              extrato atualizado</strong>
          </li>
          <li>
            Para realizar o pagamento acesse o <a href="https://banco.bradesco" target="_blank" rel="noopener noreferrer"
              class="font-weight-bold qj-color-secondary">Internet Banking, App Bradesco</a> ou
            dirija-se a uma
            <strong>agência bancária</strong> para realizar o depósito em conta
          </li>
          <li>
            Prontinho! Após pagar o valor exato do seu débito, é só aguardar a baixa do pagamento
          </li>
        </ol>
      </div>

      <div class="promise-buttons">
        <v-btn :disabled="isDisabledButton" class="qj-btn qj-bg-primary promise-button" @click="willPayDialog = true">
          Pagar débito
        </v-btn>
        <v-btn :disabled="isDisabledButton" class="qj-btn qj-bg-secondary-light promise-button"
          @click="sendPaymentPromise('payed'); payedDialog = true">
          Já paguei
        </v-btn>
      </div>
    </div>

    <v-dialog v-model="willPayDialog" @click:outside="willPayDialog = false" max-width="320">
      <v-card>
        <v-card-text class="pa-5">
          <v-btn block class="rounded-lg qj-btn qj-bg-primary mb-4"
            @click="sendPaymentPromise('willPayToday'); willPayDialog = false; window.open('https://banco.bradesco', '_blank')">
            Pagar hoje
          </v-btn>
          <v-btn block class="rounded-lg qj-btn qj-bg-primary" style="background-color: #FBD1A2 !important;"
            @click="sendPaymentPromise('willPayIn2Days'); willPayDialog = false; window.open('https://banco.bradesco', '_blank')">
            Pagar em 2 dias ({{ getNextWorkingDay() | brazilianDate }})
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payedDialog" @click:outside="payedDialog = false" max-width="320">
      <v-card class="text-center">
        <v-card-title class="justify-center align-start pb-3">
          <img src="@/assets/img/svg/aviso.svg" alt="image aviso" />
        </v-card-title>
        <v-card-text>
          <h3 class="qj-h3">Excelente</h3>
          <p class="qj-p mt-4 mb-5">
            Estamos aguardando a confirmação do seu pagamento, em breve seu saldo será quitado
          </p>
          <v-btn block class="rounded-lg qj-btn qj-bg-primary ml-0" @click="payedDialog = false">
            Entendi
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import servicePayment from "@/services/payment";

export default {
  props: ["deal"],
  data: () => ({
    payedDialog: false,
    willPayDialog: false,
    isDisabledButton: false,
  }),
  methods: {
    async sendPaymentPromise(text) {
      let promise = {
        deal_id: this.deal?.id,
        payment_type: text,
      };

      try {
        await servicePayment.sendPaymentPromise(promise, this.deal?.creditor?.config_prefix);
        this.isDisabledButton = true;
      } catch (error) {
        console.error(
          "Não foi possível registrar a promessa",
          JSON.stringify(error)
        );
        this.$emit("snackbar", {
          snackbar: true,
          text: "Não foi possível registrar a promessa",
        });
      }
    },
    isHoliday(date) {
      const holidays = [
        new Date(date.getFullYear(), 0, 1),  // Ano Novo
        new Date(date.getFullYear(), 4, 1),  // Dia do Trabalhador
        new Date(date.getFullYear(), 8, 7),  // Independência do Brasil
        new Date(date.getFullYear(), 9, 12), // Nossa Senhora Aparecida
        new Date(date.getFullYear(), 10, 2), // Finados
        new Date(date.getFullYear(), 11, 25),// Natal

        this.calculateCarnaval(date.getFullYear()),
        this.calculateCorpusChristi(date.getFullYear()),
        this.calculateEaster(date.getFullYear())
      ];

      for (const holiday of holidays) {
        if (date.toDateString() === holiday.toDateString()) {
          return true;
        }
      }

      return false;
    },
    calculateCarnaval(year) {
      const easter = this.calculateEaster(year);
      const carnVal = new Date(easter);
      carnVal.setDate(easter.getDate() - 48);
      return carnVal;
    },
    calculateCorpusChristi(year) {
      const easter = this.calculateEaster(year);
      const corpusChristi = new Date(easter);
      corpusChristi.setDate(easter.getDate() + 60);
      return corpusChristi;
    },
    calculateEaster(year) {
      const a = year % 19;
      const b = Math.floor(year / 100);
      const c = year % 100;
      const d = Math.floor(b / 4);
      const e = b % 4;
      const f = Math.floor((b + 8) / 25);
      const g = Math.floor((b - f + 1) / 3);
      const h = (19 * a + b - d - g + 15) % 30;
      const i = Math.floor(c / 4);
      const k = c % 4;
      const l = (32 + 2 * e + 2 * i - h - k) % 7;
      const m = Math.floor((a + 11 * h + 22 * l) / 451);
      const n = h + l - 7 * m + 114;
      const month = Math.floor(n / 31);
      const day = (n % 31) + 1;

      return new Date(year, month - 1, day);
    },
    getNextWorkingDay() {
      const today = new Date();
      let addedDays = 0;

      while (addedDays < 2) {
        today.setDate(today.getDate() + 1);
        if (today.getDay() !== 0 && today.getDay() !== 6 && !this.isHoliday(today)) {
          addedDays++;
        }
      }

      return today;
    }
  },
}
</script>

<style lang="scss" scoped>
.promise-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  row-gap: 16px;
  margin: 16px 0px 0px;

  .promise-button {
    width: 47%;
    margin-left: 0px;
    color: black !important;
    border-radius: 8px !important;
  }
}
</style>
