<template>
  <v-dialog v-model="dialog" @click:outside="closeDialog()" max-width="500">
    <v-card class="text-center">
      <v-card-text>
        <p class="qj-p pt-4 mb-0">Estou ciente que meus dados serão utilizados para envio de notificações referentes à negociação, acordos e
          ofertas</p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    dialog: false,
  }),
  methods: {
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    }
  },
};
</script>

<style scoped></style>
