import http from "@/plugins/axios";
const VERSION = "v2/";

export default {
  /**
   * Endpoint para buscar uma recomendação.
   *
   * @param {String} id Id da recomendação.
   * @return Recomendação.
   */
  get(id = false) {
    let url = "recommendation";
    if (id) url = url + "/" + id;
    return http.api.get(VERSION + url);
  },

  /**
   * Endpoint para atualizar uma recomendação.
   *
   * @param {String} id Id da recomendação.
   * @param {Object} payload
   * @return
   */
  pathc(id, payload) {
    return http.api.pathc(VERSION + "recommendation/" + id, payload);
  },

  /**
   * Endpoint para deletar uma recomendação.
   *
   * @param {String} id Id da recomendação.
   * @return
   */
  delete(id) {
    return http.api.delete(VERSION + "recommendation/" + id);
  },

  /**
   * Endpoint para adicionar uma nova recomendação.
   *
   * @param {Object} payload
   * @return
   */
  post(payload) {
    return http.api.post(VERSION + "recommendation", payload);
  },
}