import Vue from "vue";
import axios from "axios";
import environment from "@/assets/js/environment";
import axiosRetry from "axios-retry";
import { ErrorsRedirects } from "@/modules/redirect";

Vue.prototype.$http = axios;
const apis = {
  apiCore: axios.create({ baseURL: environment.apiUrl }),
  negotiationApi: axios.create({ baseURL: environment.negotiationApi }),
  apiParceleJa: axios.create({ baseURL: environment.parceleJaUrl }),
  apiAdapter: axios.create(),
  validator: axios.create({ baseURL: environment.validatorUrl }),
  tasks: axios.create({ baseURL: environment.tasksUrl }),
};

const apiKeys = Object.keys(apis);

const setToken = (token) => {
  apiKeys.forEach((api) => {
    apis[api].defaults.headers.common["Authentication-Token"] = token;
  })
}

function retryConditions(axios) {
  const errorStatusToRetry = [0, 500, 503, 428, undefined];
  const routesToRetry = ["/simulate"];
  const routesToNotRetry = ["customer/_deals", "email"]

  const DoNotRetryThisUrl = routesToNotRetry.some((route) =>
    axios.config.url.includes(route)
  );

  const retryThisUrl = routesToRetry.some((route) =>
    axios.config.url.includes(route)
  );
  const retryThisStatus = errorStatusToRetry.includes(axios?.response?.status);

  return (retryThisUrl || retryThisStatus) && !DoNotRetryThisUrl;
}

const setErrorInterceptor = (api) => {
  api.interceptors.request.use(function (request) {
    if (request["axios-retry"]?.retryCount == 5) {
      request.headers.last_retry = true
    }
    if (request["axios-retry"]) {
      request.headers.is_retry = true
    }
    if (request["axios-retry"]?.retryCount > 0 && request.method === "post") {
      let body = JSON.parse(request.data);
      request.data = JSON.stringify(body);
    }
    return request;
  });
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const redirect = new ErrorsRedirects(error);
      redirect.redirect();

      return Promise.reject(error);
    }
  );
};
const retryDelay = function (retryCount, error){
  if (error?.response?.headers['retry-after']){
    const delayInMs = Number(error.response.headers['retry-after'])
    return delayInMs
  }
  const delay = axiosRetry.exponentialDelay(retryCount, error, 1000)
  return delay
}
export const apiParceleJa = apis.apiParceleJa
apiKeys.forEach((api) => {
  axiosRetry(apis[api], {
    retries: 5,
    retryCondition: retryConditions,
    retryDelay: retryDelay,
  });
  setErrorInterceptor(apis[api]);
});

export default {
  generic: axios,
  api: apis.apiCore,
  apiParceleJa: apis.apiParceleJa,
  tasks: apis.tasks,
  apiAdapter: apis.apiAdapter,
  validator: apis.validator,
  negotiationApi: apis.negotiationApi,
  setToken,
};
