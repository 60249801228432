const state = {
    manyPhones: null,
    dealsLength: null,
    agreedDealsLength: null,
    manyOperations: null,
    invalidStatus: [
        "DISCARDED",
        "INVALID"
    ],
    agreementStatus: [
        "AGREED",
        "CONFIRMED",
    ],
}
const getters = {
    hasManyPhones(state) {
        return state.manyPhones;
    },
    hasManyDeals(state) {
        return state.dealsLength;
    },
    hasManyAgreements(state) {
        return state.agreedDealsLength;
    },
    hasManyOperations(state) {
        return state.manyOperations;
    }
}
const mutations = {
    setManyPhones(state, manyPhones) {
        state.manyPhones = manyPhones;
    },
    setDealsLength(state, dealsLength) {
        state.dealsLength = dealsLength;
    },
    setAgreedDealsLength(state, agreedLength) {
        state.agreedDealsLength = agreedLength;
    },
    setManyOperations(state, manyOperations) {
        state.manyOperations = manyOperations;
    },
}
const actions = {
    hasMany({ commit }, { mutation, length }) {
        if (length >= 2) {
            return commit(mutation, true);
        }
        commit(mutation, false);
    },
    setPhonesLength({ dispatch }, contacts = null) {
        let mutation = 'setManyPhones';
        let validPhones = 0;
        if (contacts) {
            validPhones = contacts.filter(contact => {
                if (contact.type == "CELLPHONE") {
                    if (!state.invalidStatus.includes(contact.status)) {
                        return contact;
                    }
                }
            }).length;
        }

        dispatch('hasMany', { mutation, length: validPhones });
    },
    setDealsLength({ state, dispatch }, deals) {
        let agreedLength = 0;
        let dealsLength = deals.filter(deal => {
            if (state.agreementStatus.includes(deal.status) || deal.active_proposal) {
                agreedLength += 1;
                return;
            }
            return deal;
        }).length;

        dispatch('hasMany', { mutation: 'setDealsLength', length: dealsLength });
        dispatch('hasMany', { mutation: 'setAgreedDealsLength', length: agreedLength });
    },
    setOperationsLength({ dispatch }, operations) {
        let mutation = 'setManyOperations';
        let operationsLength = operations.filter(operation => operation.active == true).length;

        dispatch('hasMany', { mutation, length: operationsLength });
    }
}
export default {
    state,
    getters,
    mutations,
    actions,
    namespaced: true,
};