<template>
  <div>
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Precisa de ajuda?</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-col
            v-if="!faq.selectedFaqs || !faq.selectedFaqs.length"
            cols="12"
            class="pt-1 text-center"
          >
            <div class="qj-p ma-auto">
              <img src="@/assets/img/svg/atendente.svg" alt width="90" />
            </div>

            <p>Ficou com alguma dúvida?</p>

            <v-btn
              class="rounded-lg qj-btn qj-bg-secondary-light"
              href="https://quiteja.com.br/contato"
              target="_blank"
            >
              Entrar em contato
            </v-btn>
          </v-col>

          <v-col
            v-if="faq.selectedFaqs && faq.selectedFaqs.length"
            cols="12"
            class="pt-1 text-center"
          >
            <p class="qj-p ma-auto">
              Veja as principais dúvidas que selecionamos para você
            </p>
          </v-col>
          <v-expansion-panels accordion v-if="faqs">
            <v-expansion-panel
              x
              v-for="(helpOption, index) in filteredFaqs(this.category)"
              :key="index"
            >
              <v-expansion-panel-header class="font-14">
                {{ helpOption.question }}
                <template v-slot:actions>
                  <v-icon color="#00E58D">mdi-close</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="px-6"
                v-html="helpOption.content"
              ></v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-col class="attentant-call" cols="12">
            <button
              v-show="hasCustomerServiceBtn"
              class="qj-btn qj-bg-primary"
              @click="dialogCallAttendant = true"
            >
              Falar com um atendente
            </button>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogCallAttendant
      :dialogCallAttendant="dialogCallAttendant"
      @closeCallAttendant="dialogCallAttendant = false"
    />
  </div>
</template>

<script>
import DialogCallAttendant from "@/components/DialogCallAttendant";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  props: ["dialog", "hasCustomerServiceBtn", "view"],
  data: () => ({
    dialogCallAttendant: false,
    category: null,
  }),
  async created() {
    this.category = this.selectedDeal?.creditor?.name ?? null;
  },
  computed: {
    ...mapState(["faq"]),
    ...mapGetters("negotiation", {
      deal: "getDeals",
      selectedDeal: "getSelectedDeal",
    }),
    ...mapGetters("faq", {
      filteredFaqs: "getFaqsByCategory",
      faqs: "getFaqs",
    }),
  },
  methods: {
    ...mapActions("faq", ["getFaqView"]),
    ...mapMutations("faq", ["cleanFaqs"]),
    close() {
      this.$emit("close");
    },
    closeCallAttendant() {
      this.dialogCallAttendant = false;
    },
  },
  components: {
    DialogCallAttendant,
  },
};
</script>

<style lang="scss" scoped>
.v-card__title {
  padding-bottom: 0 !important;
}

.v-expansion-panel-content {
  display: flex;
  flex-direction: column;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  border-radius: 0.5em;
  margin-top: 10px;
}

.v-expansion-panel {
  border: 1px solid #f0f0f0;
  border-left: 6px solid #00e58d;
}

.v-expansion-panel.v-expansion-panel--active.v-item--active {
  background: #f6f6f6;
  min-height: 0;
  button {
    font-weight: 600;
  }
  div {
    font-weight: 400;
  }
}

.v-icon.mdi.mdi-close {
  transform: rotate(-135deg);
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}

.attentant-call {
  display: flex;
  justify-content: space-around;
  margin: 1em 0 1em 0;
  button {
    width: 16em;
    height: 3em;
    border-radius: 10px;
  }
}
.font-14 {
  @media (max-width: 320px) {
    font-size: 14px;
  }
}
</style>
