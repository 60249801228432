export default [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'Deals' },
  },
  {
    path: '/boleto',
    name: 'Boleto',
    redirect: { name: 'Authentication', query: {modo: 'boleto'} },
  },
  {
    path: '/dividas',
    name: 'Deals',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/Deals'),
    meta: {
      title: "Dívidas",
      requiresAuth: true,
      showMenu: true,
      showStep: false,
      canShowLogo: true,
      noPadding: true,
      showTabs: true,
    },
  },
  {
    path: '/carregando',
    name: 'HomeLoadDeals',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/LoadDeals'),
    meta: {
      title: "Carregando",
      requiresAuth: true,
      showMenu: false,
      showStep: false,
    },
  },
  {
    path: '/acordo',
    name: 'HomeActiveAgreement',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/ActiveAgreement'),
    meta: {
      title: "Acordo",
      requiresAuth: true,
      showMenu: true,
      showStep: false,
    },
  },
  {
    path: '/proposta',
    name: 'ProposalStatus',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/ProposalStatus'),
    meta: {
      title: "Proposta",
      requiresAuth: true,
      showMenu: true,
      showStep: false,
    },
  },
  {
    path: '/sem-dividas',
    name: 'NegotiationNoDeals',
    component: () => import(/* webpackChunkName: "negotiation" */ './pages/NoDeals'),
    meta: {
      title: "Sem dívidas",
      requiresAuth: false,
      showMenu: false,
      showStep: false,
    },
  },
  {
    path: '/ajuda',
    name: 'Support',
    redirect: () => {
      window.location = 'https://quiteja.com.br/ajuda?utm_source=homescreen';
    },
  }
]
