<template>
  <v-row class="pt-1 pb-0">
    <!-- Btn exit -->
    <v-col cols="8" offset="2">
      <a v-bind:href="landingPageUrl" style="text-decoration-line: none">
        <v-btn block class="rounded-lg qj-btn qj-bg-secondary qj-p-btn">
          Sair
        </v-btn>
      </a>
    </v-col>
    <!-- Btn help -->
    <v-col cols="2" class="pl-0">
      <v-btn
        icon
        class="rounded-lg qj-btn qj-bg-primary-light"
        @click="dialog = true">
        <v-icon>far fa-question-circle</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import environment from "@/assets/js/environment";

export default {
  props: {
    disabled: Boolean,
    label: String,
  },
  data: () => ({
    landingPageUrl: environment.landingUrl,
  }),
  methods: {
    send() { this.$emit("send") },
    help() { this.$emit("help") },
  },
}
</script>

<style>
</style>