import Vue from "vue";
import store from "../store";
import VueSocketIOExt from "vue-socket.io-extended";
import { io } from "socket.io-client";
import environment from "@/assets/js/environment";
const websocketUrl = environment.negotiationApi.replace('api/', '')
export const initWebSocket = () => {

  const jwt = store?.getters['authentication/getToken']

  const socket = io(websocketUrl + `?jwt=${jwt}`, {
    transports: ["websocket"],
    debug: true,
    withCredentials: true,
    reconnection: true,
    reconnectionDelay: 500,
    maxReconnectionAttempts: Infinity,
    closeOnBeforeunload: false,
  });

  Vue.use(VueSocketIOExt, socket, { store, actionPrefix: 'SOCKET_' });
};
