import serviceProposal from "@/services/proposal";
import serviceDeal from "@/services/deal";
// import { createTagManagerEvent } from "@/utils";

const state = {
  simulationParams: null,
  simulationResult: null,
}
const mutations = {
  setSimulationParams(state, payload) {
    state.simulationParams = payload;
  },
  setSimulationResult(state, payload) {
    state.simulationResult = payload;
  },
}
const actions = {
  async getSimulationParams({ commit }, dealId, payload) {
    try {
      const { data } = await serviceDeal.simulationParams(dealId, payload)
      commit('setSimulationParams', data)
      return data
    } catch (e) {
      console.error(e)
    }
  },
  async simulate({commit}, payload){
    try {
      const { data } = await serviceProposal.simulate(payload)
      commit('setSimulationResult', data)
      return data
    } catch (e) {
      console.error(e)
    }
  },
}
const getters = {
  notification: (state) => state.notification,

}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};