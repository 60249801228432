<template>
    <v-dialog v-model="dialog" max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center align-start px-4">
          <v-spacer></v-spacer>
          <img src="@/assets/img/svg/terms.svg" alt="image aviso" class="ml-8" />
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <h3 class="qj-h3">Receber novidades?</h3>
          <p class="qj-p mb-6 mt-3">
            Sempre que tivermos alguma
            novidade na sua negociação
            enviamos uma mensagem.
          </p>
          <div
            style="padding: 8px; background: #f6f6f6; border-radius: 7px"
            class="mb-4"
          >
            <v-checkbox
              id="accept__policies__link"
              hide-details
              class="qj-checkbox pt-0 mt-0"
              v-model="notification"
              @click="registerPushNotificationAndClose()"
            >
              <template v-slot:label>
                <div class="qj-checkbox-label ml-2">
                  Aceito receber mensagens
                </div>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data: () => ({
  }),
  methods: {
    ...mapActions('pushNotification', {
      registerPushNotification: 'registerPushNotification',
      verifyPushPermission: 'verifyPushPermission'
    }),
    ...mapMutations('pushNotification', {
      isDialogOpen: 'isDialogOpen',
      setNotification: 'setNotification',
    }),
    openDialog(){
      this.verifyPushPermission()
      if(!this.notification && this.isSupported) {
        this.dialog = true
      }
    },
    registerPushNotificationAndClose() {
      this.registerPushNotification(this.notification)
      this.dialog = false
    }
  },
  computed: {
    ...mapGetters('pushNotification', {
      dialogFromStore: 'dialog',
      notificationFromStore: 'notification',
      isSupported: 'supported'
    }),
    dialog: {
      get: function() { return this.dialogFromStore},
      set: function(boolean) { this.isDialogOpen(boolean)}
    },
    notification: {
      get: function() { return this.notificationFromStore},
      set: function(notification) { this.setNotification(notification)}
    }
  },
};
</script>

<style scoped></style>
