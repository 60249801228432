export default [
  {
    path: '/cadastro',
    name: 'OpportunityStart',
    component: () => import(/* webpackChunkName: "public" */ './pages/Start'),
    meta: {
      title: "Cadastro",
      requiresAuth: false,
      showMenu: false,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/cadastro/verificacao',
    name: 'OpportunityVerification',
    component: () => import(/* webpackChunkName: "public" */ './pages/Verification'),
    meta: {
      title: "Verificação",
      requiresAuth: false,
      showMenu: true,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/cadastro/informacoes',
    name: 'OpportunityDebtCreditor',
    component: () => import(/* webpackChunkName: "public" */ './pages/DebtCreditor'),
    meta: {
      title: "Informações",
      requiresAuth: false,
      showMenu: true,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/cadastro/finalizacao',
    name: 'OpportunityFinish',
    component: () => import(/* webpackChunkName: "public" */ './pages/Finish'),
    meta: {
      title: "Finalização",
      requiresAuth: false,
      showMenu: false,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/optin-whatsapp',
    name: 'optinWhatsapp',
    component: () => import(/* webpackChunkName: "public" */ './pages/Optin'),
    meta: {
      title: "Confirme whatsapp",
      showVersion: false,
      requiresAuth: false,
      noPadding: true,
      optin: true,
    },
    props: route => ({ hash: route.query.hash || null, source_channel: route.query.source_channel || null, utm_source: route.query.utm_source || null, utm_medium: route.query.utm_medium || null, utm_campaign: route.query.utm_campaign || null, utm_content: route.query.utm_content || null, utm_term: route.query.utm_term || null })
  }
];