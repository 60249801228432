<template>
  <div>
    <v-fade-transition>
      <div v-show="dialogPolicy" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      content-class="qj-dialog"
      transition="dialog-bottom-transition"
      v-model="dialogPolicy"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Política de Privacidade</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <p class="mb-0 mt-2 qj-p" v-html="msg"></p>
        </v-card-text>
        <v-card-actions
          v-if="footer"
          class="actions-container"
          style="border-radius: 0px !important"
        >
          <div class="checkbox-container">
            <v-checkbox
              hide-details
              class="qj-checkbox pt-0 mt-0"
              v-model="checkbox"
              @click="setPolicy()"
            >
              <template v-slot:label>
                <div class="qj-checkbox-label">
                  Li e concordo com a
                  <b> Política de Privacidade</b>.
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-btn
            :disabled="!policy"
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn proceed-button"
            @click="next"
          >
            <div style="margin-right: auto">Prosseguir</div>
            <v-icon style="font-size: 20px">fas fa-long-arrow-alt-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "@/assets/js/util";
import serviceUtil from "@/services/util";

export default {
  props: ["dialogPolicy", "policy", "footer", "url", "id"],
  data: () => ({
    checkbox: false,
    msg: "Aguarde...",
  }),
  created() {
    let url = this.url;

    // TODO De onde é esse id?
    if (this.id) {
      let configPrefix = util.getPrefix(this.id);

      if (!configPrefix) return;

      url = configPrefix
        ? util.getConfig()[configPrefix + "asset.confirmation_terms"]
        : false;
    }

    if (!url) return;

    serviceUtil.generic(url).then((res) => (this.msg = res.data));
  },
  watch: {
    policy() {
      this.checkbox = this.policy;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setPolicy() {
      this.$emit("setPolicy");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  button {
    width: 25em;
  }
}

.v-icon.mdi-checkbox-blank-outline {
  color: var(--primary) !important;
}
.v-icon.mdi-checkbox-marked {
  color: var(--primary) !important;
  caret-color: var(--primary) !important;
}

.proceed-button {
  width: 228px !important;
  height: 40px !important;
  margin-top: 20px;
  display: flex;
  .text {
    display: flex;
    flex: 1;
  }
}

.actions-container {
  height: 150px;
  .v-input--selection-controls {
    margin-top: 8px;
    padding-top: none;
  }
}

.checkbox-container {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 10px;
  line-height: initial;
}
</style>
