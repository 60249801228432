<template>
  <v-row class="pt-0 pb-0">
    <!-- Btn next -->
    <v-col cols="8" offset="2" class="text-center">
      <a
        href="javascript:void(0);"
        class="qj-link"
        style="vertical-align: sub"
        @click="notMyCPF"
      >
        Eu não sou {{ userInfo.name }}
      </a>
    </v-col>
    <DialogNotMy
      :dialogNotMy="dialogNotMy"
      :userName="userInfo.name"
      @closeDialogNotMyCPF="closeDialogNotMyCPF"
      @confirmNotMe="confirmNotMyCPF"
    ></DialogNotMy>
  </v-row>
</template>
<script>
import store from "@/store";
import DialogNotMy from "../modals/DialogNotMy";
import authenticationService from "@/services/authentication";
import { createTagManagerEvent } from "@/utils";

export default {
  components: {
    DialogNotMy,
  },
  data: () => ({
    userInfo: store.getters["authentication/getUserInfo"],
    dialogNotMy: false,
  }),
  
  methods: {
    
    notMyCPF() {
      this.dialogNotMy = true;
    },
    closeDialogNotMyCPF() {
      this.dialogNotMy = false;
    },
    confirmNotMyCPF() {
      createTagManagerEvent({
        event: "loginClickedNotMyDocument",
      });
    
      if (this.userInfo.hash_id) {
        authenticationService.notMyCPFHash(this.userInfo.hash_id)
      }
    },
  },
};
</script>
<style scoped lang="scss">
.qj-link {
  color: #d32f2f;
  &:hover,
  &:active {
    color: #d32f2f;
  }
}
</style>
