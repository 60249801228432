import http from "@/plugins/axios";
const VERSION = "v1/";

export default {
  /**
   * Endpoint para criar um cliente.
   *
   * @param {Object} payload Dados do cliente.
   * @param {String} privacyPolicyId Id dos termos de uso.
   * @return Retornar um objeto do cliente.
   */
  create(payload, useTermsId) {
    return http.api.post(VERSION + "opportunity", payload, {
      params: {
        termsAccepted: useTermsId,
      },
    });
  },

  /**
   * Endpoint para salvar alterações em um cliente.
   *
   * @param {String} id Id do cliente.
   * @param {Object} payload Dados do cliente.
   * @return Retornar um objeto do cliente.
   */
  save(id, payload) {
    return http.api.patch(VERSION + "opportunity/" + id + "?termsAccepted=1", payload);
  },

  /**
   * Endpoint para confirmar o código de verificação.
   *
   * @param {Object} payload Objeto com o código de verificação.
   * @return Retornar um objeto com o status do resultado.
   */
  confirm(payload) {
    return http.api.post(VERSION + "opportunity/_confirm", payload);
  },

  /**
   * Endpoint para reenviar o código de verificação.
   *
   * @param {Object} payload Objeto com o _hdoc.
   * @return Retornar um objeto com o status do resultado.
   */
  resendCode(payload) {
    return http.api.post(VERSION + "opportunity/_send_verification_code", payload);
  },

  /**
   * Endpoint para reenviar o código de verificação.
   *
   * @param {Object} payload Objeto com o _hdoc.
   * @return Retornar um objeto com o status do resultado.
   */
   enrichment(payload) {
    return http.api.post(VERSION + "opportunity/_get_customer_information", payload);
  },
};