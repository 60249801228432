import store from "@/store";
import util from "@/assets/js/util";
import Vue from "vue";

const FUNCTIONS = {
  /**
   * Função que muda o meta da rota.
   * @param {Object} to Rota da onde vem.
   * @returns Objeto to sem o novo meta nos params.
   */
  changeMeta(to) {
    if (typeof to.params.meta !== "undefined") {
      Object.entries(to.params.meta).forEach(
        ([key, value]) => (to.meta[key] = value)
      );
      delete to.params.meta;
    }
    return to;
  },
  /**
   * Função que adiciona uma nova rota no histórioco de rotas.
   * @param {Object} to Rota da onde vem.
   * @param {Object} from Rota para onde vai.
   */
  pushPageHistory: (to, from) => {
    let isSkiped = from.meta.skipToHighlightOne;
    if (!window.pageHistory.back && !isSkiped) {
      if (!util.isEmpty(to.params)) {
        from.params.oldParams = to.params;
      }
      window.pageHistory.pages.push(from);
    } else {
      window.pageHistory.back = false;
    }
  },
  /**
   * Função que identifica se o usuário está logado.
   * Se o usuário não está logado então redireciona para a tela de login
   * @param {Object} to Rota da onde vem.
   * @param {Object} user Dados sobre o usuário.
   * @returns Retorna se o usuário está logado.
   */
  userIsLogged(to, user) {
    return to.matched.some((record) => record.meta.requiresAuth) && !user.id
      ? false
      : true;
  },
  /**
   * Query para caso o usuário não esteja logado.
   * Por padrão o redirecionamento após o login será feito para "/dividas"
   * Então não é necessário informar o parâmetro
   * @returns Retorna um query.
   */
  setQuery(to) {
    let query = {};
    if (to.fullPath != "/dividas") query.continue = to.fullPath;
    return query;
  },
  /**
   * Função para setar o titulo da página.
   * @param {Object} to Rota da onde vem.
   */
  setTitle(to) {
    document.title = to.meta.title + " - QuiteJá";
  },
};

export default async (to, from, next) => {
  let user = store.getters["authentication/getUserInfo"];

  to = FUNCTIONS.changeMeta(to);
  FUNCTIONS.setTitle(to);

  if (FUNCTIONS.userIsLogged(to, user)) {
    FUNCTIONS.pushPageHistory(to, from);
    try {
      next()
    } catch (err) {
      console.error(err)
      return
    }
  } else {
    try {
      next({
        name: "Authentication",
        query: FUNCTIONS.setQuery(to),
      });
    } catch (err) {
      console.error(err)
      return
    }
  }
};
