import serviceCustomer from "@/services/customer";
import { createTagManagerEvent } from "@/utils";

const state = {
  notification: false,
  loading: false,
  supported: false,
  dialog: false,
}
const mutations = {
  isLoading(state, boolean) {
    state.loading = boolean;
  },
  setNotification(state, notification) {
    state.notification = notification;
  },
  isSupported(state, boolean) {
    state.supported = boolean;
  },
  isDialogOpen(state, boolean) {
    state.dialog = boolean
  }
}
const actions = {
  async registerPushNotification({ dispatch, commit, rootState }, notification) {
    commit('isLoading', true)
    await window.Notification.requestPermission();
    await setTimeout(() => {}, 1000);

    if (!window.Notification || window.Notification.permission !== "granted") {
      commit('setNotification', false);
      return;
    }
    
    console.debug("Alterando notificação push");

    let token = null;

    try {
      token = await dispatch('requestPushPermission');
      console.debug("Obtido token para notificação:", token);
    } catch {
      console.debug("Não foi possível pegar o token para notificação.");
      commit('setNotification', false);
      return;
    }

    commit('setNotification', notification)

    // Verifica se o contato para o token já existe
    let contacts = rootState.authentication.userInfo?.contacts || [];
    let tokenContact = contacts.find(contact => contact.value == token);

    const contact = await dispatch('generateContact', token)
    var payload = ""
    if (tokenContact && !notification) {
      console.debug("Contato já existe para o token", token);
      payload = await dispatch('generateServiceCustomerPayload', { tokenContact, token });
    } else {
      payload = await dispatch('generateServiceCustomerPayload', { contact, token });
    }

    await dispatch('saveCustomerPushPermission', { payload, contact: contact || tokenContact })
  },

  async requestPushPermission() {
    return new Promise((resolve) => {
      window.Notification.requestPermission().then((permission) => {
        console.debug("Permissão para notificação:", permission);

        if (permission == "granted") {
          resolve(window.firebase.messaging().getToken());
        } else {
          console.info('Permissão negada');
        }
      });
    });
  },

  generateContact({ rootGetters }, token) {
    const contact = rootGetters["authentication/getActiveDeviceTokenContacts"]?.find((contact) => {
      return contact.value == token
    });
    return contact || undefined;
  },

  generateServiceCustomerPayload({ state }, payload) {
    let status = "CONFIRMED"
    if (state.notification == false) status = "DISCARDED";
    return {
      source: "CUSTOMER",
      status,
      type: "DEVICE_TOKEN",
      value: payload.token,
    };
  },

  async saveCustomerPushPermission({ commit, dispatch }, payload) {
    try {
      commit('isLoading', true)
      const contactResponse = await serviceCustomer.updateOrCreate(payload.payload, payload.contact?.id);
      dispatch('saveCustomerContact', contactResponse)
    } catch (error) {
      console.error(`Não foi possivel salvar o token`, error);
    } finally {
      commit('isLoading', false)
    }
  },

  saveCustomerContact({ rootState }, contactResponse) {
    rootState.authentication.userInfo.contacts = rootState.authentication.userInfo?.contacts || [];

    if (contactResponse.statusText === "CREATED") {
      rootState.authentication.userInfo?.contacts?.push(contactResponse.data)
      return
    }
    rootState.authentication.userInfo?.contacts.forEach((contact) => {
      if (contact.id == contactResponse.id) {
        contact = contactResponse
      }
    })
  },
  async verifyPushPermission({ rootGetters, commit, dispatch }) {
    commit('isLoading', true);
    if (!("Notification" in window)) {
      console.debug("Sem suporte para notificação.");
      commit('isSupported', false);
      commit('isLoading', false);
      return;
    }

    const activeDeviceTokens = rootGetters["authentication/getActiveDeviceTokenContacts"]
    if (window.Notification.requestPermission && activeDeviceTokens) {
      commit('setNotification', window.Notification.permission === "granted")
    }

    if (window.Notification.permission === "denied") {
      commit('isSupported', false);
      commit('isLoading', false);
      return;
    }

    if (!state.notification && (!window.firebase || window.firebase.apps.length === 0)) {
      console.debug("Firebase não foi inicializado");
      commit('isSupported', false);
      commit('isLoading', false);
      return;
    }

    commit('isSupported', true);
    commit('isLoading', false);
  },
}
const getters = {
  notification: (state) => state.notification,
  loading: (state) => state.loading,
  supported: (state) => state.supported,
  dialog: (state) => state.dialog,
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};