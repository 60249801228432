import moment from "moment";

export default {
  checkbox: [(v) => !!v || "Este campo é obrigatório."],
  name: [(v) => !!v || "Nome é obrigatório."],
  required: [(v) => !!v || "Campo é obrigatório."],
  email: [
    (v) => !!v || "E-mail é obrigatório.",
    (email) => validateEmail(email),
  ],
  cellphone: [
    (v) => !!v || "Número é obrigatório.",
    (v) => {
      const regexTelefoneCelular = /^(\+\d{2}|0)[1-9]{2}9\d{8}$/
      return regexTelefoneCelular.test(v) || "Número deve ser válido."
    }
  ],
  phone: [
    (v) => {
      const regexTelefoneCelular = /^(?:(?:\+|00)55\s?)?([1-9][0-9])?\s?9\s?([6-9]\d{3}-?\d{4})$/;
      return regexTelefoneCelular.test(`${v}`.replace(/[^\w\s]/gi, '')) || "Número deve ser válido."
    }
  ],
  debt: [(v) => v != "0,00" || "Valor da dívida é obrigatório."],
  creditor: [(v) => !!v || "Credor é obrigatório."],
  cep: [
    (v) => !!v || "CEP é obrigatório.",
    (cep) => cep?.length == 10 || "CEP Invalido"
  ],
  uf: [(v) => !!v || "UF é obrigatório."],
  city: [(v) => !!v || "Cidade é obrigatório."],
  district: [(v) => !!v || "Bairro é obrigatório."],
  number: [(v) => !!v || "Número é obrigatório."],
  street: [(v) => !!v || "Rua é obrigatório."],
  ccv: [(v) => !!(v?.length == 3) || 'Preencha todos os números'],
  creditCard: [(v) => isValidCreditCardNumber(v)],
  cardExpirationDate: [(v) => isValidExpirationDate(v)]
};

const validateEmail = (email) => {
  const emailValidatorRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isValid = String(email).toLowerCase().match(emailValidatorRegex)
  if (isValid) return true;
  return "E-mail deve ser válido"
}
const isValidCreditCardNumber = (cardNumber) => {
  if (!cardNumber) return false;

  const sanitizedCardNumber = cardNumber.replace(/[\s-]+/g, "");

  if (!/^\d{13,19}$/.test(sanitizedCardNumber)) {
    return 'Número inválido';
  }

  const brands = [
    { name: "Visa", pattern: /^4\d{12}(\d{3})?$/ },
    { name: "Mastercard", pattern: /^(5[1-5]|2[2-7])\d{14}$/ },
    { name: "Hipercard", pattern: /^(38\d{17}|60\d{14})$/ },
    {
      name: "elo",
      pattern:
        /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
    },
  ];

  const matchingBrand = brands.find((brand) =>
    brand.pattern.test(sanitizedCardNumber)
  );

  if (matchingBrand) {
    return true;
  }

  return 'Bandeira invalida';
}
const isValidExpirationDate = (expDate) => {
  const expiration = moment(expDate, 'YYYY/MM')
  const currentDate = moment()
  return (expiration.isSameOrAfter(currentDate, 'month' ) && expDate.length == 7) || 'Data inválida'
}