import { app } from '@/main'
import store from "@/store"

class ErrorsRedirects {
  constructor(error) {
    this.error = error;
    this.app = app
    this.route = app.$route
    this.errorStatus = error?.response?.status ?? 0
  }

  redirect() {
    if (this.errorStatus == 503) {
      this.redirectTo503(this.error)
    }
    if ([500, 428, 0, undefined, null].includes(this.errorStatus)) {
      this.redirectTo500()
    }
  }

  redirectTo503(error) {
    store.commit('error/setError', { response: { ...error.response }, request: { ...error.request } }, { root: true })
    app.$router.replace({ name: 'Error5XX', params: { id: error.response.status, stay: true } }).catch((failure) => {
      app.skipIfNavigationFailureIsGeneric(failure)
    });
  }
  redirectTo500(){
    if (!['/erro', '/500'].includes(app.$router.currentRoute.path) && this.error?.config?.url.includes('generate') ) {
      app.$router.replace({ name: "500", params: {error: this.error, stay: true}}).catch((failure) => {
        app.skipIfNavigationFailureIsGeneric(failure)
      });
    }
  }
}

export {
  ErrorsRedirects
}