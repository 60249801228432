/**
 * Função para pegar do state o hdoc do cliente.
 * 
 * @param {String} hdoc Hdoc do cliente.
 * @return Hdoc do cliente.
 */
export const getHdoc = ({ hdoc }) => { return hdoc };

/**
 * Função para pegar do state o objeto do cliente.
 * 
 * @param {Object} client Objeto do cliente.
 * @return Objeto do cliente.
 */
export const getClient = ({ client }) => { return client };

/**
 * Função para pegar do passcode do cliente.
 * 
 * @param {Object} passcode Passcode do cliente.
 * @return Passcode do cliente.
 */
export const getTaskId = ({ taskId }) => { return taskId };