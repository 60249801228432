<template>
  <div>
    <MercadoPago
      v-if="name == 'proposal-agreed-after-payments' && creditorId == 17"
    />
    <MercadoLivre
      @openLink="$emit('openLink')"
      v-else-if="name == 'deal-proposals-after-operations' && creditorId == 17"
    />
    <Bradesco
      :deal="deal"
      v-else-if="name == 'deal-proposals-after-operations' && creditorId == 33"
    />
    <div v-else>
      <div
        v-for="alert in getAlerts(name, creditorId)"
        :class="contentClass"
        :key="alert.id"
        v-html="alert.content"
        class="qj-active-agreement-alert"
      ></div>
    </div>
  </div>
</template>

<script>
import MercadoPago from "./customAlerts/MercadoPago.vue";
import MercadoLivre from "./customAlerts/MercadoLivre.vue";
import Bradesco from "./customAlerts/Bradesco.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    MercadoPago,
    MercadoLivre,
    Bradesco,
  },
  props: {
    creditorId: Number,
    name: String,
    deal: Object,
    contentClass: String,
  },
  computed: {
    ...mapGetters("alert", {
      getAlerts: "getAlerts",
    }),
  },
};
</script>

<style lang="scss">
.qj-active-agreement-alert p {
  margin-bottom: 0px;
}
</style>
