<template>
  <div>
    <v-fade-transition>
      <div v-show="dialogTermsOfUse" class="qj-bg-dialog" @click="close"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      content-class="qj-dialog"
      transition="dialog-bottom-transition"
      v-model="dialogTermsOfUse"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Termos de uso</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text :class="hasDifferentTerms ? 'pa-0' : ''" :style="hasDifferentTerms ? 'overflow-y: hidden;' : ''">
          <iframe v-if="hasDifferentTerms" style="width: inherit; height: 100%; border-width: 0"
            :src="getIframeSource"></iframe>
          <p class="mb-0 mt-2 qj-p" v-html="msg" v-else></p>
        </v-card-text>
        <v-card-actions
          v-if="footer"
          class="actions-container" 
          :class="type ? type : 'default'"
          style="border-radius: 0px !important"
        >
          <div class="checkbox-container">
            <v-checkbox
              hide-details
              class="qj-checkbox pt-0 mt-0"
              v-model="checkbox"
              @click="setTerms()"
            >
              <template v-slot:label>
                <div class="qj-checkbox-label">
                  <div v-if="hasDifferentTerms">
                    Ao confirmar essa proposta, declaro que li e concordo com as
                    informações importantes, os detalhes dessa negociação, e
                    <b>{{ creditorTermsLabel }}</b>.
                  </div>
                  <div v-else>
                    Li e concordo com os
                    <b> Termos de Uso</b>.
                  </div>
                </div>
              </template>
            </v-checkbox>
          </div>
          <v-btn
            :disabled="!terms"
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn proceed-button"
            @click="next"
          >
            <div style="margin-right: auto">
              {{ forceDownload ?
                "Baixar e prosseguir" :
                "Prosseguir"
              }}
            </div>
            <v-icon style="font-size: 20px">fas fa-long-arrow-alt-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import util from "@/assets/js/util";
import serviceUtil from "@/services/util";

export default {
  props: [
    "dialogTermsOfUse",
    "terms",
    "footer",
    "type",
    "url",
    "base64Code",
    "id",
    "hasDifferentTerms",
    "forceDownload",
    "creditorTermsLabel"
  ],
  data: () => ({
    checkbox: false,
    msg: "Aguarde...",
    deal: null,
  }),
  created() {
    let url = this.url;

    if (this.id) {
      let proposalId = util.base64Decode(this.id);
      let deal = util.getDealByProposalId(proposalId);

      if (!deal) {
        console.debug("Negociação não encontrada.");
        return;
      }

      this.deal = deal;
      let configPrefix = util.getPrefix(deal.id);

      if (!configPrefix) return;

      url = configPrefix
        ? util.getConfig()[configPrefix + "asset.confirmation_terms"]
        : false;
    }

    if (!url && !this.base64Code) {
      this.$emit("hide", true);
      return;
    }

    this.execUrl(url);
  },
  watch: {
    terms() {
      this.checkbox = this.terms;
    },
    url() {
      if (this.url) {
        this.$emit("hide", false);
        this.execUrl(this.url);
      }
    },
    base64Code() {
      if (this.base64Code) {
        this.$emit("hide", false);
      }
    },
  },
  computed: {
    getIframeSource() {
      if (this.url) return this.url;
      return `data:application/pdf;base64,${this.base64Code}`;
    },
  },
  methods: {
    execUrl(url) {
      serviceUtil.generic(url).then((res) => (this.msg = res.data));
    },
    close() {
      this.$emit("close");
    },
    setTerms() {
      this.$emit("setTerms");
    },
    next() {
      this.$emit("next");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
  box-shadow: 0em 1em 1em 0.5em black;
  margin-bottom: 1em;
  padding-bottom: 2em;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  button {
    width: 25em;
  }
}

.v-icon.mdi-checkbox-blank-outline {
  color: var(--primary) !important;
}

.v-icon.mdi-checkbox-marked {
  color: var(--primary) !important;
  caret-color: var(--primary) !important;
}

.proceed-button {
  width: 228px !important;
  height: 40px !important;
  margin-top: 20px;
  display: flex;

  .text {
    display: flex;
    flex: 1;
  }
}

.itau {
  height: 188px;
}

.default {
  height: 150px;
}

.actions-container {
  .v-input--selection-controls {
    margin-top: 8px;
    padding-top: none;
  }
}

.checkbox-container {
  list-style-type: none;
  background: #f6f6f6;
  border-radius: 7px;
  padding: 10px;
  line-height: initial;
}
</style>
