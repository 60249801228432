import { checkPrePayment } from "@/middlewares/parceleJa";

export default [
  {
    path: "/negociacao/divida",
    name: "NegotiationDeal",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/Deal"),
    meta: {
      title: "Dívida",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
    beforeEnter: (to, from, next) => checkPrePayment(to, from, next),
  },
  {
    path: "/negociacao/pagamento",
    name: "NegotiationPaymentInfo",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/PaymentInfo"),
    meta: {
      title: "Pagamento",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
  {
    path: "/negociacao/contato",
    name: "NegotiationContactInfo",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/ContactInfo"),
    meta: {
      title: "Contato",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
  {
    path: "/negociacao/credit",
    name: "CreditCardPayment",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/InsertCreditCard"),
    meta: {
      title: "Pagamento",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
  {
    path: "/negociacao/pin",
    name: "PinChecker",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/PinChecker"),
    meta: {
      title: "Pagamento",
      requiresAuth: true,
      showMenu: false,
      showStep: true,
    },

  },
  {
    path: "/negociacao/confirmar",
    name: "NegotiationConfirmDeal",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/ConfirmDeal"),
    meta: {
      title: "Confirmar negociação",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
  {
    path: "/negociacao/forma-de-pagamento",
    name: "NegotiationPaymentMethod",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/PaymentMethod"),
    meta: {
      title: "Forma de pagamento",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
  {
    path: "/negociacao/credit-card",
    name: "NegotiationParceleJa",
    component: () =>
      import(/* webpackChunkName: "negotiation" */ "./pages/PaymentCreditCard"),
    meta: {
      title: "Forma de pagamento",
      requiresAuth: true,
      showMenu: true,
      showStep: true,
    },
  },
];
