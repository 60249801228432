import http from "@/plugins/axios";
const VERSION = "v2";
const RESOURCE = "nps_answer";
const RESOURCE_URL = VERSION + '/' + RESOURCE

export default {
  get(id) {
    return http.api.get(RESOURCE_URL + '/' + id)
  },
  search(params) {
    return http.api.get(RESOURCE_URL, { params })
  },
  save(data) {
    if (data.id) {
      return http.api.patch(RESOURCE_URL + '/' + data.id, data)
    }
    return http.api.post(RESOURCE_URL, data)
  },
  delete(id) {
    return http.api.delete(RESOURCE_URL + '/' + id)
  },
};