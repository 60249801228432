<template>
  <v-row class="pt-1 pb-0 justify-center">
    <!-- Btn next -->
    <v-col cols="auto">
      <v-btn
        block
        :disabled="disabled || loading"
        :loading="loading"
        class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
        @click="send"
      >
        <div class="text-center">{{ label }}</div>
      </v-btn>
    </v-col>
    <!-- Btn help -->
    <v-col cols="auto" class="pl-0">
      <v-btn icon class="rounded-lg qj-btn qj-bg-primary-light" @click="help">
        <v-icon>far fa-question-circle</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { createTagManagerEvent } from '@/utils'


export default {
  props: {
    loading: Boolean,
    disabled: Boolean,
    label: String,
  },
  methods: {
    send() {
      this.$emit("send");
    },
    help() {
      createTagManagerEvent({
        event: 'clickedHelp'
      })
      this.$emit("help");
    },
  },
};
</script>

<style>
.qj-btn {
  margin-left: 3px;
}
</style>
