import AlertService from "@/services/alert";
import * as types from "./mutation-types";

const state = {
  alerts: [],
  modalAlertState: {
    open: false,
    content: "",
  },
  exactValueState: {
    showExactValue: false,
    exactValue: 0
  }
}

const mutations = {
  [types.SET_ALERTS](state, payload) {
    state.alerts = payload;
  },
  [types.SET_MODAL_ALERT_STATE](state, payload) {
    state.modalAlertState = payload;
  },
  [types.SET_EXACT_VALUE_STATE](state, payload) {
    state.exactValueState = payload;
  },
}

const actions = {
  // Carrega todos os alertas da aplicação
  async search({ commit }){
    return AlertService.search().then(res => {
      commit(types.SET_ALERTS, res.data.objects);
    });
  },
};

const getters = {
  getModalAlertState: state => state.modalAlertState,
  getExactValueState: state => state.exactValueState,
  getAlert: (state) => (name, creditorId) => {
    return state.alerts.find(alert => {
      return (alert.name == name && alert.creditor_id == creditorId)
    })
  },
  getAlerts: (state) => (name, creditorId) => {
    let alerts = []

    state.alerts.forEach(alert => {
      if (alert.name != name) {
        return;
      }

      if (creditorId != null && alert.creditor_id != null &&
          creditorId != alert.creditor_id) {
        return;
      }

      alerts.push(alert);
    });

    return alerts;
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
