import * as Sentry from "@sentry/browser";
class SentryError extends Error {
  constructor({ message = "", axiosError = null }, ...args) {
    super(message, ...args)
    Sentry.addBreadcrumb({
      category: "error",
      message: "error status: " + (axiosError?.response?.status || 0),
      level: "error",
    });
    if (axiosError) {
      Sentry.configureScope((scope) => {
        scope.setTag('error_code', axiosError?.response?.status || 0)
        scope.setContext("request", axiosError?.request);
        scope.setContext("response", axiosError?.response);
        scope.setContext("response headers", axiosError?.response?.headers);
        scope.setContext("response errors", axiosError?.response?.data?.errors);
      });
    }
  }
} 


class GenerateProposalsError extends SentryError {
  constructor({ message = "", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "GenerateProposalsError";
    this.message = message;
  }
}

class LoadConfigurationsError extends SentryError {
  constructor({ message = "Não foi possível carregar as configurações", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "LoadConfigurationsError";
    this.message = message;
  }
}

class LoadDealsError extends SentryError {
  constructor({ message = "Não foi possível carregar os deals", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "LoadDealsError";
    this.message = message;
  }
}
class LoadDealError extends SentryError {
  constructor({ message = "Não foi possível carregar o deal", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "LoadDealError";
    this.message = message;
  }
}
class SaveAddressError extends SentryError {
  constructor({ message = "Não foi possível salvar o endereço", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "SaveAddressError";
    this.message = message;
  }
}

class SaveContactError extends SentryError {
  constructor({ message = "Não foi possível salvar o contato", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "SaveContactError";
    this.message = message;
  }
}

class ConfirmProposalError extends SentryError {
  constructor({ message = "Não foi possivel atualizar a proposta.", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "ConfirmProposalError";
    this.message = message;
  }
}

class ContactMeError extends SentryError {
  constructor({ message = "", axiosError = null }, ...args) {
    super({ message: message, axiosError: axiosError }, ...args);
    this.name = "ContactMeError";
    this.message = message;
  }
}

export {
  ConfirmProposalError,
  GenerateProposalsError,
  LoadConfigurationsError,
  SaveAddressError,
  SaveContactError,
  LoadDealsError,
  LoadDealError,
  ContactMeError
}
