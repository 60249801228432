<template>
  <v-container class="py-0">
    <v-row class="header-steps">
      <v-col
        v-if="showStep"
        class="d-flex w-100 pa-0 align-center"
        :class="'justify-' + (showBackButton ? 'space-between' : 'end')"
      >
        <div v-if="showBackButton" class="back-button" @click="backPage()">
          <v-icon>mdi-arrow-left-circle-outline</v-icon>
          <a
            href="javascript:void(0)"
            class="qj-link qj-color-link ml-1"
            id="back-previous-link"
            >Voltar</a
          >
        </div>
        <div class="d-flex align-end">
          <span class="qj-span">Passo {{ step }} de {{ totalStep }}</span>
        </div>
      </v-col>
      <div style="height: 19px" v-else></div>
    </v-row>
    <v-row class="text-center">
      <v-col
        v-if="showOwnLogo || profileLogo"
        class="text-center qj-creditor-logo"
      >
        <img
          v-if="showOwnLogo"
          src="@/assets/img/logo/logo_quiteja.svg"
          class="qj-img"
          alt="QuiteJá"
        />
        <img
          v-else
          class="qj-img"
          :src="profileLogo"
          :alt="`Logotipo da empresa`"
        />
      </v-col>
    </v-row>
    <v-row v-if="(!$route.meta.doNotShowMyData && $route.meta.showTabs) && $vuetify.breakpoint.width > 599">
      <v-spacer></v-spacer>
      <v-col
        cols="auto"
        class="d-flex justify-space-between pt-0 pb-2 px-0"
        v-if="showMenu && user"
      >
        <div class="d-flex align-center" @click="openMyData()">
          <v-icon color="#00CA7C" size="22">mdi-account</v-icon>
          <a
            href="javascript:void(0)"
            class="qj-link qj-color-secondary"
            id="link-my-data"
            style="color: #00CA7C !important;"
            >Meus dados
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="$route.meta.showTabs">
      <v-tabs
        v-if="$vuetify.breakpoint.width > 599"
        ref="tabs"
        lazy
        v-model="panel"
        color="#00E58D"
        fixed-tabs
        centered
        background-color="transparent"
        class="qj-tabs mb-4 shrink"
        active-class="no-bg"
      >
        <v-tab v-for="item in items" :key="item.id">
          <span class="text-capitalize qj-tab"> {{ item.text }} </span>
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row>
      <v-col
        class="d-flex justify-space-between pt-0 pb-2 px-0"
        v-if="showMenu && userLogged && $route.meta.requiresAuth"
      >
        <span
          v-if="getUserName()"
          class="p-name mb-0"
          :class="{ 'p-business': isPersonalUser(user) }"
          >{{ getUserName(user) }},</span
        >
        <v-spacer v-else></v-spacer>
        <div
          v-if="
           !$route.meta.doNotShowMyData &&
            ($vuetify.breakpoint.width <= 599 || !$route.meta.showTabs)            
          "
          class="d-flex align-center"
          @click="openMyData()"
        >
          <v-icon color="#00CA7C" size="22">mdi-account</v-icon>
          <a
            href="javascript:void(0)"
            class="qj-link qj-color-secondary"
            id="link-my-data"
            style="white-space: nowrap; color: #00CA7C !important;"
            >Meus dados
          </a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  props: {
    step: Number,
    totalStep: Number,
    showStep: Boolean,
    showMenu: Boolean,
    showOwnLogo: Boolean,
    showBackButton: Boolean,
    backPage: Function,
    openMyData: Function,
    profileLogo: String,
  },
  data() {
    return {
      items: [
        {
          id: 1,
          text: "Minhas dívidas",
          active: true,
        },
        {
          id: 2,
          text: "Meus acordos",
          active: false,
        },
      ],
    };
  },
  methods: {
    ...mapMutations("home", ["setPannel"]),
    isPersonalUser(user) {
      return user?.type === "BUSINESS";
    },
    getUserName() {
      const user = this.user;
      if (this.isPersonalUser(user))
        return user?.name.replace(/\d+/g, "").trim().toLowerCase();
      if (user?.name) return user?.name.split(" ")?.[0].toLowerCase();
      return false;
    },
  },
  computed: {
    ...mapGetters("home", {
      pannelGetter: "pannel",
    }),
    ...mapGetters("negotiation", {
      user: "getMe",
    }),
    userLogged() {
      return this.user?.id;
    },
    panel: {
      get: function () {
        return this.pannelGetter;
      },
      set: function (value) {
        this.setPannel(value);
      },
    },
  },
};
</script>
<style lang="scss">
.deals-debts-pannels {
  .v-expansion-panel-content__wrap {
    padding: 0px 15px;
  }

  .v-expansion-panel-header__icon {
    margin-left: 0px;
    width: 0px;
    i {
      color: #00e58d !important;
    }
  }
}
.no-bg {
  &::before {
    background-color: transparent;
  }
}
.qj-tab {
  font-size: 14px;
  font-weight: 600 !important;
}
.qj-tabs {
  @media (min-width: 600px) {
    width: 520px !important;
    margin-left: -16px;
    margin-right: -16px;
  }
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}
</style>
<style lang="scss" scoped>
.qj-color-link {
  margin-left: 4px;
}
.back-button {
  cursor: pointer;
}
.qj-logo {
  width: 50%;
}
.p-name {
  font-weight: 800;
  line-height: 22px;
  font-size: 20px;
  width: 60%;
  text-transform: capitalize !important;
}
.p-business {
  font-size: 16px;
  line-height: 115.9%;
}
</style>