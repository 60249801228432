const state = {
  error: null
}

const mutations = {
  setError(state, payload){
    state.error = payload
  }
}

const actions = {

};

const getters = {
  getErrorResponse(state) { return state.error?.response ?? null },
  getErrorRequest(state) { return state.error?.response ?? null },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};