export default [
  {
    path: '/login',
    name: 'Authentication',
    component: () => import(/* webpackChunkName: "public" */ './pages/Login'),
    meta: {
      title: "Login",
      requiresAuth: false,
      showMenu: false,
      doNotShowMyData: true,
      showStep: false,
      canShowLogo: true
    },
    alias: [
      '/acessar',
      '/confirmar',
      '/verificar',
    ]
  },
  {
    path: '/login/telefone',
    name: 'AuthLanding',
    component: () => import(/* webpackChunkName: "public" */ './pages/Landing'),
    meta: {
      title: "Telefone",
      requiresAuth: false,
      showMenu: true,
      doNotShowMyData: true,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/login/codigo',
    name: 'AuthPasscode',
    component: () => import(/* webpackChunkName: "public" */ './pages/Passcode'),
    meta: {
      title: "Código verificador",
      requiresAuth: false,
      doNotShowMyData: true,
      showMenu: true,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/login/aniversario',
    name: 'AuthBirthday',
    component: () => import(/* webpackChunkName: "public" */ './pages/Birthday'),
    meta: {
      title: "Confirmar",
      requiresAuth: false,
      doNotShowMyData: true,
      showMenu: true,
      showStep: false,
      canShowLogo: true
    },
  },
  {
    path: '/login/nome-mae',
    name: 'AuthMotherName',
    component: () => import(/* webpackChunkName: "public" */ './pages/MotherName'),
    meta: {
      title: "Nome da mãe",
      requiresAuth: false,
      doNotShowMyData: true,
      showMenu: true,
      showStep: false,
      canShowLogo: true
    },
  },
];