import store from "@/store";
import * as Sentry from "@sentry/browser";
import Deal from "@/modules/deal";
import serviceAuth from "@/services/authentication";
import serviceUtil from "@/services/util";
import serviceDeal from "@/services/deal";
import serviceCustomer from "@/services/customer";
import serviceProposal from "@/services/proposal";
import ServiceParceleJa from "@/services/parceleJa";
import { V2 as serviceCustomerV2 } from "@/services/customer";
import { initWebSocket } from "@/plugins/websocket";

export default {
  async getSyncs({ commit }) {
    try {
      const { data } = await serviceDeal.syncs.get();
      commit("setCreditorsToSync", data);
    } catch (e) {
      console.error(e);
      return undefined;
    }
  },
  async ActionsLogar({ commit }, payload) {
    return serviceAuth
      .login(payload)
      .then((res) => commit("setToken", res.data.token));
  },

  async loginCustomer({ commit }, payload) {
    return serviceAuth
      .loginCustomer(payload.data, payload.token)
      .then((res) => commit("ActiosetTokennsSetToken", res.data.token));
  },

  async ActionsMe({ commit }) {
    return serviceCustomerV2.me().then((res) => {
      commit("setMe", res.data);
      initWebSocket();
      if (res?.data?.id) {
        Sentry.setUser({
          id: res.data.id,
          document: res.data.document,
          status: res.data.status,
          type: res.data.type,
          username: res.data.name,
        });
      }
      return res.data;
    });
  },

  async ActionsDeals({ commit }, payload) {
    let config = {};
    if (payload) config = { bring_fomentation: payload };
    return serviceCustomerV2.deals(config).then((res) => {
      let deals = Deal.filterValidDeals(res.data.deals);
      commit("setDeals", deals);
    });
  },

  async getSyncedDeals({ commit }, adapter) {
    const justSynced = true;
    const { data } = await serviceCustomerV2.deals(justSynced);
    // await commit("setAllDeals", data.deals);
    await commit("pushDeals", data.deals);
    return data.deals;
  },
  async getPrePayments({ commit }) {
    try {
      const { data } = await ServiceParceleJa.getPrePayments();
      commit("setPrePayments", data.objects);
    } catch (err) {
      console.error(err);
    }
  },

  async getDeals({ commit }, adapter) {
    const { data } = await serviceCustomerV2.deals();
    await commit("setAllDeals", data.deals);
    await commit("pushDeals", data.deals);
    return data.deals;
  },

  async getAgreements({ commit }) {
    const { data } = await serviceCustomerV2.agreements();
    await commit("pushDeals", data.deals);
    return data.deals;
  },

  async getAgreement({ commit }, dealId) {
    const { data } = await serviceCustomerV2.agreement(dealId);
    await commit("updateDeal", data?.deals[0]);
    return data?.deals[0];
  },

  async ActionsTask({ commit }) {
    const taskResult = await serviceCustomer.deals({ sync: true });
    if (taskResult.data?.task) commit("setTask", taskResult.data.task);
    return taskResult;
  },

  async ActionsConfiguration({ commit }) {
    return serviceUtil
      .configuration()
      .then((res) => commit("setConfiguration", res.data));
  },


  async ActionsDeal({ commit }, payload) {
    return serviceDeal.deal(payload.deal_id).then((res) => {
      let newDeals = store.getters["negotiation/getDeals"];
      newDeals.forEach((deal) => {
        if (deal.id == payload.deal_id) deal = res.data;
      });
      commit("setDeals", newDeals);
      return res.data;
    });
  },

  async ActionsGenerate({ commit }, payload) {
    return serviceProposal.generate(payload).then((res) => {
      let newDeals = store.getters["negotiation/getDeals"];
      newDeals.forEach((deal) => {
        if (deal.id == payload.deal_id) deal.proposals = res.data;
      });
      commit("setDeals", newDeals);
    });
  },

  async ActionsSimulate({ commit }, payload) {
    return serviceProposal.simulate(payload).then((res) => {
      let newDeals = store.getters["negotiation/getDeals"];
      newDeals.forEach((deal) => {
        if (deal.id == payload.deal_id) deal.proposals.push(res.data);
      });
      commit("setDeals", newDeals);
      return res;
    });
  },
  async updateProposal({ commit }, { proposal = null, status = null } = {}) {
    try {
      await serviceProposal.update({ id: proposal.id, status });
    } catch (error) {
      console.error(
        "Não foi possível atualizar a proposta",
        proposal.id,
        error
      );
      throw new Error(error);
    }
  },
  async validateProposal({ commit }, { proposalId = undefined } = {}) {
    let status;
    try {
      await serviceProposal.get(proposalId).then((res) => {
        status = res.data.status;
      });
    } catch (error) {
      console.error("Não foi possível consulta a proposta.", error);
      return false;
    }
    if (status != "CREATED" && status != "SELECTED") return false;
    return true;
  },
  async fetchContractDetails({ commit, state }, payload) {
    if (state.contractInfo.deal_id === payload.deal_id) {
      return;
    } else {
      return serviceDeal.getContractDetails(payload).then((res) => {
        res.data.deal_id = payload.deal_id;
        commit("setContractInfo", res.data);
      });
    }
  },
  openDialogCreditorsOff({ commit, state }) {
    state.dialogCreditorsOff = true;
  },
};
