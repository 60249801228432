<template>
    <div v-if="modalAlertState.open">
        <v-fade-transition>
            <div class="qj-bg-dialog" @click="close"></div>
        </v-fade-transition>
        <v-dialog
            fullscreen
            scrollable
            persistent
            content-class="qj-dialog"
            transition="dialog-bottom-transition"
            v-model="modalAlertState.open"
        >
            <v-card>
                <v-card-title>
                    <v-col cols="12 pt-0" style="position: relative">
                        <h2 class="qj-h3 text-center">Informações Importantes</h2>
                        <v-btn icon class="qj-btn-close-dialog" @click="close">
                            <v-icon>far fa-times-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <p class="mb-0 mt-2 qj-p" v-html="modalAlertState.content"></p>
                </v-card-text>
                <!-- Footer -->
                <qj-footer
                    type="next"
                    :fixed="false"
                    :alert="true"
                    @send="close"
                    view="negociacao/confirmar"
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
    methods: {
        ...mapMutations('alert', ['setModalAlertState']),
        close() {
            this.setModalAlertState({
                open: false,
                content: '',
            })
        },
    },
    computed: {
        ...mapGetters('alert', {
            modalAlertState: 'getModalAlertState'
        }),
    },
};
</script>

<style lang="scss" scoped>
.v-card__actions {
    box-shadow: 0em 1em 1em 0.5em black;
    margin-bottom: 1em;
    padding-bottom: 2em;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    button {
        width: 25em;
    }
}

.v-icon.mdi-checkbox-blank-outline {
    color: var(--primary) !important;
}
.v-icon.mdi-checkbox-marked {
    color: var(--primary) !important;
    caret-color: var(--primary) !important;
}

.proceed-button {
    width: 228px !important;
    height: 40px !important;
    margin-top: 20px;
    display: flex;
    .text {
        display: flex;
        flex: 1;
    }
}

.actions-container {
    height: 150px;
    .v-input--selection-controls {
        margin-top: 8px;
        padding-top: none;
    }
}

.checkbox-container {
    list-style-type: none;
    background: #f6f6f6;
    border-radius: 7px;
    padding: 10px;
    line-height: initial;
}
</style>
