import store from "@/store";

const checkPrePayment = (to, from, next) => {  
  // try {
  //   let selectedDealId = window.atob(to.query?.id);
  //   let selectedDeal = store.getters["negotiation/getDeal"](selectedDealId) || store.getters["negotiation/getSelectedDeal"];
  //   const redirects = {
  //     WAITING_PIN_VALIDATION: "PinChecker",
  //     WAITING_PROPOSAL_CONFIRMATION: "NegotiationConfirmDeal",
  //     PIN_VALIDATION_FAIL: 'NegotiationDeal'
  //   };
  //   const prePayment = selectedDeal.prePayment;
  //   if (!prePayment) {
  //     next()
  //     return
  //   }
  //   const selectedProposal = selectedDeal.proposals.find(
  //     (proposal) => proposal.id === prePayment.proposal_id
  //   );

  //   if (redirects[prePayment.quiteja_status] && to.name !== redirects[prePayment.quiteja_status]) {
  //     store.commit("negotiation/setSelectedDeal", selectedDeal, { root: true });
  //     store.commit(
  //       "negotiation/setNegotiation",
  //       {
  //         email: selectedDeal.prePayment.payer_email,
  //       },
  //       { root: true }
  //     );
  //     const nextRoutte = {
  //       name: redirects[prePayment.quiteja_status],
  //       query: { id: window.btoa(prePayment.proposal_id) },
  //       params: {
  //         deal: selectedDeal,
  //         proposal: selectedProposal,
  //         date: undefined,
  //         isParceleJa: true,
  //       },
  //     }
  //     next({...nextRoutte})
  //     return;
  //   }
  //   else {
  //     next()
  //   }
  // } catch (e) {
  //   console.error(e)
  //   next();
  // }
  next()
};

export { checkPrePayment };
