import Vue from "vue"
import moment from "moment"

/** Retorna o número de uma conta-corrente. */
Vue.filter("bankAccountNumber", bankAccount => {
  if (!bankAccount || !bankAccount.account_number) {
    return "";
  }

  if (bankAccount.account_number_check_digit) {
    return bankAccount.account_number + "-" + bankAccount.account_number_check_digit;
  }

  return bankAccount.account_number;
})

/** Retorna a agência de uma conta-corrente. */
Vue.filter("bankAccountBranch", bankAccount => {
  if (!bankAccount || !bankAccount.branch_code) {
    return "";
  }

  if (bankAccount.branch_code_check_digit) {
    return bankAccount.branch_code + "-" + bankAccount.branch_code_check_digit;
  }

  return bankAccount.branch_code;
})

Vue.filter("currency", value => {
  if (!value) return value;
  if (typeof value !== "number" && typeof value !== "string") return value;
  if (isNaN(value)) return value;

  return value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });
})

Vue.filter("customerDocument", document => {
  if (!document) {
    return '';
  }

  let formattedDocument = document;

  if (document.length == 14) {
    formattedDocument = document.padStart(14, '0');
    formattedDocument = formattedDocument.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  } else {
    formattedDocument = document.padStart(11, '0');
    formattedDocument = formattedDocument.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return formattedDocument;
})

Vue.filter("customerType", customerType => {
  switch (customerType) {
    case "PERSONAL":
      return "Pessoa Física"

    case "BUSINESS":
      return "Pessoa Jurídica"

    default:
      return customerType
  }
})

Vue.filter("percent", value => {
  try {
    return value.toLocaleString('pt-br', { style: 'percent', minimumFractionDigits: 2 });
  } catch {
    return value;
  }
})

Vue.filter("proposalPaymentMethod", (value) => {
  const types = {
    "BOLETO": 'Boleto',
    "DEBIT_CARD": 'Débito automático',
    "FIRST_BOLETO_OTHERS_DEBIT": 'Débito automático',
    "FIRST_BOLETO_OTHERS_CREDIT": 'Cartão',
    "PARCELE_JA": 'Cartão de crédito'
  }
  return types[value] ?? value
})

Vue.filter("proposalPaymentType", value => {
  const types = {
    "IN_CASH": 'À vista',
    "INSTALLMENT": 'Parcelado',
  }
  return types[value] ?? value
})

Vue.filter("money", value => {
  if (typeof value !== "number" && typeof value !== "string" || isNaN(value)) return value;
  return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
})

Vue.filter("data", date => {
  if (typeof date !== "string" || !date) return date;
  date = date.split('-');
  let newDate = new Date(date?.[0], date[1] - 1, date[2]);
  let dias = new Array(
    'domingo',
    'segunda-feira',
    'terça-feira', 'quarta-feira',
    'quinta-feira',
    'sexta-feira',
    'sábado'
  );
  let meses = new Array(
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  );
  return newDate.getDate() + " de " + meses[newDate.getMonth()] + " / " + dias[newDate.getDay()];
})
Vue.filter('brazilianDate', (date) => {
  return moment(date).format('DD/MM/YYYY')
})

Vue.filter("cellphone", phone => {
  if (typeof phone !== "number" & "string") return phone;
  let cleaned = ('' + phone).replace(/\D/g, '');
  let match = [];
  if (cleaned.length === 11) {
    match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  } else {
    match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
  }

  if (match) return `(${match[1]}) ${match[2]}-${match[3]}`;
  return null;
})

Vue.filter("value", valor => {
  if (!valor) return valor;
  if (typeof valor !== "number" && typeof valor !== "string") return valor;
  if (isNaN(valor)) return valor;
  return valor.toLocaleString('pt-BR');
})

Vue.filter("creditCard", valor => {
  if (!valor) return valor;
  let splitedNumbers = valor.split(' ')
  return '****.****.****.' + splitedNumbers[3]
})
