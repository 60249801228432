import moment from "moment";
import { CreditorId } from "@/constants";

/**
 * Retorna true se é permitida fazer simulação para a negociação.
 * 
 * @param {Object} deal a negociação.
 * @param {Array} policies as políticas da negociação.
 * @returns true se permite fazer simulação.
 */
function allowsSimulation(deal, policies = []) {
  if (!deal || !deal.creditor.allows_simulation) {
    return false;
  }
  let proposals = deal.proposals || [];

  if (proposals.length == 0) {
    return false;
  }

  if (deal.customer_params &&
    deal.customer_params["allows_simulation"] !== null) {
    return deal.customer_params["allows_simulation"];
  }

  return deal.creditor["allows_simulation"];
}

/**
 * Retorna true se é permitido informar a entrada na simulação.
 * 
 * @param {Object} deal a negociação.
 * @returns true se permite informar a entrada na simulação.
 */
function allowsSimulationDownPayment(deal) {
  if (!deal) {
    return false;
  }

  if (deal.customer_params &&
    deal.customer_params["allows_down_payment"] !== null) {
    return deal.customer_params["allows_down_payment"];
  }

  return deal.creditor["allows_down_payment"];
}

/**
 * Retorna true se é permitido informar a quantidade de parcelas na simulação.
 * 
 * @param {Object} deal a negociação.
 * @param {Array} configurations a lista de configurações.
 * @returns true se permite informar a quantidade de parcelas na simulação.
 */
function allowsSimulationInstallmentsCount(deal, configurations) {
  if (!deal) {
    return false;
  }

  if (deal.customer_params &&
    deal.customer_params["ask_installment_count"] !== null) {
    return deal.customer_params["ask_installment_count"];
  }

  if (!configurations) {
    return false;
  }

  let config = configurations.find((config) => {
    return config.key == deal.creditor.config_prefix +
      "simulation.ask_installments_count";
  });

  if (!config) {
    return false;
  }

  return config["value"] == "true";
}

/**
 * Retorna true se o cliente pode escolher se quer fazer simulação sem entrada.
 * 
 * @param {Object} deal a negociação.
 * @param {Array} configurations a lista de configurações.
 * @returns true se o cliente pode escolher se quer fazer simulação sem entrada.
 */
function askWithoutDownPayment(deal, configurations) {
  if (!deal) {
    return false;
  }

  if (!configurations) {
    return false;
  }

  let config = configurations.find((config) => {
    return config.key == deal.creditor.config_prefix +
      "simulation.ask_without_down_payment";
  });

  if (!config) {
    return false;
  }

  return config["value"] == "true";
}

function forceWithoutDownPayment(deal, configurations) {
  if (!deal) {
    return false;
  }

  if (!configurations) {
    return false;
  }

  let config = configurations.find((config) => {
    return config.key == deal.creditor.config_prefix +
      "simulation.force_without_down_payment";
  });

  if (!config) {
    return false;
  }

  return config["value"] == "true";
}

/**
 * Retorna o valor total da dívida baseado nas operações ativas da negociação.
 * 
 * @param {Array} operations lista de operações.
 * @returns o valor total.
 */
function calculateDebts(operations) {
  if (!operations) {
    return 0;
  }

  let total = 0;

  operations
    .filter((operation) => operation.active)
    .forEach((operation) => {
      total = total + operation.debit_value;
    });

  return total;
}

/**
 * Retorna uma proposta à vista para uma negociação.
 * 
 * @param {Object} deal a negociação.
 * @returns uma proposta ou null caso não seja possível criar.
 */
function createIncashProposal(deal) {
  if (!deal?.proposals) return null;


  var proposals = [...deal.proposals];
  let orderedProposals = proposals.sort((proposalA, proposalB) => proposalA.highlight - proposalB.highlight);


  return orderedProposals.find(proposal => proposal.payment_type === 'IN_CASH');
}

/**
 * Retorna uma lista com os dias possíveis para o vencimento das parcelas.
 * Só serão retornados dias com no máximo 15 dias até o vencimento.
 * 
 * @param {String} downPaymentDueDate data de vencimento da entrada.
 * @param {Array} configInstallmentBirthdays lista de dias possíveis.
 * @returns uma lista de dias.
 */
function createInstallmentDueDates(downPaymentDueDate, configInstallmentBirthdays) {
  downPaymentDueDate = moment(downPaymentDueDate);

  return configInstallmentBirthdays.filter((day) => {
    let date = moment(downPaymentDueDate.format(day + "-MM-YYYY"), "DD-MM-YYYY");
    return date.diff(downPaymentDueDate, "days") <= 15;
  });
}

/**
 * Filtra e retorna uma lista de negociações que serão consideradas válidas
 * para exibição para o cliente.
 * 
 * @param {Array} deals todas as negociações.
 * @returns uma lista de negociações válidas.
 */
function filterValidDeals(deals) {
  if (!deals) {
    return [];
  }

  let invalidStatus = [
    "ABORTED",
    "RENEGOTIATED",
  ];

  let validAbortedCauses = [
    "ACTIVE_AGREEMENT",
    "DOWN_PAYMENT_EXPIRED",
    "INTERRUPTED",
    "PAYMENT_EXPIRED",
  ];

  return deals.filter((deal) => {
    if (invalidStatus.indexOf(deal.status) == -1) {
      // O status é válido
      return true;
    }

    if (deal.status == "ABORTED" &&
      validAbortedCauses.indexOf(deal.aborted_cause) != -1) {
      // O motivo da negociação estar abortada é valido
      return true;
    }

    if (deal.status == "AGREED") {
      return true;
    }

    if (deal.type != "RENEGOTIATION" || deal.type != "FOMENTATION") {
      return true;
    }

    return false;
  });
}

/**
 * Retorna a proposta que representa o carrinho abandonado.
 */
function getAbandonedProposal(proposals) {
  if (!proposals) {
    return null;
  }

  proposals = proposals.filter(proposal => {
    return proposal.type == "ABANDONED_CART";
  });

  if (proposals.length == 0) {
    return null;
  }

  // Ordena para pegar a proposta atualizada mais recentemente
  proposals.sort((proposalA, proposalB) => {
    return moment(proposalB.updated_at).toDate() -
      moment(proposalA.updated_at).toDate();
  });

  return proposals?.[0];
}

/**
 * Retorna o cedente da negociação, caso exista.
 * 
 * @param {Object} deal a negociação.
 * @returns o nome do cedente.
 */
function getAssignor(deal) {
  // Comprimento máximo para o nome do cedente
  const MAX_ASSIGNOR_LENGTH = 31;

  var operations = deal.operations.filter((operation) => {
    return operation.active === true;
  });

  if (operations.length == 0) {
    return null;
  }

  let assignor = operations?.[0]?.assignor;

  if (!assignor) {
    return null;
  }

  let formattedName = "";

  // Concatena as partes do nome do cedente desde que fique menor que o
  // comprimento máximo permitido
  for (const name of assignor.split(" ")) {
    let newName = (formattedName + " " + name).trim();

    if (newName.length <= MAX_ASSIGNOR_LENGTH) {
      formattedName = newName;
    } else {
      break;
    }
  }

  return formattedName;
}

/**
 * Retorna todas as propostas que devem ser exibidas em destaque de acordo com o Tipo da Oferta.
 * 
 * @param {Array} proposals a lista com todas as propostas.
 * @param {Object} offerType o Tipo da Oferta para exibir no destaque.
 * @returns true se a proposta deve ser destacada.
 */
function getHighlightedProposals(proposals, offerType) {
  if (!proposals) {
    return [];
  }

  if (!offerType) {
    return [];
  }

  // Pega todas as propostas parceladas
  let installmentProposals = proposals.filter((p) => {
    return p.payment_type == "INSTALLMENT";
  });


  let offerTypes = offerType.split(",");

  let maxInstallmentProposal = installmentProposals.length > 0
    ? installmentProposals[installmentProposals.length - 1]
    : null;

  let highlightedProposals = proposals.filter((proposal) => {
    if (offerTypes.indexOf("in_cash") > -1 && proposal.payment_type == "IN_CASH") {
      return true;
    }

    if (offerTypes.indexOf("installments") > -1 && proposal.payment_type == "INSTALLMENT") {
      return true;
    }

    if (offerTypes.indexOf("max_installment") > -1 && maxInstallmentProposal &&
      maxInstallmentProposal.id == proposal.id) {
      return true;
    }

    if (offerTypes.indexOf("installment_1") > -1 && installmentProposals.length > 0 &&
      installmentProposals?.[0]?.id == proposal.id) {
      return true;
    }

    if (offerTypes.indexOf("installment_2") > -1 && installmentProposals.length > 1 &&
      installmentProposals[1].id == proposal.id) {
      return true;
    }

    return false;
  });

  let proposalsOrderedByHighlight = highlightedProposals.sort((proposalA, proposalB) => {
    if (Number(proposalA.highlight) > Number(proposalB.highlight)) return 1;
    if (Number(proposalA.highlight) < Number(proposalB.highlight)) return -1;
    return 0;
  });

  return proposalsOrderedByHighlight;
}

/**
 * Retorna o valor do I.O.F. para cada parcela.
 * 
 * @param {Object} deal a negociação.
 * @param {Array} configurations a lista de configurações.
 * @returns o valor do I.O.F. ou 0 se não existir valor.
 */
function getPartialIof(deal, configurations) {
  if (!deal?.payments || !configurations) return 0

  let partialIof = 0;

  let config = configurations.find((config) => {
    return config.key == deal.creditor.config_prefix + "iof_type";
  });

  if (!config) {
    return 0;
  }

  if (config["value"] !== "divided") {
    return 0;
  }

  let payments = deal.payments?.filter((payment) => {
    if (payment.type != "INSTALLMENT") {
      return false;
    }

    if (["PENDING", "PAYED"].indexOf(payment.status) == -1) {
      return false;
    }

    return true;
  });

  let paymentsWithIof = payments.filter((payment) => {
    return payment.additional_desc === "IOF"
      && payment.additional_value;
  });

  if (paymentsWithIof.length && payments.length === paymentsWithIof.length) {
    partialIof = paymentsWithIof?.[0]?.additional_value;
  }

  return partialIof;
}

/**
 * Retorna a primeira proposta à vista da lista.
 * 
 * @param {Array} proposals propostas da negociação.
 * @returns uma proposta à vista.
 */
function getIncashProposal(proposals) {
  if (!proposals) {
    return null;
  }

  return proposals.find((proposal) => {
    return proposal.payment_type == "IN_CASH";
  });
}

/**
 * Retorna uma lista de pagamentos válidos.
 * 
 * @param {Object} deal a negociação.
 * @returns uma lista de pagamentos.
 */
function getValidPayments(deal) {
  if (!deal) {
    return [];
  }

  let payments = deal.payments || [];

  return payments.filter((payment) => {
    if (payment.status == "DISCARTED") {
      return false;
    }

    if (payment.type == "DOWN_PAYMENT" && payment.value == 0) {
      return false;
    }

    return true;
  });
}

/**
 * Retorna uma lista de propostas válida para uma negociação.
 * 
 * @param {Object} deal a negociação.
 * @returns uma lista de propostas.
 */
function getValidProposals(deal) {
  if (!deal) {
    return [];
  }

  if (!deal.proposals) {
    return [];
  }
  deal.proposals?.map((proposal) => {
    if (proposal.highlight == null) {
      proposal.highlight = 999;
    }
  });

  return deal.proposals.filter((proposal) => {
    if (proposal.type != "NORMAL" && proposal.type != "FIXED") {
      return false;
    }

    if (proposal.status != "CREATED" && proposal.status != "SELECTED") {
      return false;
    }

    return true;
  });
}

/**
 * Retorna true se a dívida pode ser negociada.
 * 
 * @param {Object} deal a negociação.
 * @returns true se a dívida pode ser negociada.
 */
function isNegotiable(deal) {
  if (!deal) {
    return false;
  }
  if (["STARTED", "WAITING", "AGREED", "RESOLVED"].indexOf(deal.status) !== -1 && deal.active_proposal) {
    if (deal.status === "AGREED" ||
      deal.status === "RESOLVED" ||
      ((deal.status === "STARTED" || deal.status === "WAITING") &&
        (deal.active_proposal.status === "PROROGUED" || deal.active_proposal.status === "CONFIRMED" ||
          (deal.active_proposal.status === "WAITING" && deal.active_proposal.waiting_cause !== "EXISTING_PROMISE")))) {
      return false;
    }

    if ((deal.status === "STARTED" || deal.status == "WAITING") &&
      deal.active_proposal &&
      deal.active_proposal.status === "WAITING" &&
      deal.active_proposal.waiting_cause === "EXISTING_PROMISE") {
      return false;
    }
  }

  if (deal.status === "ABORTED" &&
    (deal.aborted_cause === "ACTIVE_AGREEMENT" ||
      deal.aborted_cause === "PAYMENT_EXPIRED" ||
      deal.aborted_cause === "DOWN_PAYMENT_EXPIRED" ||
      deal.aborted_cause === "INTERRUPTED")) {
    return false;
  }

  if (deal.status === "ABORTED") {
    return false;
  }

  if (deal.status === "CREATED" ||
    ((deal.status === "STARTED" ||
      deal.status === "WAITING") &&
      !deal.active_proposal)) {
    return true;
  }

  return false;
}

/**
 * Retorna true se o método de pagamento deve ser exibido para as propostas de
 * uma negociação.
 * 
 * @param {Object} deal a negociação.
 * @returns true se o método de pagamento deve ser exibido.
 */
function isPaymentMethodVisible(deal) {
  if (!deal) return false;

  // Não exibe o método de pagamento (débito automático) para o Mercado Livre
  if (deal.creditor_id === CreditorId.MERCADO_LIVRE) return false;

  return true;
}

const maxDownPayment = (deal) => {
  return
}

export default {
  maxDownPayment,
  allowsSimulation,
  allowsSimulationDownPayment,
  allowsSimulationInstallmentsCount,
  askWithoutDownPayment,
  forceWithoutDownPayment,
  calculateDebts,
  createIncashProposal,
  createInstallmentDueDates,
  filterValidDeals,
  getAbandonedProposal,
  getAssignor,
  getHighlightedProposals,
  getPartialIof,
  getIncashProposal,
  getValidPayments,
  getValidProposals,
  isNegotiable,
  isPaymentMethodVisible,
}
