<template>
  <div class="d-flex flex-column align-center">
    <div class="green-card rounded-lg">
      <h3 class="mb-3">Veja como realizar o pagamento:</h3>
      <ol>
        <li>
          Clique em <span class="fw-700">Acessar Mercado Pago</span> para ser
          direcionado.
        </li>
        <li>
          Realize login e clique em <span class="fw-700">Pagar Tudo</span>.
        </li>
        <li>Escolha a data de vencimento.</li>
        <li>
          Escolha a forma de pagamento (PIX, TED, boleto, cartão de crédito ou
          via lotérica).
        </li>
        <li>
          Realize o pagamento, no valor exato da oferta, na data de vencimento
          escolhida.
        </li>
      </ol>
    </div>
    <h4 class="mt-3 pr-5 make-payment">
      Ao realizar o pagamento, daremos baixa no seu débito e seu nome será
      retirado dos órgãos de proteção de crédito.
    </h4>
    <a
      href="https://www.mercadopago.com.br/account-fund#from-section=home"
      title="Acessar Mercado Pago"
      class="qj-bg-primary text-center alert-btn v-btn my-7 mx-a"
      @click="registerMeliAccess()"
    >
      Acessar Mercado Pago
    </a>
    <div class="ml-10px">
      <h4 class="comments">Observações:</h4>
      <ul class="comments-list">
        <li>
          No Mercado Pago, você pode escolher selecionar as parcelas a pagar
          para realizar o pagamento parcial da sua pendência.
        </li>
        <li>
          Caso você possua mais de 1 contrato ativo, selecione o contrato
          negociado com a QuiteJá.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { createTagManagerEvent } from "@/utils";
export default {
  methods: {
    registerMeliAccess() {
      createTagManagerEvent({
        event: "accessedMercadoLivre",
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.green-card {
  padding: 12px 40px 10px 14px;
  background-color: #cafce9;
  ol li {
    margin-bottom: 6px;
    font-size: 17.5px;
    line-height: 24px;
  }
  @media (max-width: 425px) {
    h3 {
      font-weight: 700;
      font-size: 14px;
      line-height: 115.9%;
    }
    ol li {
      font-weight: 400;
      font-size: 14px;
      line-height: 115.9%;
    }
  }
}
.make-payment {
  font-weight: 700;
  line-height: 20px;
  @media (max-width: 425px) {
    font-size: 12px;
    line-height: 132.9%;
  }
}
.alert-btn {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px !important;
  border-radius: 16px !important;
  padding: 10px 24px;
  letter-spacing: initial !important;
  text-transform: initial !important;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 20%) !important;
  @media (max-width: 425px) {
    font-size: 14px !important;
    border-radius: 10px !important;
    line-height: 17px !important;
  }
}
.comments {
  font-weight: 700;
  margin-bottom: 10px;
  @media (max-width: 425px) {
    font-size: 12px;
    line-height: 115.9%;
  }
}
.comments-list {
  margin-left: 10px;
  li {
    margin-bottom: 10px;
  }
  @media (max-width: 425px) {
    font-weight: 400;
    font-size: 12px;
    line-height: 115.9%;
    li {
      margin-bottom: 5px;
    }
  }
}
.ml-10px {
  margin-left: 10px;
}
.fw-700 {
  font-weight: 700;
}
</style>
