import http from "@/plugins/axios";
const VERSION = "v1/";

export default {
  /**
   * Envia o boleto por email para o cliente.
   *
   * @param {Number} id Id do pagamento.
   * @return
   */

  sendBoleto(id) {
    return http.api.post(VERSION + "payment/" + id + "/_send_boleto");
  },

  downloadBoleto(url) {
    return http.generic
      .get(
        url,
        { responseType: "blob" } // !!!
      )
      .then((response) => {
        window.open(URL.createObjectURL(response.data));
      });
  },

  sendPaymentPromise(payload, creditor = "") {
    return http.apiAdapter.post(`https://${creditor}quiteja.com.br/api/v1/payment/promise`, payload);
  },
};
