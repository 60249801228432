import * as types from "./mutation-types";
import serviceOpportunity from "@/services/opportunity";

/* INI SETTERS */
/**
 * Função que armazena o hdoc do cliente no state.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {String} payload Hdoc do cliente.
 */
export const ActionsSetHdoc = ({ commit }, payload) => {
  commit(types.SET_HDOC, payload);
}

/**
 * Função que armazena o objeto do cliente no state.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Object} payload Imformações sobre o cliente.
 */
export const ActionsSetClient = ({ commit }, payload) => {
  commit(types.SET_CLIENT, payload);
}

/**
 * Função que armazena o passcode do cliente no state.
 * 
 * @param {Function} commit Função para armazenar no state.
 * @param {Object} payload Passcode do cliente.
 */
export const ActionsSetTaskId = ({ commit }, payload) => {
  commit(types.SET_TASK_ID, payload);
}
/* FIM SETTERS */

/* INI ACTIONS */
/**
 * Função que cria um cliente.
 * 
 * @param {Function} dispatch Função para executar uma Action.
 * @param {Object} payload Dados sobre o cliente.
 * @return Retorna um objeto contendo as informações sobre o cliente.
 */
export const ActionsCreate = ({ dispatch }, payload) => {
  return serviceOpportunity.create(payload.opportunity, payload.useTermsId).then(res =>
    dispatch("ActionsSetClient", res.data)
  );
}

/**
 * Função para salvar as alterações no cliente.
 * 
 * @param {Function} dispatch Função para executar uma Action. 
 * @param {Object} payload Dados sobre o cliente e o id.
 * @return Retorna um objeto contendo as informações sobre o cliente.
 */
export const ActionsSave = ({ dispatch }, payload) => {
  return serviceOpportunity.save(payload.id, payload.data).then(res =>
    dispatch("ActionsSetClient", res.data)
  );
}


/**
 * Função para salvar as alterações no cliente.
 * 
 * @param {Function} dispatch Função para executar uma Action. 
 * @param {Object} payload Dados sobre o cliente e o id.
 * @return Retorna um objeto contendo as informações sobre o cliente.
 */
 export const ActionsEnrichment = ({ dispatch }, payload) => {
  return serviceOpportunity.enrichment(payload).then(res =>{
    dispatch("ActionsSetClient", res.data);
    return res.data;
  });
}
/* FIM ACTIONS */