<template>
  <div :class="'text-' + config.position">
    <v-snackbar
      v-model="config.snackbar"
      :multi-line="config.multiLine"
      :timeout="config.timeout"
      :vertical="config.vertical"
      :color="config.color"
    >
      <div :style="config.textStyle">
        {{ config.text }}
      </div>
      <template v-slot:action="{ attrs }" v-if="config.btn.show">
        <v-btn
          text
          v-bind="attrs"
          v-if="config.btn.text"
          :color="config.btn.color"
          @click="config.btn.func(config)"
        >
          {{ config.btn.text }}
        </v-btn>
        <v-btn
          icon
          v-else
          v-bind="attrs"
          :color="config.btn.color"
          @click="config.btn.func(config)"
        >
          <v-icon>{{ config.btn.icon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["newConfig"],
  data: () => ({
    config: {
      snackbar: false,
      vertical: false,
      multiLine: false,
      timeout: 5000,
      text: "",
      color: "",
      textStyle: "",
      position: "center",
      btn: {
        show: true,
        text: "",
        color: "white",
        icon: "mdi-close",
        func: function (config) {
          config.snackbar = false;
        },
      },
    },
  }),
  watch: {
    newConfig() {
      if (this.newConfig.snackbar) {
        Object.entries(this.newConfig).forEach(([key, value]) => {
          if (key == "btn") {
            Object.entries(this.newConfig.btn).forEach(([key, value]) => {
              this.config.btn[key] = value;
            });
          } else this.config[key] = value;
        });
      } else this.reset();
    },
    config() {
      if (!this.config.snackbar) this.reset();
    },
  },
  methods: {
    reset() {
      this.config = {
        snackbar: false,
        vertical: false,
        multiLine: false,
        timeout: 5000,
        text: "",
        color: "",
        textStyle: "",
        position: "center",
        btn: {
          show: true,
          text: "",
          color: "white",
          icon: "mdi-close",
          func: function (config) {
            config.snackbar = false;
          },
        },
      };
    },
  },
};
</script>

<style></style>
