<template>
  <div v-if="client">
    <v-fade-transition>
      <div v-show="dialog" class="qj-bg-dialog" @click="closeThis()"></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-card-title class="px-4">
          <v-col cols="12" class="pa-0 text-end">
            <h3 class="qj-h3 my-data-title">Meus dados</h3>
            <v-btn icon class="qj-btn-close-dialog" @click="closeThis()">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
        </v-card-title>
        <v-card-text class="px-4">
          <v-row>
            <v-col cols="12" class="pt-0">
              <p class="qj-p mb-0">
                {{ client.name }}<br /><b
                  >{{ client.type == "PERSONAL" ? "CPF" : "CNPJ" }}
                  {{ client.document | customerDocument }}</b
                >
              </p>
            </v-col>
            <v-col cols="12" class="pt-0 pb-2" v-if="emailObject">
              <p class="qj-p mb-0">E-mail para receber os boletos</p>
              <div class="d-flex align-center justify-space-between">
                <div class="contact-input">
                  <v-icon color="black" size="18" class="mr-2"
                    >far fa-envelope</v-icon
                  >
                  <p class="qj-p">
                    {{ emailObject.value }}
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="pt-0 pb-5">
              <v-fade-transition :hide-on-leave="true">
                <div>
                  <a
                    href="javascript:void(0);"
                    class="qj-link qj-color-secondary"
                    id="add__email__address"
                    @click="openEmailDialog(emailObject)"
                  >
                    {{ informText(emailObject) }}
                    e-mail</a
                  >
                  <br />
                </div>
              </v-fade-transition>
            </v-col>
            <v-col cols="12" class="pt-0 pb-2" v-if="cellphone">
              <p class="qj-p mb-0">Telefone para contato</p>
              <div class="d-flex align-center justify-space-between">
                <div class="contact-input">
                  <v-icon
                    v-if="hasWhatsapp(cellphone)"
                    color="black"
                    size="20"
                    class="mr-2"
                  >
                    fab fa-whatsapp
                  </v-icon>
                  <v-icon v-else color="black" size="20" class="mr-2">
                    fas fa-mobile-alt
                  </v-icon>
                  <p class="qj-p">
                    <b>{{ cellphone.value | cellphone }} </b>
                  </p>
                </div>
              </div>
            </v-col>
            <v-col cols="12" class="d-flex pt-0 pb-0" v-if="inputNumber">
              <v-text-field
                dense
                outlined
                required
                single-line
                :rules="rules.phone"
                :disabled="btnLoading"
                label="Número"
                class="qj-input"
                inputmode="numeric"
                style="width: calc(100vw - 115px)"
                v-mask="'(##) #####-####'"
                v-model="number"
              >
              </v-text-field>
              <v-btn
                fab
                small
                :loading="btnLoading"
                :disabled="btnLoading"
                class="ml-2 qj-btn qj-bg-primary-light"
                @click="actionNumber()"
              >
                <v-icon dark style="color: #000" v-show="!number.length"
                  >mdi-close</v-icon
                >
                <v-icon dark style="color: #000" v-show="number.length"
                  >mdi-check</v-icon
                >
              </v-btn>
            </v-col>
            <v-col
              v-if="cellphone && !hasWhatsapp(cellphone)"
              cols="12"
              class="is-my-whatsapp pt-0 pb-2"
              @click="checkWhatsapp()"
            >
              <v-checkbox class="qj-checkbox pt-0 mt-2" hide-details>
                <template v-slot:label>
                  <span class="qj-p ml-2">Esse é o meu whatsapp</span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" class="pt-0 pb-5">
              <v-fade-transition :hide-on-leave="true">
                <div v-if="!inputNumber">
                  <a
                    href="javascript:void(0);"
                    class="qj-link qj-color-secondary"
                    @click="() => (inputNumber = true)"
                    id="add__phone__number"
                    >{{ informText(cellphone) }} número</a
                  >
                  <br />
                </div>
              </v-fade-transition>
            </v-col>
            <v-col cols="12" v-if="isNotificationSupported" class="pt-0">
              <div
                class="qj-bt qj-bb pt-2 pb-2 d-flex justify-space-between align-center"
                style="line-height: 13px"
              >
                <div class="mr-2">
                  <p class="qj-p mb-0">
                    <b style="font-weight: 600">Receber notificações</b>
                  </p>
                  <samp
                    style="
                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 14px;
                      color: #212121;
                    "
                    >{{ getNotificationText() }}
                  </samp>
                </div>
                <v-switch
                  inset
                  hide-details
                  v-model="notification"
                  class="pa-0 ma-0 qj-switch"
                  :loading="savingDeviceToken"
                  :disabled="savingDeviceToken"
                  @change="(e) => changedSwitch(e)"
                  id="switch__receive__notifications"
                ></v-switch>
              </div>
            </v-col>
            <v-col cols="12" class="indicate-friend">
              <a
                href="javascript:void(0);"
                class="qj-link qj-color-secondary"
                @click="openDialogReferFriend()"
                id="recommended__friend__link"
                >Quero indicar um amigo</a
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogEditEmail
      :email="setEmail"
      :dialog="dialogEditEmail"
      @close="closeModal()"
    />
    <!-- dialogResendEmail  -->
    <DialogResendEmail
      :email="setEmail"
      :dialog="dialogResendEmail"
      @close="() => (dialogResendEmail = false)"
    />
    <!-- dialogDeleteEmail  -->
    <DialogDeleteEmail
      :email="setEmail"
      :dialog="dialogDeleteEmail"
      @close="closeModal()"
    />
    <DialogReferFriend
      :dialog="dialogReferFriend"
      @close="() => (dialogReferFriend = false)"
    />

    <DialogConfirmDeleteNumber
      :phone="objPhone"
      :dialog="dialogConfirmDeleteNumber"
      @send="deleteCellphone()"
      @close="() => (dialogConfirmDeleteNumber = false)"
    />
  </div>
</template>

<script>
import store from "@/store";
import { mapActions, mapMutations, mapGetters } from "vuex";
import util from "@/assets/js/util";
import rules from "@/assets/js/rules";
import serviceCustomer from "@/services/customer";
import serviceContact from "@/services/contact";
import DialogReferFriend from "./DialogReferFriend";
import DialogConfirmDeleteNumber from "./DialogConfirmDeleteNumber";
import DialogEditEmail from "@/views/email/components/EditEmail";
import DialogResendEmail from "@/views/email/components/ResendEmail";
import DialogDeleteEmail from "@/views/email/components/DeleteEmail";
import { createTagManagerEvent } from "@/utils";
export default {
  props: {
    dialog: {
      default: false,
    },
    deal: {
      default: null,
      required: false,
    },
  },
  components: {
    DialogReferFriend,
    DialogConfirmDeleteNumber,
    DialogEditEmail,
    DialogResendEmail,
    DialogDeleteEmail,
  },
  data: () => ({
    rules: rules,
    client: store.getters["negotiation/getMe"],
    btnLoading: false,
    number: "",
    inputNumber: false,
    idPhone: "",
    objPhone: {},
    interval: null,
    invalidStatus: ["INVALID", "DISCARDED", "REJECTED"],
    setEmail: "",
    dialogEditEmail: false,
    dialogDeleteEmail: false,
    dialogResendEmail: false,
    dialogReferFriend: false,
    dialogConfirmDeleteNumber: false,
  }),
  created() {
    if (!this.client.contacts) setTimeout(() => this.getMe(), 100);
    this.verifyPushPermission();
    this.setPhonesLength(this.client.contacts);
  },
  methods: {
    ...mapActions("pushNotification", {
      verifyPushPermission: "verifyPushPermission",
      registerPushNotification: "registerPushNotification",
    }),
    ...mapMutations("pushNotification", {
      setNotification: "setNotification",
    }),
    ...mapActions("negotiation", [
      "ActionsMe",
      "ActionsDeal",
      "ActionsConfiguration",
    ]),
    ...mapActions("pluralCheck", ["setPhonesLength"]),
    openDialogReferFriend() {
      createTagManagerEvent({
        event: "myInfoClickedReferFriend",
      });
      this.dialogReferFriend = true;
    },
    
    refreshContacts() {
      this.ActionsMe().finally(() => {
        this.client = store.getters["negotiation/getMe"];
        this.inputNumber = false;
        this.btnLoading = false;
        this.number = "";
        this.setPhonesLength(this.client.contacts);
      });
    },
    closeThis() {
      createTagManagerEvent({
        event: "myInfoClosed",
      });
      this.$emit("close");
    },
    actionNumber() {
      if (this.number.length == 15) {
        this.btnLoading = true;
        if (this.cellphone && this.cellphone?.id) {
          serviceContact.save({
            id: this.cellphone.id,
            status: "DISCARDED",
          });
        }
        serviceCustomer
          .contacts(this.prepareForm("add"))
          .then(() => {
            this.refreshContacts();
          })
          .catch(() => {
            this.number = "";
            this.btnLoading = false;
          });
      } else if (this.number.length == 0) {
        this.inputNumber = false;
        this.number = "";
      }
    },
    delPhone(phone) {
      createTagManagerEvent({
        event: "myInfoRemovedPhone",
      });
      this.idPhone = phone.id;
      this.objPhone = phone;
      this.dialogConfirmDeleteNumber = true;
      this.setPhonesLength(this.client.contacts);
    },
    deleteCellphone() {
      this.btnLoading = true;
      this.dialogConfirmDeleteNumber = false;
      serviceCustomer
        .update(this.prepareForm("delete", this.idPhone), this.idPhone)
        .then(() => {
          this.refreshContacts();
        })
        .catch(() => (this.btnLoading = false));
    },
    prepareForm(type, data = null) {
      if (type == "add") {
        return { contacts: [util.justNumbers(this.number)], confirm: true };
      }
      if (type == "delete") {
        return {
          id: data,
          status: "DISCARDED",
        };
      }
    },
    getMe() {
      this.client = store.getters["negotiation/getMe"];
      if (!this.client.contacts) setTimeout(() => this.getMe(), 100);
    },
    getNotificationText() {
      if (this.notification) {
        return "Ao desativar esta opção você deixará de receber avisos sobre suas dívidas.";
      }
      return "Ao ativar esta opção, você receberá notificações sobre suas negociações.";
    },
    openEmailDialog(email) {
      this.setEmail = email;
      this.dialogEditEmail = true;
    },
    openDeleteEmailDialog(email) {
      this.setEmail = email;
      this.dialogDeleteEmail = true;
    },
    checkWhatsapp() {
      let payload = {
        id: this.cellphone?.id,
        whatsapp: true,
      };
      serviceContact.save(payload).then(() => {
        this.refreshContacts();
      });
    },
    async closeModal() {
      this.dialogEditEmail = false;
      setTimeout(() => (this.setEmail = ""), 500);
      await this.refreshContacts();
    },
    hasWhatsapp(phone) {
      if (phone?.whatsapp) return true;
      return false;
    },
    informText(item) {
      return item ? "Quero informar outro" : "Informe um";
    },
    async changedSwitch(e) {
      if (e) {
        this.notification = true;
      } else {
        this.notification = false;
      }
      await this.registerPushNotification(this.notification);
      await this.ActionsMe();
      this.verifyPushPermission();
    },
  },
  computed: {
    ...mapGetters("pushNotification", {
      notificationFromStore: "notification",
      savingDeviceToken: "loading",
      isNotificationSupported: "supported",
    }),
    notification: {
      get() {
        return this.notificationFromStore;
      },
      set(notification) {
        this.setNotification(notification);
      },
    },
    emailObject() {
      if (this.client.contacts) {
        let validMails = [];
        let confirmed = this.client.contacts.filter(
          (i) =>
            ["CONFIRMED", "CREATED"].includes(i.status) && i.type == "EMAIL"
        );
        if (confirmed.length) validMails = confirmed;
        return validMails?.[0] ? validMails?.[0] : "";
      }
      return "";
    },
    cellphone() {
      let contact = {};
      if (this.client.contacts) {
        contact = this.client.contacts
          .filter((i) => i.type == "CELLPHONE")
          .filter(
            (i) => !this.invalidStatus.includes(i.status) && i.whatsapp == true
          )
          .slice(-1)?.[0];
      }
      if (!contact) {
        contact = this.client.contacts
          .filter((i) => i.type == "CELLPHONE")
          .filter((i) => !this.invalidStatus.includes(i.status))
          .slice(-1)?.[0];
      }
      return contact;
    },
  },
};
</script>
<style scoped lang="scss">
.qj-btn-close-dialog {
  position: absolute !important;
  top: 9px;
  right: 11px;
}
.qj-p {
  padding-bottom: 3px;
}
.indicate-friend {
  padding-top: 0px !important;
  padding-bottom: 24px !important;
  text-align: center !important;
  margin-top: 3.9rem;
}
.contact-input {
  background: #f6f6f6;
  border-radius: 7px;
  padding: 12px 9px;
  margin-top: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  p {
    width: 100%;
    font-weight: bold;
    margin-bottom: 0 !important;
  }
}
.theme--light.v-btn:focus::before,
.theme--light.v-btn:hover::before {
  opacity: 0;
}
.is-my-whatsapp {
  display: flex;
  align-content: center;
  align-items: center;
}
.my-data-title {
  text-align: center;
}
</style>