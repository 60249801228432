import http from "@/plugins/axios";
const VERSION = "v2/";

export default {
  /**
   * Consulta o status da política de privacidade.
   *
   * @param {Number} id Id da política.
   * @return Status da política.
   */
  consult(id) {
    return http.api.get(VERSION + "privacy_policy/" + id);
  },

  /**
   * Altera o status da política de privacidade.
   *
   * @param {String} id Id da política.
   * @return
   */
  save(id) {
    return http.api.post(VERSION + "privacy_policy/" + id + "/consent");
  },
};