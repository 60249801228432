import http from "@/plugins/axios";
const VERSION = "v1/";
import util from "@/assets/js/util";

export default {
  get(id) {
    return http.api.get("v2/proposals/" + id);
  },
  conditions(id) {
    return http.api.get("v2/proposals/" + id + "/conditions");
  },
  generate(payload) {
    return http.api.post(VERSION + "proposal/_generate", payload);
  },
  generateRetry(payload) {
    return http.api.post("v2/proposals/_generate/retry", payload);
  },
  update(payload, termsAccepted = false) {
    let url = "v2/proposals/" + payload.id;
    if (termsAccepted) url = url + "?termsAccepted=true";
    return http.api.patch(url, payload);
  },
  simulate(payload, dealId = null) {
    const configPrefix = util.getPrefix(dealId || payload.deal_id)
    const simulateUrl = util.getConfig()[configPrefix + 'simulate.url'] ?? null
    if (simulateUrl) {
      return http.apiAdapter.post(simulateUrl, payload)
    }
    return http.api.post(VERSION + "proposal/_simulate?suggest_debit=true", payload);
  },
  registerScreenshot(fileData, acl) {
    return http.api.post(`${VERSION}file?acl=${acl}`, fileData);
  },
  saveScreenshot(payload) {
    return http.api.post(`/v2/evidence`, payload);
  },
}