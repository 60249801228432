<template>
  <div v-if="dialogState.showExactValue">
    <v-row justify="center">
      <v-dialog v-model="dialogState.showExactValue" persistent max-width="310">
        <v-card class="text-center">
          <v-card-title class="justify-center">
            <img src="@/assets/img/svg/aviso.svg" alt="image aguarde" />
          </v-card-title>
          <v-card-text>
            <h3 class="qj-h3 mb-3">Atenção</h3>
            <p class="mb-4 px-7 qj-p">
              Você precisa pagar o valor exato
              <span v-if="hasValueToShow()">
                de <b>R$ {{ dialogState.exactValue | money }}</b>.
              </span>
              <span v-else>do acordo</span>
            </p>
            <v-btn
              block
              class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
              @click="close"
              >Entendi</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  methods: {
    ...mapMutations("alert", ["setExactValueState"]),
    close() {
      this.setExactValueState({
        showExactValue: false,
        exactValue: 0,
      });
    },
    hasValueToShow() {
      if (!this.dialogState.exactValue) return false;
      return true;
    },
  },
    computed: {
      ...mapGetters("alert", {
        dialogState: "getExactValueState",
      }),
    },
};
</script>

<style scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0 19px 20px;
}
.v-dialog__content .v-dialog:not(.v-dialog--fullscreen) {
  max-height: initial !important;
}
</style>
