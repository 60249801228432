<template>
  <div :class="'qj-footer ' + (fixed == true ? 'qj-footer-fixed' : '')">
    <NextView
      v-show="(type == 'next') | (type == 'confirm')"
      :disabled="disabled"
      :loading="loading"
      :label="rules"
      @help="dialog = true"
      @send="send"
    />
    <HelpButton v-show="type == 'help'" @help="dialog = true" />
    <OthersDeals v-show="type == 'other'" @help="dialog = true" />
    <NotMyCPF v-show="type == 'notMyCPF'" />
    <Exit v-show="type == 'exit'" @help="dialog = true" />
    <!-- Safe environment message -->
    <v-col cols="12" class="pt-0 pb-0 text-center">
      <span class="qj-msg-safe">
        <v-icon>mdi-lock-outline</v-icon>
        Você está em um ambiente seguro.
      </span>
    </v-col>
    <!-- Dialog help -->
    <DialogHelp
      :dialog="dialog"
      @close="dialog = false"
      :view="view"
      :hasCustomerServiceBtn="hasCustomerServiceBtn"
    />
  </div>
</template>

<script>
import HelpButton from "@/components/footer/screen/HelpButton";
import NextView from "@/components/footer/screen/NextView";
import OthersDeals from "@/components/footer/screen/OthersDeals";
import Exit from "@/components/footer/screen/Exit";
import NotMyCPF from "@/components/footer/screen/NotMyCPF";
import DialogHelp from "@/components/DialogHelp";

import { mapState, mapActions } from "vuex";

export default {
  props: {
    fixed: Boolean,
    disabled: Boolean,
    alert: {
      type: Boolean,
      default: false,
    },
    forceDownload: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "help",
    },
    view: {
      type: String,
      default: null,
    },
    hasCustomerServiceBtn: {
      type: Boolean,
      default: false,
    },
    btnLoading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogHelp,
    NextView,
    HelpButton,
    OthersDeals,
    Exit,
    NotMyCPF,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    ...mapActions("faq", ["getFaqView"]),
    send() {
      this.$emit("send");
    },
  },
  computed: {
    ...mapState(["authentication"]),
    // footerCondition() {
    //     let footer = this.authentication.footerInfo;
    //     return footer.link || footer.customer;
    // },
    dividerCondition() {
      let footer = this.authentication.footerInfo;
      return footer.link;
    },
    rules() {
      const allRules = {
        next: this.alert ? "Entendi" : "Prosseguir",
        confirm: this.forceDownload ? "Baixar e confirmar" : "Confirmar",
      };
      return allRules[this.type];
    },
    helpCondition() {
      let footer = this.authentication.footerInfo;
      let challengeObject = this.authentication.challengeResultObject;
      let condition =
        footer.link ||
        challengeObject.challenge == "CONTACT_PASSCODE" ||
        challengeObject.challenge == "BIRTHDAY";
      return condition;
    },
  },
};
</script>

<style lang="scss" scoped>
.qj-footer {
  height: 90px;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
}
.qj-footer-fixed {
  border-top: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px -1px 5px rgba(0, 0, 0, 0.09);
  // border-top: 1px solid rgb(229, 229, 229);
  // padding-top: 12px;
  // padding-bottom: 10px;
}

@media (max-width: 600px) {
  .qj-router-view {
    .qj-footer-fixed {
      position: fixed !important;
    }
  }
}

@media (min-width: 600px) {
  .qj-footer {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.action-buttons-container {
  justify-content: center;
  display: flex;
  .proceed-button {
    margin-left: 10%;
    width: 228px;
    height: 40px;
    button {
      display: flex;
      .text {
        display: flex;
        flex: 1;
      }
    }
  }
  .help-button {
    position: inherit;
    width: 40px !important;
    height: 40px !important;
    min-width: unset !important;
    left: 30px;
    color: #00e58d;
    background-color: #cafce9 !important;
    border-radius: 10px;
    margin-left: 20px;
  }
  .margin {
    margin-top: 56px;
  }
}
.help-button {
  width: 40px !important;
  height: 40px !important;
  min-width: unset !important;
  top: 18px;

  color: #00e58d;
  background-color: #cafce9 !important;
  border-radius: 10px;
  margin-left: 20px;
}
.margin {
  margin-top: 56px;
}
</style>
