import Deal from "@/modules/deal";

export default {
  getMe: (state) => {
    return state.me;
  },
  getTask: (state) => {
    return state.task;
  },
  getToken: (state) => {
    return state.token;
  },
  getSyncFinished: (state) => {
    return state.syncFinished;
  },
  getDeals: (state) => {
    return state.deals;
  },
  getDeal: (state) => (id) => {
    return state.deals.find((deal) => deal.id == id);
  },
  getConfiguration: (state) => {
    return state.configuration;
  },
  getConfigurationByName: (state) => (configName) =>{
    return state.configuration[configName];
  },
  getNegotiation: (state) => {
    return state.negotiation;
  },
  getSelectedDeal: (state) => {
    return state.selectedDeal;
  },
  getLoadingFomentationLink: (state) => {
    return state.loadingFomentationLink;
  },
  getLoadingPriorityTask: (state) => {
    return state.loadingPriorityTask;
  },
  getLoadingTasks: (state) => {
    return state.loadingTasks;
  },
  getDebits: (state) => {
    return state.deals.filter((deal) => Deal.isNegotiable(deal));
  },
  getAgreements: (state) => {
    return state.deals.filter((deal) => !Deal.isNegotiable(deal));
  },
  getCreditorsToSync: (state) => {
    return state.creditorsToSync;
  },
  getLoadingDeals: (state) => {
    return state.loadingDeals;
  },
  getContractDetails: (state) => {
    return state.contractInfo;
  },
  getDealByProposalId: (state) => (proposalId) => {
    return state.deals.find(
      (deal) =>
        deal.active_proposal_id == proposalId ||
        deal.active_proposal?.parcele_ja_proposal_id == proposalId ||
        deal.proposals?.some((proposal) => proposal.id == proposalId)
    );
  },
  getValidContacts: (state) => (type, status) => {
    return state.me.contacts.filter(
      (contact) =>
        type.includes(contact.type) && status.includes(contact.status)
    );
  },
};
