<template>
<!-- Btn help -->
<v-col cols="12" class="text-center">
  <div class="qj-h-100">
  <v-btn
    class="rounded-lg qj-btn qj-bg-primary-light qj-p-btn"
    id="help__questions__btn"
    @click="help">
    Ajuda
    <v-icon style="margin-left: 7px">far fa-question-circle
    </v-icon>
  </v-btn>
  </div>
</v-col>
</template>

<script>
import { createTagManagerEvent } from '@/utils'
export default {
  methods: {
    help() {
      createTagManagerEvent({
        event: 'clickedHelp'
      })
      this.$emit("help")
    },
  },
}
</script>

<style>

</style>