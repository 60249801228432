import Vue from 'vue';
import routes from './routes';
import VueRouter from 'vue-router';
import beforeEach from './beforeEach';

import * as Sentry from "@sentry/browser";

Vue.use(VueRouter);

const router = new VueRouter({ routes, mode: 'history' });
router.beforeEach(beforeEach);

router.onError((error) => {
  console.error("Não foi possível carregar a rota.", error);

  if (window.location.pathname == '/erro') {
    // Se já está na página de erro então não faz o redirecionamento
    return;
  }

  let params = [
    "id=" + Sentry.captureException(error),
    // Passa o parâmetro "t" para evitar o cache do index.html
    "t=" + new Date().getTime(),
  ];

  window.location.href = "/erro?" + params.join("&");
});

window.$router = router;

export default router;