import store from "@/store";

const HELP = {
  /**
   * Retorna uma string decodificada do base64.
   *
   * @param {String} encodedData uma string codificada em base64.
   * @returns a string decodificada ou null se não for possível decodificar.
   */
  base64Decode(encodedData) {
    if (!encodedData) {
      return null;
    }

    try {
      return window.atob(encodedData);
    } catch (error) {
      console.error("Não foi possível decodificar " + encodedData, error);
      return null;
    }
  },
  /**
   * Extrai e retorna as informações da conta-corrente.
   *
   * @param {Object} object uma negociação ou uma proposta.
   * @returns as informações da conta-corrente.
   */
  extractBankAccount: (object) => {
    if (!object) {
      return null;
    }

    if (!object.account_number) {
      return null;
    }

    return {
      account_number: object.account_number,
      account_number_check_digit: object.account_number_check_digit,
      branch_code: object.branch_code,
      branch_code_check_digit: object.branch_code_check_digit,
    };
  },
  justNumbers: (value) => {
    if (typeof value !== "string") return value;
    return value.replace(/[^0-9]/g, "");
  },
  isEmpty(value) {
    if (value == null) return true;
    switch (typeof value) {
      case "boolean":
        return false;
      case "function":
        return false;
      case "undefined":
        return true;
      case "number":
        return isFinite(value);
      case "array":
        return value.length === 0;
      case "string":
        return value.length === 0;
      case "object":
        return Object.keys(value).length === 0;
      default:
        throw new Error("Typeof invalid.");
    }
  },
  getDealByProposalId(proposal_id) {
    return store.getters["negotiation/getDeals"].find(
      (deal) =>
        deal.active_proposal_id == proposal_id ||
        deal.active_proposal?.parcele_ja_proposal_id == proposal_id ||
        deal.proposals?.some((proposal) => proposal.id == proposal_id)
    );
  },
  getProposal(id) {
    let deals = store.getters["negotiation/getDeals"];
    let parceleJaProposals = store.getters["parceleJa/proposals"];
    let selectedProposal = null;

    deals.forEach(function (deal) {
      let proposals = deal.proposals || [];
      if (deal.active_proposal?.id == id){
        selectedProposal = deal.active_proposal
        return
      }

      proposals.forEach(function (proposal) {
        if (proposal.id == id) {
          selectedProposal = proposal;
        } else if (proposal.debit_suggestion_id == id) {
          selectedProposal = proposal.debit_suggestion;
        }
      });
    });

    const parceleJaSelectedProposal = parceleJaProposals?.find(proposal => proposal.id == id)
    return selectedProposal || parceleJaSelectedProposal;
  },
  getDeal(deal_id) {
    return store.getters["negotiation/getDeals"].find(
      (deal) => deal.id == deal_id
    );
  },
  getPrefix(deal_id) {
    if (!deal_id) return;
    return this.getDeal(deal_id)?.creditor?.config_prefix ?? "";
  },
  getProfileKey(deal_id) {
    if (!deal_id) return;
    return this.getDeal(deal_id)?.creditor?.profile?.key ?? "";
  },
  getPrefixByProposal(proposal_id) {
    if (!proposal_id) return;

    let deal = HELP.getDealByProposalId(proposal_id);

    if (!deal) {
      return;
    }

    return HELP.getPrefix(deal.id);
  },
  getConfig() {
    return store.getters["negotiation/getConfiguration"];
  },
  getConfigValue(name) {
    return store.getters["negotiation/getConfiguration"][name];
  },
  /**
   * Envia um evento do tipo "velocidade do usuário" para o Google Analytics.
   * https://developers.google.com/analytics/devguides/collection/analyticsjs/user-timings
   *
   * TODO criar tag no GTM após a Ludmilla resolver o problema com os relatórios
   */
  googleAnalyticsTiming(category, variable, value, label) {
    if (!window.ga) {
      return;
    }

    if (typeof window.ga.getAll != "function") {
      return;
    }

    if (!window.ga.getAll()?.[0]) {
      return;
    }

    window.ga.getAll()?.[0]?.send("timing", category, variable, value, label);
  },
  doShowBankSlip(dealId) {
    let prefix = this.getPrefix(dealId);
    let config = Number(this.getConfigValue(`${prefix}show_billet_word`));
    if (config === 0) return false;

    return true;
  },
  doShowWhatsBankSlip(dealId) {
    let prefix = this.getPrefix(dealId);
    let config = Number(this.getConfigValue(`${prefix}show_whats_billet_word`));
    if (config === 0) return false;

    return true;
  },
  stringToColor(str) {
    if (!str) return
    str = str.toLowerCase()
    if (!str.includes('hsl')){
      str = str.split(' ')[0]
    }
    function adjustHue(color, degrees) {
      const regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
      const matches = regex.exec(color);
      if (!matches) return null;

      const [_, r, g, b] = matches;
      const hslColor = `hsl(${(parseInt(r, 16) + degrees) % 360}, ${g}, ${b})`;
      const newColor = hslColor;
      return newColor;
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = "#" + (hash & 0x00ffffff).toString(16).toUpperCase();

    if (this.hasGoodContrastWithWhite(color)) return color;
    const newColor = adjustHue(color, 35);
    return this.stringToColor(newColor);
  },
  getRelativeLuminance(color) {
    const gammaCorrection = (value) => {
      value = value / 255;
      return value <= 0.03928
        ? value / 12.92
        : Math.pow((value + 0.055) / 1.055, 2.4);
    };

    const r = gammaCorrection((color >> 16) & 0xff);
    const g = gammaCorrection((color >> 8) & 0xff);
    const b = gammaCorrection(color & 0xff);

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  },
  hasGoodContrastWithWhite(color) {
    const hexColor = parseInt(color.slice(1), 16);
    const contrastRatio = (this.getRelativeLuminance(hexColor) + 0.05) / 1.05;
    return contrastRatio < 1 / 7;
  },
  calculateDaysAgo(date) {
    const daysAgo = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(today - daysAgo);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  }
};

export default HELP;
