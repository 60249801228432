import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 375,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904
        },
    },
    theme: {
        themes: {
          light: {
            primary: '#00E58D',
            secondary: '#00606A',
            primaryLight: '#CAFCE9'
          },
        },
      },
});
