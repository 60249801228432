<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="310"
    v-if="privacyPolicy"
    @click:outside="checkCheckbox"
  >
    <v-card class="text-center">
      <v-card-title class="justify-center">
        <img src="@/assets/img/svg/terms.svg" alt="image aviso" />
      </v-card-title>
      <v-card-text>
        <h3 class="qj-h3">Termos de Uso e Política de Privacidade</h3>
        <p class="qj-p mb-6 mt-3">
          O Termos de Uso e Política de Privacidade foram atualizados. Para
          continuar, é necessário ler e concordar com os documentos.
        </p>
        <div
          style="padding: 8px; background: #f6f6f6; border-radius: 7px"
          class="mb-4 checkbox"
        >
          <v-checkbox
            id="accept__policies__link"
            hide-details
            class="qj-checkbox pt-0 mt-0"
            v-model="checkbox"
          >
            <template v-slot:label>
              <div class="qj-checkbox-label">
                Declaro que li e concordo com os
                <a
                  href="javascript:void(0);"
                  class="qj-link qj-color-secondary"
                  @click="
                    dialogTermsOfUse = true;
                    checkbox = !checkbox;
                  "
                  >Termos de Uso</a
                >
                e
                <a
                  href="javascript:void(0);"
                  class="qj-link qj-color-secondary"
                  @click="
                    dialogPolicy = true;
                    checkbox = !checkbox;
                  "
                  >Política de Privacidade.</a
                >
              </div>
            </template>
          </v-checkbox>
        </div>
        <div @click="checkCheckbox">
          <v-btn
            block
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            :disabled="!checkbox"
            @click="next"
            id="btn__accept__policies"
          >
            Entendi
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <DialogTermsOfUse
      :footer="false"
      :dialogTermsOfUse="dialogTermsOfUse"
      :url="privacyPolicy.terms_of_use_url"
      @close="dialogTermsOfUse = false"
    />
    <!-- Dialog política de privacidade -->
    <DialogPolicy
      :footer="false"
      :dialogPolicy="dialogPolicy"
      :url="privacyPolicy.privacy_policy_url"
      @close="dialogPolicy = false"
    />
  </v-dialog>
  <!-- Dialog Contract Clauses -->
</template>

<script>
import store from "@/store";
import util from "@/assets/js/util";
import DialogPolicy from "@/components/DialogPolicy";
import DialogTermsOfUse from "@/components/DialogTermsOfUse";
import servicePrivacyPolicy from "@/services/privacy_policy";

export default {
  props: ["privacyPolicy"],
  components: {
    DialogPolicy,
    DialogTermsOfUse,
  },
  data: () => ({
    id: store.getters["negotiation/getMe"]?.id,
    dialog: false,
    checkbox: false,
    dialogPolicy: false,
    dialogTermsOfUse: false,
    url: "",
  }),
  watch: {
    privacyPolicy() {
      if (!this.privacyPolicy._consented)
        this.dialog = !this.privacyPolicy._consented;
    },
    checkbox(newValue) {
      if (newValue) {
        document.querySelector(".checkbox").classList.remove("checkbox--error");
      }
    },
    dialog() {
      let interval = setInterval(() => {
        let html = document.querySelector("html");
        if (!html?.classList?.contains("overflow-y-hidden") && this.dialog) {
          html.classList.add("overflow-y-hidden");
        }
        if (!this.dialog) {
          clearInterval(interval);
        }
      }, 200);
    },
  },
  methods: {
    next() {
      if (this.id) {
        this.createTagManagerEvent({
          event: "agreementsAccepted",
          customerId: this.id,
        });
      }

      if (window.location.pathname == "/cadastro") return (this.dialog = false);

      servicePrivacyPolicy
        .save(util.getConfig()["current_privacy_policy"])
        .then(() => (this.dialog = false))
        .catch((error) => {
          console.error(
            "Não foi possível salvar o consentimento da política de privacidade.",
            error
          );

          this.dialog = false;
        });
    },
    checkCheckbox() {
      let checkboxContainer = document.querySelector(".checkbox");
      setTimeout(() => {
        if (!this.checkbox) {
          checkboxContainer.classList.add("checkbox--error");
        }
      }, 100);
    },
  },
};
</script>

<style lang="scss">
.checkbox {
  &--error {
    background: #fbe9e7 !important;
    .qj-link {
      color: black !important;
    }
    .v-icon {
      color: #ff7043 !important;
    }
  }
}
</style>
