<template>
  <v-app ref="app" :class="{ 'optin-application': isOptinView }">
    <v-main class="d-flex">
      <div class="app-info" v-if="showVersion">
        <router-link :to="{ name: 'Info' }" replace class="version">
          <span v-bind:class="{ 'show-version': showVersion }">
            v{{ version }}
          </span>
        </router-link>

        <div v-if="user?.id && showLogout">
          {{ user.name }}
          | {{ user.document | customerDocument }} (<a
            id="logout__link"
            @click="logoutFromApp"
            >Sair</a
          >)
        </div>
      </div>
      <v-container
        class="grow d-flex flex-column px-4"
        :class="[$route.meta.noPadding ? '' : 'qj-container-main']"
      >
        <HeaderComponent
          :user="user"
          :step="step"
          :totalStep="totalStep"
          :showStep="this.$route.meta.showStep"
          :showMenu="this.$route.meta.showMenu"
          :showOwnLogo="this.$route.meta.canShowLogo"
          :showBackButton="showBackButton()"
          :backPage="back"
          :openMyData="openMyData"
          :profileLogo="profileLogo"
        />
        <div v-show="loading" class="loading-container">
          <v-progress-circular
            indeterminate
            class="qj-color-primary"
            :size="50"
          ></v-progress-circular>
        </div>
        <router-view
          @step="step = $event"
          @loading="loading = $event"
          @totalStep="totalStep = $event"
          @policy="privacyPolicy = $event"
          @snackbar="configSnackBar = $event"
          @debitsLoaded="debitsLoaded = $event"
          v-show="!loading"
          class="router-view grow qj-router-view"
        />
        <!--@loadFaq="loadFaq" /-->
        <DialogPrivacyPolicy :privacyPolicy="privacyPolicy" />
        <DialogWhatsappPermission ref="DialogWhatsappPermission" />
        <DialogPushPermission ref="DialogPushPermission" />
        <DialogAlert />
        <DialogExactValue />
        <DialogMyData
          :deal="getSelectedDeal"
          :dialog="dialogMyData"
          @close="dialogMyData = false"
        />
      </v-container>
      <!-- Dialog da política de privacidade -->

      <!-- SnackBar -->
      <SnackBar :newConfig="configSnackBar" />
    </v-main>
  </v-app>
</template>

<script>
import * as Sentry from "@sentry/browser";
import { LoadConfigurationsError } from "@/errors";

import store from "@/store";
import { version } from "@/../package.json";
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
import SnackBar from "@/components/SnackBar";
import environment from "@/assets/js/environment";
import DialogPrivacyPolicy from "@/components/DialogPrivacyPolicy";
import DialogAlert from "@/components/DialogAlert";
import DialogWhatsappPermission from "@/components/DialogWhatsappPermission";
import DialogPushPermission from "@/components/DialogPushPermission";
import DialogExactValue from "@/components/DialogExactValue";
import util from "@/assets/js/util";
import DialogMyData from "@/views/home/components/DialogMyData";
import HeaderComponent from "@/components/header/HeaderComponent";
import { createTagManagerEvent } from "@/utils";

export default {
  components: {
    SnackBar,
    DialogPrivacyPolicy,
    DialogAlert,
    DialogWhatsappPermission,
    DialogPushPermission,
    DialogExactValue,
    DialogMyData,
    HeaderComponent,
  },
  data: () => ({
    debitsLoaded: false,
    step: 1,
    totalStep: 4,
    showLogout: environment.showLogout,
    showVersion: environment.showVersion,
    version: version,
    // helpOptions: [],
    // dialogHelp: false,
    loading: false,
    privacyPolicy: null,
    configSnackBar: {},
    dialogMyData: false,
  }),
  watch: {
    async creditorsToSync(newValue) {
      if (newValue.length == 0) {
        // sleep colocado para resolver bug nos sinais do websocket QJ-9256
        await this.$sleep(500);
        this.setLoadingDeals(false);
      }
    },
    $route: "getFaq",
  },
  created() {
    console.info("Versao teste pix itau 11:00");
    window.addEventListener(
      "popstate",
      function (e) {
        if (window.hj) window.hj("event", "Cliente voltou - back button");
      },
      false
    );
    document.addEventListener("visibilitychange", (e) => {
      const visibilityState = e.target.hidden ? "Minimizada" : "Maximizada";
      if (window.hj) window.hj("event", `Janela ${visibilityState}`);
    });
    // Carrega a lista com todas as configurações
    this.ActionsConfiguration().catch((error) => {
      console.error("Não foi possível carregar as configurações.", error);

      let eventId = Sentry.captureException(
        new LoadConfigurationsError({
          axiosError: error,
        })
      );
      this.$router
        .push({ name: "Error", query: { id: eventId } })
        .catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      return;
    });

    // Carrega todos os alertas da aplicação
    this.loadAlerts().catch((error) => {
      console.error("Não foi possível carregar os alertas.", error);
    });

    // Identifica o ambiente no <body> para que seja possível fazer
    // customizações no layout conforme o ambiente
    if (document.body.classList) {
      document.body.classList.add("env-" + environment.name);
    }
  },
  methods: {
    ...mapActions("alert", {
      loadAlerts: "search",
    }),
    ...mapActions("faq", ["getFaqView"]),
    ...mapActions("authentication", ["logout"]),
    ...mapActions("negotiation", ["ActionsConfiguration"]),
    ...mapMutations("negotiation", {
      setLoadingDeals: "setLoadingDeals",
    }),
    getFaq() {
      let alreadyPassed = window.pageHistory.pages.some(
        (page) => page.path == this.$route?.path
      );
      if (!alreadyPassed) {
        this.getFaqView();
      }
      document.documentElement.scrollTop = 0;
    },
    showBackButton() {
      let isInProposalPage = this.$route.path == "/proposta";
      let hasMoreThanOneDeal = this.getDeals.length != 1;
      let hasOneDealAndNotInStepOne = !hasMoreThanOneDeal && this.step != 1;
      let hasMoreThanOneDealAndInProposalPage =
        isInProposalPage && hasMoreThanOneDeal;
      let routeMeta = this.$route.meta;
      if (
        (hasMoreThanOneDeal ||
          hasOneDealAndNotInStepOne ||
          hasMoreThanOneDealAndInProposalPage) &&
        (routeMeta.showMenu || routeMeta.showStep)
      ) {
        return true;
      }
      return false;
    },
    back() {
      this.loading = false;
      let path = this.$route.path;
      if (path.includes("acordo") || path.includes("proposta")) {
        this.$router.push({ path: "/dividas" }).catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      } else if (window.pageHistory.pages.length == 1) {
        this.$router
          .push({ path: window.pageHistory.pages.path })
          .catch((failure) => {
            this.skipIfNavigationFailureIsGeneric(failure);
          });
      } else if (path === "/negociacao/divida") {
        this.$router.push({ path: "/dividas" }).catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      } else {
        let pastPage =
          window.pageHistory.pages[window.pageHistory.pages.length - 1];

        window.pageHistory.pages.pop();
        window.pageHistory.back = true;

        if (this.step > 1) this.step--;

        this.$router.push(pastPage).catch((failure) => {
          this.skipIfNavigationFailureIsGeneric(failure);
        });
      }
    },
    async logoutFromApp() {
      await this.logout();

      window.location.href = window.location.origin;
    },
    openMyData() {
      this.dialogMyData = true;
      createTagManagerEvent({
        event: "dealClickedMyInfo",
      });
    },
  },
  computed: {
    ...mapState(["authentication"]),
    ...mapGetters("negotiation", ["getDeals", "getDeal", "getSelectedDeal"]),
    ...mapGetters("negotiation", {
      syncFinished: "getSyncFinished",
      creditorsToSync: "getCreditorsToSync",
    }),
    ...mapGetters("pluralCheck", {
      hasManyDeals: "hasManyDeals",
      hasManyAgreements: "hasManyAgreements",
    }),
    user() {
      return store.getters["negotiation/getMe"];
    },
    deal() {
      let dealId = util.base64Decode(this.$route.query.id);
      return this.getDeal(dealId);
    },
    profileLogo() {
      let deal = this.getSelectedDeal;
      if (!deal) {
        let proposalId = util.base64Decode(this.$route.query.id);
        deal = util.getDealByProposalId(proposalId);
      }

      return (
        deal?.creditor_profile?.logo_url || deal?.creditor?.profile?.logo_url
      );
    },
    isOptinView() {
      return this.$route.query?.hash && this.$route.meta?.optin;
    },
  },
};
</script>

<style lang="scss">
body.env-staging {
  .v-main::before {
    background-color: rgba(255, 255, 255, 0.5);
    content: "Homologação";
    color: #ff0000;
    font-size: 12px;
    font-weight: 600;
    padding: 8px 0 0 8px;
    position: absolute;
    text-transform: uppercase;
  }
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}
.v-main__wrap {
  display: flex;
  flex-direction: column;
}
</style>

<style scoped lang="scss">
.qj-router-view {
  display: flex;
  flex-direction: column;
}
.background-logo {
  text-align: center;
  img {
    height: 100%;
    width: 125px;
  }
}

@media (min-width: 360px) {
  .v-main {
    background-size: 85%;
  }
  .background-logo {
    background-size: cover;
    img {
      height: 100%;
      width: 40%;
    }
  }
}

.qj-link {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
}

.qj-link .v-icon {
  font-size: 19px !important;
  padding-right: 10px !important;
  vertical-align: text-top !important;
}

.qj-action-button {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.sticky {
  position: sticky;
}

.fixed {
  position: fixed;
}

.authentication-footer {
  bottom: 0;
  position: fixed;
  width: 100%;
}

.footer {
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: white;
  text-align: center;
  width: 100%;
  div {
    padding-bottom: 0;
  }

  .help-button {
    margin-bottom: 0;
    button {
      width: 7em !important;
      height: 3em !important;
      color: #00e58d !important;
      background-color: #cafce9 !important;
      font-weight: 800;
      border-radius: 0.5em;
      img {
        margin-left: 0.5em;
      }
    }
  }

  .safe-message {
    padding-bottom: 10px;
  }
}

.qj-step {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}

.app-info {
  color: #777;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 8px 0 0;
  right: 0;
  text-align: right;

  a {
    text-decoration: none;
  }

  .version {
    span {
      visibility: hidden;
    }

    span.show-version {
      color: #777;
      visibility: visible;
    }
  }
}

.loading-container {
  text-align: center;
  padding-top: 250px;
}

.confirm-screen {
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
  img {
    width: 250px;
    margin-top: 14px;
    @media (max-width: 375px) {
      width: 70%;
    }
  }
}

@media (min-width: 600px) {
  .v-application {
    background-attachment: fixed !important;
    background-color: #c9f9e9 !important;
    background-image: url("./assets/img/background/bg-tree.svg") !important;
    background-position: center calc(100% - 100px) !important;
    background-repeat: repeat-x !important;
    background-size: 1600px;
  }

  .optin-application {
    background-image: url("./assets/img/background/bg-furniture.webp") !important;
  }

  .v-main {
    background-color: #fff;
    box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    margin: 24px auto 24px;
    width: 520px;
    max-width: 520px;
  }

  .background-logo {
    border-top-left-radius: 10px;
  }

  .qj-container-main {
    padding: 16px 16px 0px 16px !important;
  }

  .header-steps {
    background-color: #fff;
    left: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 1px 16px;
    position: absolute;
    right: 0;
    z-index: 1;
  }
}
</style>
