import ServiceCreditor from '@/services/creditor'

const state = {
  creditorProfiles: null
}

const mutations = {
  setCreditorProfiles(state, creditorProfiles){
    state.creditorProfiles = creditorProfiles.objects;
  }
}

const actions = {
  async fetchCreditorProfiles({commit}){
    const {data} = await ServiceCreditor.creditorProfile()
    

    commit('setCreditorProfiles', data || null)
  }
};

const getters = {
  getCreditorProfiles(state) { return state.creditorProfiles || null },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};