import ServiceParceleJa from "@/services/parceleJa";

const state = {
  proposals: null,
  prePayment: null,
  remainingPinAttempts: 3,
  prePaymentError: {
    state: false
  },
};

const mutations = {
  setProposals(state, proposals) {
    state.proposals = proposals;
  },
  setPrePayment(state, prePayment) {
    state.prePayment = prePayment;
  },
  setPin(state, pin) {
    state.pin = pin;
  },
  setRemainingPinAttempts(state, remainingPinAttempts) {
    state.remainingPinAttempts = remainingPinAttempts;
  },
  setPreparePaymentError(state, error) {
    state.prePaymentError.message = error
    state.prePaymentError.state = true;
    setTimeout(() => {
      state.prePaymentError.state = false;
    }, 3500);
  }
};

const actions = {
  async getProposals({ commit }, parentProposalId) {
    try {
      const { data, error } = await ServiceParceleJa.getProposals(
        parentProposalId
      );
      commit("setProposals", data.proposals || null);
    } catch (e) {
      console.error(e);
    }
  },
  async sendPrePayment({ commit }, payload) {
    try {
      const { data } = await ServiceParceleJa.sendPrePayment(payload);
      commit("setPrePayment", data);
      commit(
        "negotiation/setPrePaymentOnDeal",
        { ...data, proposal_id: payload.proposal_id },
        { root: true }
      );
    } catch (e) {
      commit('setPreparePaymentError', e.response?.data?.errors[0]?.title)
      if (e.response?.status !== 404) {
        throw new Error(e);
      }
    }
  },
  async sendPin({ commit }, payload) {
    try {
      const result = await ServiceParceleJa.sendPin(payload);
      commit(
        "negotiation/setPrePaymentOnDeal",
        { ...result, proposal_id: payload.proposal_id },
        { root: true }
      );
    } catch (e) {
      if (e.response.status === 400) {
        const { pin_remaining_attempts } = e.response.data;
        commit(
          "negotiation/setPrePaymentOnDeal",
          { pin_remaining_attempts, proposal_id: payload.proposal_id },
          { root: true }
        );
      }
      throw new Error(e);
    }
  },
};

const getters = {
  prePaymentError: (state) => state.prePaymentError,
  proposals(state) {
    return state.proposals || null;
  },
  remainingPinAttempts: (state) => state.remainingPinAttempts,
  getPrePayment: (state) => state.prePayment
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
