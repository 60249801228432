const state = {
  broadcastCount: 0,

}
const mutations = {

}
const actions = {
  async joinUserRoom({ rootGetters }) {
    const userData = rootGetters["authentication/getUserInfo"]
    this._vm.$socket?.client?.emit('join', {room: userData.id});
  },
  async SOCKET_joined(store, payload){
    console.debug(`WS: ${payload}`)
  },
  async SOCKET_broadcast({state}, payload){
    console.debug(`WS: ${payload}`)
    state.broadcastCount += 1
  },
  async SOCKET_disconnect({state, rootGetters}, payload){
    const userData = rootGetters["authentication/getUserInfo"]
    if (userData.id){
      this._vm.$socket?.client?.emit('join', {room: userData.id});
    }
  },
  async SOCKET_dealSynced({commit}, deal){
    commit('negotiation/pushDeal', deal, {root: true})
  },
  async SOCKET_creditorSynced({commit}, deal){
    commit('negotiation/creditorSynced', deal, {root: true})
  }
}
const getters = {

}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};