import store from "@/store";

export default {
  setDialogCreditorsOff(state, value) {
    state.dialogCreditorsOff = value;
  },
  setLoadingPriorityTask(state, boolean) {
    state.loadingPriorityTask = boolean;
  },
  setLoadingTasks(state, boolean) {
    state.loadingTasks = boolean;
  },
  async setNonSyncedDeals(state, deals) {
    const notSynced = state.allDeals.filter((deal) => {
      let alreadySynced = state.deals.find((d) => d.id == deal.id);
      if (alreadySynced) return false;
      return true;
    });
    notSynced.forEach((deal) => {
      const prePayment = state.prePayments.find((prePayment) => {
        return (
          prePayment.proposal_id ==
            deal.active_proposal?.parcele_ja_proposal_id ||
          deal.proposals?.some(
            (proposal) => proposal.id == prePayment.proposal_id
          )
        );
      });
      state.deals.push({ ...deal, prePayment });
    });
  },
  setPrePaymentOnDeal(state, payload) {
    state.selectedDeal.prePayment = {
      ...state.selectedDeal.prePayment,
      ...payload,
    };
    const index = state.deals.findIndex(
      (deal) => deal.id == state.selectedDeal.id
    );
    state.deals[index].prePayment = {
      ...state.selectedDeal.prePayment,
      ...payload,
    };
  },
  setSyncFinished(state, payload) {
    state.syncFinished = payload;
  },
  setAllDeals(state, deals) {
    state.allDeals = deals.deals;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setTask(state, payload) {
    state.task = payload;
  },
  setMe(state, payload) {
    state.me = payload;
  },
  setDeals(state, payload) {
    state.deals = payload;
  },
  setNegotiation(state, payload) {
    Object.assign(state.negotiation, payload);
  },
  setConfiguration(state, payload) {
    state.configuration = payload;
  },
  setSelectedDeal(state, payload) {
    state.selectedDeal = payload;
  },
  setLoadingFomentationLink(state, payload) {
    let negotiation = store.getters["negotiation/getNegotiation"];
    Object.entries(payload).forEach(
      ([key, value]) => (negotiation[key] = value)
    );
    state.loadingFomentationLink = negotiation;
  },
  setNewProposal(state, proposal) {
    const index = state.deals.findIndex((deal) => deal.id == proposal.deal_id);
    state.deals[index].proposals.push(proposal);
  },
  setSelectedDealByProposalId(state, proposalId) {
    state.selectedDeal = state.deals.find((deal) => {
      return deal.proposals.some((proposal) => proposal.id == proposalId);
    });
  },
  pushDeals(state, deals) {
    deals.forEach((deal) => {
      let dealAlreadyExist = state.deals.some(
        (dealInStore) => dealInStore.id == deal.id
      );
      if (!dealAlreadyExist) {
        const prePayment = state.prePayments?.find((prePayment) => {
          return (
            prePayment.proposal_id ==
              deal.active_proposal?.parcele_ja_proposal_id ||
            deal.proposals?.some(
              (proposal) => proposal.id == prePayment.proposal_id
            )
          );
        });

        state.deals.push({ ...deal, prePayment });
      }
    });
  },
  setPrePayments(state, prepayments) {
    state.prePayments = prepayments;
    state.deals.forEach((deal) => {
      if (deal.active_proposal?.parcele_ja_proposal_id) {
        deal.prePayment = state.prePayments.find(
          (prePayment) =>
            prePayment.proposal_id ==
            deal.active_proposal.parcele_ja_proposal_id
        );
      }
    });
  },
  pushDeal(state, deal) {
    const dealAlreadyExist = state.deals.some((item) => item.id == deal.id);
    if (!dealAlreadyExist) {
      state.deals.push(deal);
    }
  },
  setContractInfo(state, payload) {
    state.contractInfo = payload;
  },
  updateDeal(state, deal) {
    const index = state.deals.findIndex((DEAL) => {
      return DEAL.id == deal.id;
    });
    state.deals[index] = {
      ...deal,
      prePayment: state.deals?.[index].prePayment,
    };
  },
  creditorSynced(state, creditor_id) {
    const indexOfCreditor = state.creditorsToSync?.findIndex(
      (creditor) => creditor.id == creditor_id
    );
    if (indexOfCreditor >= 0) state.creditorsToSync.splice(indexOfCreditor, 1);
  },
  setCreditorsToSync(state, syncs) {
    state.creditorsToSync = syncs;
  },
  forceSyncToFinish(state) {
    if (state.loadingDeals) {
      state.creditorsToSync = [];
      state.loadingDeals = false;
    }
  },
  setLoadingDeals(state, loading) {
    state.loadingDeals = loading;
  },
};
