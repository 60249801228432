import http from "@/plugins/axios";
const VERSION = "v1/";
import util from "@/assets/js/util";

export default {
  syncs: {
    get(){
      return http.negotiationApi.get('v1/deal/syncs')
    },
  },
  deal(id) {
    let params = {
      bring_fomentation: true,
      outdate_proposals: true,
      t: new Date().getTime(),
      include: 'tags_v2',
    };

    let url = "deal/" + id;

    return http.api.get(VERSION + url, { params });
  },

  policy(id) {
    return http.api.get(
      VERSION + "deal/" + id + "/_policy?t=" + new Date().getTime()
    );
  },

  /**
   * Endpoint para cancelar uma negociação.
   * 
   * @param {String} id Id da dívida 
   * @return
   */
  cancel(id) {
    return http.api.post(VERSION + "deal/" + id + "/_cancel");
  },


  /**
   * Endpoint para requisitar o contrato..
   * 
   * @param {String} id Id da dívida 
   * @return
   */

  contract(id) {
    return http.api.get(VERSION + "deal/" + id + "/_contract");
  },
  simulationParams(dealId, payload) {
    const configPrefix = util.getPrefix(dealId);
    const simulationParamsUrl =
      util.getConfig()[configPrefix + "simulation_params_url"] ?? null;
    if (simulationParamsUrl) {
      return http.apiAdapter.post(simulationParamsUrl, { ...payload, deal_id: dealId })
    }
    return http.api.post("v2/deal/" + dealId + "/_simulation_params?", {simulate: true});
  },

  /*
  * Retorna mais informações do contrato quando solicitado pelo credor.
  */
  getContractDetails({ contract_url, deal_id }) {
    return http.api.post(contract_url, { deal_id: deal_id });
  }
}
