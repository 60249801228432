<template>
  <div>
    <v-fade-transition>
      <div
        v-show="dialogCallAttendant"
        class="qj-bg-dialog"
        @click="closeCallAttendant"
      ></div>
    </v-fade-transition>
    <v-dialog v-model="dialogCallAttendant" scrollable persistent width="30em">
      <v-card>
        <v-card-title>
          <v-col cols="12 pt-0" style="position: relative">
            <h2 class="qj-h3 text-center">Precisa de ajuda?</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="closeCallAttendant">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="pt-1 text-center">
            <div class="qj-p ma-auto">
              <img src="@/assets/img/svg/atendente.svg" alt width="90" />
            </div>
          </v-col>
        </v-card-title>
        <v-card-text>
          <div class="qj-p ma-auto content-container">
            <p>Ficou com alguma dúvida?</p>
            <v-btn
              class="rounded-lg qj-btn qj-bg-secondary-light"
              href="https://quiteja.com.br/contato"
              target="_blank"
            >
              Entrar em contato
            </v-btn>
            <div class="margin-top">Ou fale com um dos nossos atendentes.</div>
            <div>Geralmente respondemos em alguns minutos.</div>
            <div class="margin-top">Horário comercial</div>
            <div>
              <strong>Das 08:00 às 18:00</strong>
            </div>
            <div>Somente em dias úteis.</div>
            <v-btn
              class="rounded-lg qj-btn qj-bg-primary margin-top"
              @click="goToWhatsapp()"
              ><v-icon class="mr-2">fab fa-whatsapp</v-icon>WhatsApp</v-btn
            >
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import serviceCustomer from "@/services/customer";

export default {
  props: ["dialogCallAttendant"],
  data: () => ({}),
  methods: {
    closeCallAttendant() {
      this.$emit("closeCallAttendant");
    },
    async goToWhatsapp() {
      let newWindow = window.open();
      const { data, error } = await serviceCustomer.whatsapp();
      if (error) {
        if (window.hj) window.hj('event', 'redirect_error')
        return
      }
      if (window.hj) window.hj('event', 'redirect_success')
      const link = data.url
      newWindow.location = link
    },
  },
};
</script>

<style lang="scss" scoped>
.margin-top {
  margin-top: 2em;
}

.qj-bg-secondary-light.v-btn--contained.theme--light {
  width: 12em;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.qj-bg-primary.v-btn--contained.theme--light {
  width: 18em;
}
</style>
