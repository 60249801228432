const host = window.location.hostname;
const appMode = process.env.VUE_APP_MODE
const configs = {
  localhost: {
    apiUrl: "http://localhost:5000/",
    authHostsPattern: "quiteja.com.br",
    parceleJaUrl: "http://localhost:5501/",
    negotiationApi: "http://localhost:5031/",
    authTokenCookieDomain: "localhost",
    dealAppWebV1Url: "https://negociar.quiteja.com.br/#",
    landingUrl: "http://localhost:5002",
    name: "development",
    showLogout: true,
    showVersion: true,
    tasksUrl: "http://localhost:5300",
    validatorUrl: "http://localhost:5006",
    useOtpSms: false,
    queryTaskMaxAttempts: 1,
    showParceleJaForQuitejaProposals: true,
  },
  "negocie.quiteja.staging.gorillascode.com": {
    apiUrl: "https://api.quiteja.staging.gorillascode.com/",
    authHostsPattern: "quiteja.staging.gorillascode.com",
    parceleJaUrl: "https://parcele-ja.quiteja.staging.gorillascode.com/api/",
    negotiationApi: "https://api.negocie.staging.gorillascode.com/",
    authTokenCookieDomain: ".quiteja.staging.gorillascode.com",
    dealAppWebV1Url: "http://negociar.quiteja.staging.gorillascode.com/#",
    landingUrl: "https://quiteja.staging.gorillascode.com",
    name: "staging",
    showLogout: true,
    showVersion: true,
    tasksUrl: "https://tasks.quiteja.staging.gorillascode.com",
    validatorUrl: "https://validator.staging.gorillascode.com",
    useOtpSms: true,
    queryTaskMaxAttempts: 10,
    showParceleJaForQuitejaProposals: true,
  },
  "negocie.quiteja.com.br": {
    apiUrl: "https://api.quiteja.com.br/",
    authHostsPattern: "quiteja.com.br",
    parceleJaUrl: "https://parcele-ja.quiteja.com.br/api/",
    negotiationApi: "https://api.negocie.quiteja.com.br/",
    authTokenCookieDomain: ".quiteja.com.br",
    dealAppWebV1Url: "https://negociar.quiteja.com.br/#",
    landingUrl: "https://quiteja.com.br",
    name: "production",
    showLogout: false,
    showVersion: false,
    tasksUrl: "https://tasks.quiteja.com.br",
    validatorUrl: "https://validator.quiteja.com.br/",
    useOtpSms: true,
    queryTaskMaxAttempts: 10,
    showParceleJaForQuitejaProposals: false,
  },
};
if (!configs[host]) {
  configs[host] = {
    apiUrl: "https://api.quiteja.staging.gorillascode.com/",
    authHostsPattern: "quiteja.staging.gorillascode.com",
    parceleJaUrl: "https://parcele-ja.quiteja.staging.gorillascode.com/api/",
    negotiationApi: "https://api.negocie.staging.gorillascode.com/",
    authTokenCookieDomain: ".quiteja.staging.gorillascode.com",
    dealAppWebV1Url: "http://negociar.quiteja.staging.gorillascode.com/#",
    landingUrl: "https://quiteja.staging.gorillascode.com",
    name: "staging",
    showLogout: true,
    showVersion: true,
    tasksUrl: "https://tasks.quiteja.staging.gorillascode.com",
    validatorUrl: "https://validator.staging.gorillascode.com",
    useOtpSms: true,
    queryTaskMaxAttempts: 10,
    showParceleJaForQuitejaProposals: true,
  };
}

if (appMode == 'production' && host == 'localhost') {
  configs[host] = {
    apiUrl: "https://api.quiteja.com.br/",
    authHostsPattern: "quiteja.com.br",
    parceleJaUrl: "https://parcele-ja.quiteja.com.br/api/",
    negotiationApi: "https://api.negocie.quiteja.com.br/",
    authTokenCookieDomain: "localhost",
    dealAppWebV1Url: "https://negociar.quiteja.com.br/#",
    landingUrl: "http://localhost:5002",
    name: "development",
    showLogout: true,
    showVersion: true,
    tasksUrl: "https://tasks.quiteja.com.br",
    validatorUrl: "https://validator.gorillascode.com/",
    useOtpSms: false,
    queryTaskMaxAttempts: 1,
    showParceleJaForQuitejaProposals: true,
  }
}

if (appMode == 'staging' && host == 'localhost') {
  configs[host] = {
    apiUrl: "https://api.quiteja.staging.gorillascode.com/",
    authHostsPattern: "quiteja.staging.gorillascode.com",
    parceleJaUrl: "https://parcele-ja.quiteja.staging.gorillascode.com/api/",
    negotiationApi: "https://api.negocie.staging.gorillascode.com/",
    authTokenCookieDomain: "localhost",
    dealAppWebV1Url: "http://negociar.quiteja.staging.gorillascode.com/#",
    landingUrl: "https://quiteja.staging.gorillascode.com",
    name: "staging",
    showLogout: true,
    showVersion: true,
    tasksUrl: "https://tasks.quiteja.staging.gorillascode.com",
    validatorUrl: "https://validator.staging.gorillascode.com",
    useOtpSms: true,
    queryTaskMaxAttempts: 10,
    showParceleJaForQuitejaProposals: true,
  }
}


export default {
  apiUrl: configs[host].apiUrl,
  parceleJaUrl: configs[host].parceleJaUrl,
  negotiationApi: configs[host].negotiationApi,
  authHostsPattern: configs[host].authHostsPattern,
  authTokenCookieDomain: configs[host].authTokenCookieDomain,
  dealAppWebV1Url: configs[host].dealAppWebV1Url,
  landingUrl: configs[host].landingUrl,
  name: configs[host].name,
  tasksUrl: configs[host].tasksUrl,
  showParceleJaForQuitejaProposals:
    configs[host].showParceleJaForQuitejaProposals,

  // Indica se será exibido o botão para o logout
  showLogout: configs[host].showLogout,

  // Indica se será exibida a versão da aplicação na tela
  showVersion: configs[host].showVersion,

  // URL do serviço para validação dos e-mails
  validatorUrl: configs[host].validatorUrl,

  // Indica se será usada a API Web OTP para ler o SMS com o código de negociação
  useOtpSms: configs[host].useOtpSms,
  queryTaskMaxAttempts: configs[host].queryTaskMaxAttempts,
};
