<template>
  <v-row justify="center" v-if="email">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">Excluir contato</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close()"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <p class="qj-p">
            Tem certeza que deseja excluir o e-mail <b>{{ email.value }}</b
            >?
          </p>
          <v-btn
            class="rounded-lg qj-btn qj-bg-secondary-light"
            style="width: calc(50% - 10px); margin-right: 10px"
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            style="width: calc(50% - 10px); margin-left: 10px"
            @click="send()" :loading="deleting"
          >
            Excluir
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import serviceContact from "@/services/contact";

export default {
  props: ["dialog", "email"],
  data: () => ({
    deleting: false,
  }),
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.deleting = true;

      serviceContact
        .save({
          id: this.email.id,
          status: "DISCARDED",
        })
        .then(() => this.resolveInconsistencies())
        .then(() => {
          this.$emit("snackbar", {
            snackbar: true,
            text: "E-mail excluído.",
          });

          this.close();
        })
        .catch((error) => {
          console.error("Não foi possível excluir o e-mail.", error);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.deleting = false;
        });
    },
    resolveInconsistencies() {
      serviceContact.resolveInconsistencies(this.email.id, {
        types: ["EMAIL_NOT_DELIVERED"],
      })
      .catch((error) => {
        console.error("Não foi possível resolver as inconsistências.", error);
      });
    },
  },
};
</script>

<style></style>
