<template>
  <v-row justify="center" v-if="phone">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">Excluir número</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close()"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-3">
          <p class="qj-card-text">
            Tem certeza que deseja excluir o número <b>{{ phone.value | cellphone }}</b
            >?
          </p>
          <v-btn
            class="rounded-lg qj-btn qj-bg-secondary-light qj-p-btn"
            style="width: calc(50% - 10px); margin-right: 10px"
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
            style="width: calc(50% - 10px); margin-left: 10px"
            @click="send()"
          >
            Excluir
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["dialog", "phone"],
  methods: {
    close() {
      this.$emit("close");
    },
    send() {
      this.$emit("send");
    },
  },
};
</script>

<style scoped></style>
