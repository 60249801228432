export const initFirebase = () => {
  // Configurações: https://console.firebase.google.com/u/0/project/quiteja/settings/general
  let app = window.firebase.initializeApp({
    apiKey: "AIzaSyBiKcRan9MjKna_n22cF2QKZJxt62F1eiQ",
    messagingSenderId: "1059483522324",
    projectId: "quiteja",
  });

  console.debug("Firebase:", app.name);

  // Pega uma instância do Firebase Messaging para poder tratar mensagens em background
  const messaging = app.messaging();

  if (messaging) {
    console.debug("Firebase Messaging habilitado.");
  }
}
