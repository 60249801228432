import Vue from "vue";
import environment from "@/assets/js/environment";
import authenticationService from "@/services/authentication";
import * as storage from "../storage";
import * as Sentry from "@sentry/browser";
import Store from "@/store/index"
import { createTagManagerEvent } from "@/utils";


/** Nome do cookie com o token JWT de autenticação. */
const COOKIE_NAME_AUTH_TOKEN = "QUITEJA_DEAL_TOKEN";

/** Nome do cookie com o id criptografado do cliente. */
const COOKIE_NAME_LOGGED_CUSTOMER_ID = "QUITEJA_LOGGED_CUSTOMER_ID";

export default {
  state: () => {
    return {
      token: "", //Token da seção.
      tokenPhone: "",
      tokenSms: "",
      userInfo: {},
      userDeals: [],
      challengeResultToken: "",
      challengeResultObject: {},
      footerInfo: {},
      analytics: {},
      fomentation: false,
    };
  },
  mutations: {
    setToken(state, payload) {
      storage.setHeaderToken(payload);
      state.token = payload;
    },
    setTokenPhone(state, payload) {
      state.tokenPhone = payload;
    },
    setTokenSms(state, payload) {
      state.tokenSms = payload;
    },
    setUserInfo(state, payload) {
      state.userInfo = payload;
    },
    setChallengeResultToken(state, payload) {
      state.challengeResultToken = payload;
    },
    setChallengeResultObject(state, payload) {
      state.challengeResultObject = payload;
    },
    setFooter(state, payload) {
      state.footerInfo = payload;
    },
    setAnalytics(state, payload) {
      state.analytics = payload;
    },
    setFomentation(state, payload) {
      state.fomentation = payload;
    },
    pushUserInfo(state, payload) {
      state.userInfo = Object.assign(state.userInfo, payload);
    },
  },
  actions: {
    async loginOpportunity({ commit }, { payload, vm }) {
      return authenticationService.loginOpportunity(payload).then((res) => {
        if (!res.data.token) {
          return res;
        }

        commit("setToken", res.data.token);
        vm.$cookies.set(
          COOKIE_NAME_AUTH_TOKEN,
          res.data.token,
          "1y",
          null,
          environment.authTokenCookieDomain
        );
        return res;
      });
    },
    async login({ commit }, payload) {
      return authenticationService
        .login(payload.data, payload.token)
        .then((res) => {
          commit("setToken", res.data.token);
        });
    },
    logout({ commit }) {
      console.debug("Deslogando o cliente.");

      commit("negotiation/setMe", {}, { root: true });

      commit("setChallengeResultObject", {});
      commit("setChallengeResultToken", "");
      commit("setToken", "");
      commit("setTokenPhone", "");
      commit("setTokenSms", "");
      commit("setUserInfo", {});

      Vue.prototype.$cookies.remove(COOKIE_NAME_AUTH_TOKEN, null, environment.authTokenCookieDomain);
      Vue.prototype.$cookies.remove(COOKIE_NAME_LOGGED_CUSTOMER_ID, null, environment.authTokenCookieDomain);

      // Limpa o usuário do Sentry
      Sentry.configureScope((scope) => scope.setUser(null));
    },
    async validateChallenge({ commit }, data) {
      return authenticationService
        .validateChallenge(
          data.answer,
          data.type,
          data.token,
          data.source_channel
        )
        .then((res) => {
          let base64Payload = res.data.token.split(".")[1];
          let payload = Buffer.from(base64Payload, "base64");
          let { challenge } = JSON.parse(payload.toString());

          if (res.data.located) {
            createTagManagerEvent({ event: "contactConfirmed" });
          }

          commit("setChallengeResultToken", res.data.token);
          if (challenge === "CONTACT_PASSCODE") {
            commit("setTokenPhone", res.data.token);
          } else if (challenge === "BIRTHDAY") {
            commit("setTokenSms", res.data.token);
          } else {
            commit("setToken", res.data.token);
          }
        });
    },
    async loginDocument({ commit }, payload) {
      if (payload.auth_type == "CUSTOMER") commit("setToken", "");
      return authenticationService.loginDocument(payload).then((res) => {
        commit("setChallengeResultToken", res.data.token);
        commit("setToken", res.data.token);
      });
    },
    async getAuthenticatedUser({ commit }, { token, alreadyLogged }) {
      commit("setToken", token);
      return authenticationService.getAuthenticatedUser(alreadyLogged);
    },
    /** Retorna o id criptografado do cliente autenticado. */
    async getAuthenticatedUserId() {
      return Vue.prototype.$cookies.get(COOKIE_NAME_LOGGED_CUSTOMER_ID);
    },

    /** Remove o cookie com o id criptografado do cliente autenticado. */
    async removeAuthenticatedUserId() {
      console.debug("Removendo o cookie", COOKIE_NAME_LOGGED_CUSTOMER_ID);
      return Vue.prototype.$cookies.remove(COOKIE_NAME_LOGGED_CUSTOMER_ID);
    },

    setAuthTokenToCookie({ state }, { payload, vm }) {
      let tokenExpiration = null;

      try {
        let tokenPayload = Vue.prototype.$jwt.decode(state.token);
        tokenExpiration = new Date(tokenPayload.exp * 1000);

        console.debug('Expiração do token de autenticação:', tokenExpiration);
      } catch (error) {
        console.error('Não foi possível decodificar o token JWT.', error);
      }

      vm.$cookies.set(
        COOKIE_NAME_AUTH_TOKEN,
        state.token,
        "1y",
        null,
        environment.authTokenCookieDomain
      );

      vm.$cookies.set(
        COOKIE_NAME_LOGGED_CUSTOMER_ID,
        state.challengeResultObject?.customer_id,
        tokenExpiration,
        null,
        environment.authTokenCookieDomain
      );
    },
    setUserInfo({ commit }, data) {
      commit("setUserInfo", data);
    },
    setChallengeResultObject({ commit }, data) {
      commit("setUserInfo", data);
    }
  },
  getters: {
    getToken: ({ token }) => token,
    getTokenPhone: ({ tokenPhone }) => tokenPhone,
    getTokenSms: ({ tokenSms }) => tokenSms,
    getUserInfo: ({ userInfo }) => userInfo,
    getChallengeResultToken: ({ challengeResultToken }) => challengeResultToken,
    getChallengeResultObject: ({ challengeResultObject }) =>
      challengeResultObject,
    getFooterInfo: ({ footerInfo }) => footerInfo,
    getAnalytics: ({ analytics }) => analytics,
    getFomentation: ({ fomentation }) => fomentation,
    getDeviceTokenContacts: ({ userInfo }) => {
      return userInfo.contacts.filter((contact) => {
        return contact.type == "DEVICE_TOKEN";
      });
    },
    getActiveDeviceTokenContacts: () => {
      const devices = Store.getters["negotiation/getMe"]?.contacts?.filter(
        (contact) => {
          return (
            contact.type == "DEVICE_TOKEN" && contact.status === "CONFIRMED"
          );
        }
      );
      if (devices?.length < 1) return null;
      return devices;
    },
  },
  namespaced: true,
};
