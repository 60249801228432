import CustomerService from "@/services/customer";
import ProposalService from "@/services/proposal";
import ContactService from "@/services/contact";
import { createTagManagerEvent } from "@/utils";
import util from "@/assets/js/util";

const state = {
  contactId: 0,
  contactList: [],
  contactToChange: null,
  whatsappDialog: false,
  whatsappAccepted: null,
  contactsPushs: [],
  token: null,
};

const mutations = {
  setContactId(state, contactId) {
    state.contactId = contactId;
  },
  setContactsPushs(state, contact) {
    state.contactsPushs = contact;
  },
  setToken(state, token) {
    state.token = token;
  },
  setWhatsappModal(state, boolean) {
    state.whatsappDialog = boolean;
  },
  setContactToChange(state, contact) {
    state.contactToChange = contact;
  },
  setWhatsappAccepted(state, boolean) {
    state.whatsappAccepted = boolean;
  },
};

const actions = {
  async saveWhatsapp({ rootState }, campaign = null) {
    if (!rootState.negotiation.negotiation.whatsapp) {
      return null;
    }

    let today = new Date().toLocaleString();

    let customer_params = {
      id: rootState.authentication.userInfo.id,
      whatsapp_optin: today,
      contact_id: rootState.negotiation.negotiation.phone.id,
    };
    const contactId = rootState.negotiation.negotiation.phone.id;
    const { analytics } = rootState.authentication;
    let payload = {
      campaign: campaign || analytics.campaign,
      source: analytics.source,
      medium: analytics.medium,
      channel: analytics.source_channel,
    };
    await ContactService.saveWhatsApp(contactId, payload).then((res) => {
      console.debug(res.data.objects);
    });

    await CustomerService.save(customer_params).then((res) => {
      console.debug(res.data.objects);
    });
  },
  async createContact({ commit }, payload) {
    return CustomerService.contacts(payload).then((res) => {
      let id = res.data?.[0]?.id;
      commit("setContactId", id);
    });
  },
  generateRetry({ dispatch }, payload) {
    dispatch("setContactId", payload.contact_id);
    return ProposalService.generateRetry(payload);
  },
  setContactId({ commit }, id) {
    commit("setContactId", id);
  },
  async requestNotification({ dispatch, commit, state, rootGetters }) {

    const me = await rootGetters["negotiation/getMe"];
    const { contacts } = me
    var pushGranted = true;
    var pushDenied = false;

    const contact = contacts.find((contact) => {
      return (
              me.contact_id == contact.id
            );
    });

    if (window.Notification && window.Notification.permission) {
      pushGranted = Notification.permission === "granted";
      pushDenied = Notification.permission === "denied";
    }
    const contactIsCellphone = ["CELLPHONE", "PHONE"].includes(contact.type);
    const contactIsValid = ['CREATED', 'CONFIRMED'].includes(contact.status);
    let optinGranted = false;
    let pushDecision = false;
    let validPushs = rootGetters["negotiation/getValidContacts"](
      ["DEVICE_TOKEN"],
      ["CONFIRMED"]
    ).map((contact) => contact.value);

    commit("setContactsPushs", validPushs);
    if (contactIsCellphone && contactIsValid) {
      if (contact.whatsapp && (pushGranted || pushDenied)) return
      optinGranted = await dispatch("openWhatsappModal");
      pushDecision = await dispatch(
        "checkAndRequestPushPermission",
        pushGranted
      );
    } else {
      const validPhoneWithWhats = contacts?.find((item) => item.whatsapp && util.calculateDaysAgo(item.updated_at) < 365 && ['CREATED', 'CONFIRMED'].includes(item.status));
      const validPhone = contacts.find((contact) => {
        return (
          ["CELLPHONE", "PHONE"].includes(contact.type) &&
          ["CREATED", "CONFIRMED"].includes(contact.status)
        );
      });
      if ((validPhoneWithWhats || !validPhone) && (pushGranted || pushDenied))
        return;
      optinGranted = await dispatch("openWhatsappModal");
      pushDecision = await dispatch(
        "checkAndRequestPushPermission",
        pushGranted
      );
    }
    if (optinGranted && pushDecision) {
      createTagManagerEvent({ event: "acceptedNotificationAndPush" });
    } else if (!optinGranted && pushDecision) {
      createTagManagerEvent({ event: "acceptedPushAndDeclinedNotification" });
    } else if (!pushDecision && optinGranted) {
      createTagManagerEvent({ event: "acceptedNotificationAndDeclinedPush" });
    } else {
      createTagManagerEvent({
        event: "declinedNotification",
      });
    }
  },
  async checkAndRequestPushPermission({ dispatch, commit }, pushGranted) {
    // if (pushGranted) return true;
    try {
      let token = await dispatch("requestPushPermission");
      commit("setToken", token);
      if (!state.contactsPushs.includes(state.token)) {
        dispatch("pushNotification/registerPushNotification", true, {
          root: true,
        });
      }
      return true;
    } catch (err) {
      console.info("Permissão para push negada");
      return false;
    }
  },
  async openWhatsappModal({ commit, state }) {
    return new Promise((resolve) => {
      commit("setWhatsappModal", true);
      const interval = setInterval(() => {
        if (state.whatsappAccepted !== null) {
          clearInterval(interval);
          resolve(state.whatsappAccepted);
        }
      }, 100);
    });
  },
  async acceptNotifications(
    { dispatch, state, commit, rootState },
    { accepted = true, contact }
  ) {
    if (!accepted) {
      commit("setWhatsappAccepted", false);
      return;
    }
    commit("setWhatsappAccepted", true);
    const { analytics } = rootState.authentication;
    let payload = {
      campaign: 'tela_modal_optin',
      source: analytics.source,
      medium: analytics.medium,
      channel: analytics.source_channel,
    };
    if (contact) {
      ContactService.saveWhatsApp(contact.id, payload).then(() => {
        dispatch("negotiation/ActionsMe", {}, { root: true });
      });
    }
  },
  async requestPushPermission() {
    return new Promise((resolve) => {
      window.Notification.requestPermission().then((permission) => {
        console.debug("Permissão para notificação:", permission);

        if (permission == "granted") {
          resolve(window.firebase.messaging().getToken());
        } else {
          console.info("permissão negada");
        }
      });
    });
  },
};

const getters = {
  getContactId: ({ contactId }) => contactId,
  whatsappDialog: (state) => state.whatsappDialog,
  whatsappAccepted: (state) => state.whatsappAccepted,
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
