<template>
  <v-row class="pt-0 pb-0">
    <!-- Btn next -->
    <v-col cols="8" offset="2" class="text-center">
      <router-link
        :to="{ name: 'Deals', query:{ view: getView} }"
        class="qj-link qj-color-secondary"
        style="vertical-align: sub"
        id="return__to__other__negotiations"
      >
        {{ routerText }}
      </router-link>
    </v-col>
    <!-- Btn help -->
    <v-col cols="2" class="pl-0">
      <v-btn icon class="rounded-lg qj-btn qj-bg-primary-light" @click="help">
        <v-icon>far fa-question-circle</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import { createTagManagerEvent } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  methods: {
    help() {
      createTagManagerEvent({
        event: 'clickedHelp'
      })
      this.$emit("help");
    },
  },
  computed: {
    ...mapGetters('negotiation', {
      deals: 'getDeals',
      debits: 'getDebits',
      agreements: 'getAgreements'
    }),
    getView(){
      if (this.debits.length >= 1) return 'dividas'
      return 'acordos'
    },
    routerText(){
      if (this.debits.length >= 1) return 'Ver outras dívidas'
      return 'Ver outros acordos'
    }
  },
};
</script>
<style></style>
