<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="310">
      <v-card class="text-center">
        <v-card-title class="justify-center">
          <h3 class="qj-h3">{{email ? "Atualizar" : "Novo"}} e-mail</h3>
          <v-btn
            icon
            class="qj-btn-close-dialog"
            style="top: 9px; right: 2px"
            @click="close()"
          >
            <v-icon>far fa-times-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-form ref="form" @submit.prevent="send()">
            <p class="qj-p mb-1">Informe o e-mail no campo abaixo:</p>
            <v-text-field
              type="email"
              autocomplete="email"
              dense
              outlined
              required
              single-line
              ref="email"
              label="E-mail"
              class="qj-input"
              v-model="newEmail"
              :rules="rules.email"
              autofocus
            ></v-text-field>
            <div
              style="
                background: #f6f6f6;
                border-radius: 7px;
                padding: 21px 10px;
                margin-bottom: 45px;
              "
            >
              <p class="qj-p">
                Você irá receber um e-mail de verificação. Caso não receba o
                e-mail, verifique a caixa de spam ou entre em contato conosco.
              </p>
              <v-btn
                class="rounded-lg qj-btn qj-bg-secondary-light"
                href="https://quiteja.com.br/contato"
                target="_blank"
              >
                Entrar em contato
              </v-btn>
            </div>
            <v-btn
              block
              id="btn__save__new__email"
              class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
              @click="send()"
              :loading="saving"
            >
              <div style="margin-right: auto">Salvar</div>
              <v-icon style="font-size: 20px">fas fa-check</v-icon>
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import rules from "@/assets/js/rules";
import serviceContact from "@/services/contact";
import serviceCustomer from "@/services/customer";

export default {
  props: ["dialog", "email"],
  data: () => ({
    newEmail: "",
    rules: rules,
    saving: false,
  }),
  watch: {
    dialog() {
      if (this.dialog) {
        setTimeout(() => this.$refs.email?.focus(), 100);
      }
    },
  },
  methods: {
    close() {
      this.newEmail = "";
      this.$refs.form.reset();
      this.$emit("close");
    },
    async send() {
      console.debug("Salvando o e-mail", this.newEmail);

      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.email && this.email.value == this.newEmail) {
        return;
      }

      this.saving = true;

      if (this.email && this.email?.id) {
        await this.rejectEmail(this.email.id);
      }

      serviceCustomer
        .contacts({ contacts: [this.newEmail], confirm: true })
        .then(() => {
          this.$emit("snackbar", {
            snackbar: true,
            text: "E-mail salvo.",
          });

          this.close();
        })
        .catch((error) => {
          console.error("Não foi possível salvar o e-mail.", error);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    rejectEmail(id) {
      serviceContact
        .save({
          id: id,
          status: "DISCARDED",
        })
        .then(() => this.resolveInconsistencies())
        .then(() => {
          this.$emit("snackbar", {
            snackbar: true,
            text: "E-mail excluído.",
          });
          this.close();
        })
        .catch((error) => {
          console.error("Não foi possível excluir o e-mail.", error);

          this.$emit("snackbar", {
            snackbar: true,
            text: "Houve um erro. Tente novamente.",
          });
        });
    },
    resolveInconsistencies() {
      serviceContact
        .resolveInconsistencies(this.email.id, {
          types: ["EMAIL_NOT_DELIVERED"],
        })
        .catch((error) => {
          console.error("Não foi possível resolver as inconsistências.", error);
        });
    },
  },
};
</script>

<style></style>
