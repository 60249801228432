<template>
  <v-dialog v-model="dialogNotMy" persistent max-width="310">
    <CardConfirmNotMe :userName="userName" v-if="!notMe" @close="close" @confirmNotMe="confirmNotMe"/>
    <v-card v-else class="text-center">
      <v-card-title class="justify-center">
        <h3 class="qj-h3">Você não é {{ userName }}</h3>
        <v-btn icon class="qj-btn-close-dialog" @click="close" style="top: 5px">
          <v-icon>far fa-times-circle</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pl-3 pr-3 pb-3 mt-2">
        <img src="@/assets/img/svg/aviso.svg" alt="image_attention" width="90" height="90"/>

        <p class="qj-p mt-2 ma-5">
          Agradecemos por nos avisar. Sentimos muito pelo inconveniente e não se preocupe,
          você não será mais notificado.
        </p>
        <p class="qj-p mt-6">Nos acompanhe nas redes sociais!</p>
        <div class="text-center pt-1 medias" style="line-height: initial">
          <a href="https://www.facebook.com/QuiteJa/" target="_blank">
            <img
              src="@/assets/img/midias/facebook.svg"
              alt="Facebook"
            />
          </a>
          <a href="https://www.instagram.com/quite.ja/" target="_blank">
            <img
              src="@/assets/img/midias/instagram.svg"
              alt="Instagram"
            />
          </a>
          <a href="https://br.linkedin.com/company/quitej%C3%A1" target="_blank">
            <img
              src="@/assets/img/midias/linkedin.svg"
              alt="Linkedin"
            />
          </a>
          <a
            href="https://www.youtube.com/channel/UCAqELLEaB1rF5pV1oNrL2HA"
            target="_blank"
          >
            <img
              src="@/assets/img/midias/youtube.svg"
              alt="Youtube"
            />
          </a>
          <a href="https://quiteja.com.br/blog" target="_blank">
            <img 
              src="@/assets/img/midias/blog.svg" 
              alt="Blog"/>
          </a>
        </div>
        <v-btn
          width="137px"
          class="rounded-lg qj-btn qj-bg-secondary qj-p-btn mt-10"
          @click="close"
          >Sair</v-btn
        >
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script scoped>
import CardConfirmNotMe from './CardConfirmNotMe.vue'
export default {
  props: ["dialogNotMy", "userName"],
  components: {
    CardConfirmNotMe,
  },
  data() {
    return {
      notMe: false
    }
  },
  methods: {
    close() {
      this.$emit("closeDialogNotMyCPF");
    },
    confirmNotMe(){
      this.notMe = true
      this.$emit('confirmNotMe')
    }
  },
};
</script>

<style lang="scss" scoped>
.medias img{
  margin-left: 12px;
  width: 22px;
  height: 22px;
}

.qj-btn-close-dialog {
  right: 0 !important;
}
</style>
