export default [
    {
        path: '/info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "public" */ './pages/Info'),
        meta: {
            title: "Informações",
            requiresAuth: false,
            showMenu: false,
            showStep: false,
            canShowLogo: true,
            doNotShowMyData: true,
        },
    },
];
