<template>
  <div>
    <v-fade-transition>
      <div
        v-show="dialog"
        class="qj-bg-dialog"
        style="background: rgba(0, 0, 0, 0)"
        @click="close()"
      ></div>
    </v-fade-transition>
    <v-dialog
      fullscreen
      scrollable
      persistent
      transition="dialog-bottom-transition"
      v-model="dialog"
    >
      <v-card>
        <v-card-title>
          <v-col cols="12" class="pt-0 text-center px-0" style="position: relative">
            <h2 class="qj-h3">Indicar um amigo</h2>
            <v-btn icon class="qj-btn-close-dialog" @click="close()">
              <v-icon>far fa-times-circle</v-icon>
            </v-btn>
            <p class="qj-p mb-0 mt-3">
              Ajude um amigo(a) que está precisando muito quitar as dívidas!
            </p>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12" class="pb-0 pt-0">
                <p class="qj-p mb-0">Como seu(ua) amigo(a) se chama?</p>
              </v-col>
              <v-col cols="12" class="pb-0 d-flex">
                <div class="flex-grow-1">
                  <v-text-field
                    dense
                    outlined
                    required
                    single-line
                    label="Nome"
                    class="qj-input"
                    prepend-inner-icon="far fa-user"
                    v-model="name"
                    :rules="rules.name"
                    @keyup.enter="send()"
                  ></v-text-field>
                </div>

                <div v-if="isContactPickerSupported" class="ml-2">
                  <v-btn icon @click="getContacts()">
                    <v-icon>mdi-contacts</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <p class="qj-p mb-0">Informe o e-mail do(a) amigo(a):</p>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  type="email"
                  dense
                  outlined
                  single-line
                  label="E-mail"
                  class="qj-input"
                  prepend-inner-icon="far fa-envelope"
                  v-model="email"
                  :rules="rulesEmail"
                  @keyup.enter="send()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <p class="qj-p mb-0">Ou informe o número para contato:</p>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  type="tel"
                  dense
                  outlined
                  single-line
                  label="Número"
                  class="qj-input"
                  prepend-inner-icon="fas fa-mobile-alt"
                  v-model="number"
                  v-mask="phoneMask"
                  :rules="rulesCellphone"
                  @keyup.enter="send()"
                  data-hj-allow
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="text-center">
                <v-btn
                  style="width: 230px"
                  class="rounded-lg qj-btn qj-bg-primary qj-p-btn"
                  @click="send()"
                  id="recommended__friend__btn"
                >
                  Enviar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogIndicationSent
      :dialog="dialogIndicationSent"
      @close="closeDialogIndicationSent()"
    />
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/store";
import util from "@/assets/js/util";
import rules from "@/assets/js/rules";
import DialogIndicationSent from "./DialogIndicationSent";
import serviceRecommendation from "@/services/recommendation";

export default {
  props: ["dialog"],
  components: {
    DialogIndicationSent,
  },
  data: () => ({
    // Indica se o browser suporta a API Contact Picker
    isContactPickerSupported: false,

    // Máscara do telefone
    phoneMask: "(##) #####-####",

    rules: rules,
    dialogIndicationSent: false,
    name: "",
    email: "",
    number: "",
  }),
  created() {
    this.isContactPickerSupported =
      "contacts" in navigator && "ContactsManager" in window;
    console.debug("Suporte da API Contact Picker:", this.isContactPickerSupported);
  },
  computed: {
    rulesEmail() {
      if (!!this.number && !this.email) return [];
      return this.rules.email;
    },
    rulesCellphone() {
      if (!!this.email && !this.number) return [];
      return this.rules.phone;
    },
    user: () => store.getters["authentication/getUserInfo"],
  },
  methods: {
    close() {
      this.$emit("close");
    },
    closeDialogIndicationSent() {
      this.name = "";
      this.email = "";
      this.number = "";
      this.$refs.form.resetValidation();
      this.dialogIndicationSent = false;
      this.close();
    },
    send() {
      if (this.$refs.form.validate()) {
        serviceRecommendation.post(this.prepareForm()).then(() => {
          this.dialogIndicationSent = true;
        });
      }
    },
    prepareForm() {
      return {
        name: this.name,
        email: this.email,
        contact: util.justNumbers(this.number) || "",
        customer_id: this.user.id,
      };
    },

    // Seleciona um contato usando a API Contact Picker
    async getContacts() {
      if (!this.isContactPickerSupported) {
        return;
      }

      const props = ["email", "name", "tel"];

      const opts = {
        multiple: false,
      };

      let contacts = [];

      try {
        contacts = await navigator.contacts.select(props, opts);
      } catch (err) {
        console.error("Não foi possível selecionar os contatos.", err);
      }

      contacts.forEach((contact) => {
        console.debug("Contato selecionado:", contact);

        this.name = contact.name ? contact.name?.[0] : "";
        this.email = contact.email ? contact.email?.[0] : "";

        if (contact.tel) {
          this.number = Vue.filter("VMask")(contact.tel?.[0], this.phoneMask);
        } else {
          this.number = "";
        }
      });
    },
  },
};
</script>

<style scoped></style>
